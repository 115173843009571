// let isProd = location.href.indexOf("localhost") > -1 ? false : true;

let environment = "PROD";

if(location.href.indexOf("localhost") > -1){
  environment = "LOCAL";
}
else if(location.href.indexOf("sandbox") > -1){
  environment = "SANDBOX";
}

let configs={
  PROD:{
    serverurl:"https://api.hulkhire.com",
    leverurl:"https://api.lever.co/v1/",
    leverauthurl:"https://auth.lever.co",
    uiurl:"https://talent.hulkhire.com",
    leverclientid:"BOJcXnQ5VGjHqqfURN0olSXXp8EpZ0gb"
  },
  SANDBOX:{
    serverurl:"https://api.sandbox.hulkhire.com",
    leverurl:"https://api.sandbox.lever.co/v1/",
    leverauthurl:"https://sandbox-lever.auth0.com",
    uiurl:"https://talent.sandbox.hulkhire.com",
    leverclientid:"URHjKWLRbtA0ONcHVo3yjrMDVnRVUigL"
  },
  LOCAL:{
    serverurl:"https://api.hulkhire.com",
    // serverurl:"http://localhost:8083",
    leverurl:"https://api.sandbox.lever.co/v1/",
    leverauthurl:"https://sandbox-lever.auth0.com",
    uiurl:"http://localhost:8081",
    leverclientid:"URHjKWLRbtA0ONcHVo3yjrMDVnRVUigL"
  }

}


fetch(configs[environment].serverurl+"/getTime").then(res=>res.text()).then(text=> window.SERVER_TIME= new Date( text.replace(/"/g,'')));
export default {
  light: {
    colors:{
    primary: "#008036",
    "primary-darken-1": "#008036",
    secondary: "#FF5C93",
    'secondary-darken-1': '#FF5C93',
    error: "#FF4081",
    warning: "#FFC260",
    success: "#3CD4A0",
    info: "#9013FE",
    textColor: "#262626",
    iconColor: "#FFFFFF59",
    grayBold: '#262626'
    }
  },
  serverurl : configs[environment].serverurl,
  configs: configs[environment],
  environment: environment
};

