<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row class="d-flex justify-space-between mt-3 mb-3 no-gutters" style="
          background: #fff;
          padding: 10px 10px;
          align-items: center;
        ">
        <h1 class="page-title">Integrations</h1>
        <v-spacer></v-spacer>

      </v-row>

    </div>
    <div class="d-flex">
      <v-container class="ma-0">
        <v-row class="no-gutters">
          <v-col cols="12" md="4" v-for="partner in partners" :key="partner.id">
            <div class="integration-partner d-flex flex-column justify-center text-center pa-4 mb-4">
              <v-img :src="partner.logo" style="max-width: 150px; height: 100px;margin: auto;"
                :alt="partner.name + ' Integration'"></v-img>
              <v-typography class="text-caption text-left mb-2">{{ partner.description }}</v-typography>
              <v-typography class="text-caption text-left mb-2">{{ partner.description2 }}</v-typography>
              <div cols="12"
  v-if="integrations.indexOf(partner.name.toUpperCase()) > -1"
  class="d-flex align-center justify-center"
>
  <v-icon color="primary" class="mr-2">mdi-check-circle</v-icon>
  <span class="font-weight-bold" style="color: green;">CONNECTED</span>
  <v-btn variant="text" v-if="integrations.indexOf(partner.name.toUpperCase())>-1" :disabled="!partner.available" class="ml-4" color="secondary" :href="partner.url">Reconnect</v-btn>
</div>
<!-- v-if="integrations.indexOf(partner.name.toUpperCase())==-1" :disabled="!partner.available" -->
              <v-btn variant="outlined" v-if="integrations.indexOf(partner.name.toUpperCase())==-1" :disabled="!partner.available"  class="mt-8" color="primary" :href="partner.url">{{ partner.cta }}</v-btn>
              
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import config from "../../config"
let serverurl = config.serverurl;
let leverurl = config.configs.leverurl;
let leverauthurl = config.configs.leverauthurl;
let uiurl = config.configs.uiurl;
let leverclientid = config.configs.leverclientid;
// uiurl="https://talent.hulkhire.com"
export default {
  name: "Integrations",
  components: {

  },
  data() {
    return {
      partners: [{
        id: 1,
        name: "Lever",
        logo: "/images/lever.svg",
        description: "Connecting to Lever will help you schedule HulkHire interviews directly from your Lever account. And receive feedback about candidates directly in Lever.",
        description2: "Connecting to Lever will help you schedule HulkHire interviews directly from your Lever account. And receive feedback about candidates directly in Lever.",
        cta: "Connect to Lever",
        url: `${leverauthurl}/authorize?client_id=${leverclientid}&audience=${leverurl}&redirect_uri=${uiurl}/integrate/lever&state=vgG5sHr6&response_type=code&scope=offline_access users:write:admin panels:write:admin interviews:write:admin stages:read:admin opportunities:write:admin notes:write:admin feedback:write:admin feedback_templates:write:admin contact:write:admin webhooks:write:admin &prompt=consent`,
        available: true
      },
      {
        id: 2,
        name: "JazzHR",
        logo: "/images/jazzhr.jpeg",
        description: "Connecting to JazzHR will help you schedule HulkHire interviews directly from your JazzHR account. And receive feedback about candidates directly in JazzHR.",
        description2: "Connecting to JazzHR will help you schedule HulkHire interviews directly from your JazzHR account. And receive feedback about candidates directly in JazzHR.",
        cta: "Connect to JazzHR",
        url: `${leverauthurl}/authorize?client_id=${leverclientid}&audience=${leverurl}&redirect_uri=${uiurl}/integrate/lever&state=vgG5sHr6&response_type=code&scope=offline_access users:write:admin panels:write:admin interviews:write:admin stages:read:admin opportunities:write:admin notes:write:admin feedback:write:admin feedback_templates:write:admin contact:write:admin webhooks:write:admin &prompt=consent`,
        available: false
      },
      {
        id: 3,
        name: "JobVite",
        logo: "/images/jobvite.png",
        description: "Connecting to JobVite will help you schedule HulkHire interviews directly from your JobVite account. And receive feedback about candidates directly in JobVite.",
        description2: "Connecting to JobVite will help you schedule HulkHire interviews directly from your JobVite account. And receive feedback about candidates directly in JobVite.",
        cta: "Connect to JobVite",
        url: `${leverauthurl}/authorize?client_id=${leverclientid}&audience=${leverurl}&redirect_uri=${uiurl}/integrate/lever&state=vgG5sHr6&response_type=code&scope=offline_access users:write:admin panels:write:admin interviews:write:admin stages:read:admin opportunities:write:admin notes:write:admin feedback:write:admin feedback_templates:write:admin contact:write:admin webhooks:write:admin &prompt=consent`,
        available: false
      },
      {
        id: 4,
        name: "Rippling",
        logo: "/images/rippling.webp",
        description: "Connecting to Rippling will help you schedule HulkHire interviews directly from your Rippling account. And receive feedback about candidates directly in Rippling.",
        description2: "Connecting to Rippling will help you schedule HulkHire interviews directly from your Rippling account. And receive feedback about candidates directly in Rippling.",
        cta: "Connect to Rippling",
        url: `${leverauthurl}/authorize?client_id=${leverclientid}&audience=${leverurl}&redirect_uri=${uiurl}/integrate/lever&state=vgG5sHr6&response_type=code&scope=offline_access users:write:admin panels:write:admin interviews:write:admin stages:read:admin opportunities:write:admin notes:write:admin feedback:write:admin feedback_templates:write:admin contact:write:admin webhooks:write:admin &prompt=consent`,
        available: false
      },
      {
        id: 5,
        name: "GreenHouse",
        logo: "/images/greenhouse.png",
        description: "Connecting to GreenHouse will help you schedule HulkHire interviews directly from your GreenHouse account. And receive feedback about candidates directly in GreenHouse.",
        description2: "Connecting to GreenHouse will help you schedule HulkHire interviews directly from your GreenHouse account. And receive feedback about candidates directly in GreenHouse.",
        cta: "Connect to GreenHouse",
        url: `${leverauthurl}/authorize?client_id=${leverclientid}&audience=${leverurl}&redirect_uri=${uiurl}/integrate/lever&state=vgG5sHr6&response_type=code&scope=offline_access users:write:admin panels:write:admin interviews:write:admin stages:read:admin opportunities:write:admin notes:write:admin feedback:write:admin feedback_templates:write:admin contact:write:admin webhooks:write:admin &prompt=consent`,
        available: false
      },
      {
        id: 6,
        name: "Manatal",
        logo: "/images/manatal.png",
        description: "Connecting to Manatal will help you schedule HulkHire interviews directly from your Manatal account. And receive feedback about candidates directly in Manatal.",
        description2: "Connecting to Manatal will help you schedule HulkHire interviews directly from your Manatal account. And receive feedback about candidates directly in Manatal.",
        cta: "Connect to Manatal",
        url: `${leverauthurl}/authorize?client_id=${leverclientid}&audience=${leverurl}&redirect_uri=${uiurl}/integrate/lever&state=vgG5sHr6&response_type=code&scope=offline_access users:write:admin panels:write:admin interviews:write:admin stages:read:admin opportunities:write:admin notes:write:admin feedback:write:admin feedback_templates:write:admin contact:write:admin webhooks:write:admin &prompt=consent`,
        available: false
      }

      ],
      integrations:[]
    };
  },
  methods: {
   getIntegration: async function(){
      // make api call to get integration details
      let response=await fetch(serverurl + "/getintegrations", {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        }
      });
      let data=await response.json();
      this.integrations=data;

    }

  },

  mounted() {
            this.getIntegration();
  },
};
</script>

<style src="./Integrations.scss" lang="scss"/>
