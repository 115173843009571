<template>
  <v-card
    :loading="loading"
    class="mx-auto my-12 talent-card mb-2 mt-2"
    max-width="320"
    @click.stop.prevent="openurl()"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
    <span style="position:absolute; z-index:10;font-size: 50px;
    top: -20px;
    right: -2px;">{{candidate.flag}}</span>
    <v-img height="250" :src="candidate.profile_url|| candidate.image"></v-img>

    <v-card-title style="justify-content: start">{{
      candidate.firstname
    }}</v-card-title>

    <v-card-text>
      <v-row align="center" class="mx-0">
        <v-rating
          :value="Math.max( (candidate.score / 2),3)"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>

        <div class="grey--text ms-4 text-capitalize">
          {{ Math.max((candidate.score / 2).toFixed(2),3)}} ({{ candidate.technology }})
        </div>
      </v-row>

      <div class="my-4 text-subtitle-1">
        {{ candidate.experience>0 ? (candidate.experience+ "+ yrs"): "Fresher"}} • {{ candidate.qualification }}
      </div>

      <div> {{ candidate.notice_period >1? "Available to join in " +candidate.notice_period + " days" : "Immediate Joiner" }} </div>
      <div>
        Interviewed on
        {{ new Date(candidate.interviewtime).toLocaleDateString() }}.
      </div>
      <div>
        
        {{ candidate.current_ctc>0? 'Current: '+ Number(candidate.current_ctc).toLocaleString('en-'+candidate.city.split("-")[2], {style: 'currency', currency: candidate.currency,maximumFractionDigits: 0}) : "Not Earning"}} • Expecting:
        {{ Number(candidate.expected_ctc).toLocaleString('en-'+candidate.city.split("-")[2], {style: 'currency', currency: candidate.currency,maximumFractionDigits: 0}) }}
      </div>
      <!-- <br/> -->
      <!-- <div> Work model: {{candidate.workmodel.replace(/,/g,", ")}}</div>
      <div> Preferred Location: {{candidate.location_preferences.split(",").map(s=>s.split("-")[0]).join(", ")}}</div> -->
    </v-card-text>

    <v-divider class="mx-4"></v-divider>
    <v-card-actions>
      <v-btn color="orange lighten-2" @click.stop="show = !show" text> Interview Report </v-btn>

      <v-spacer @click.stop=""></v-spacer>

      <v-btn icon @click.stop="show = !show">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
          <div
            v-for="score in candidate.scores"
            v-bind:key="score.technology"
            style="height: 40px"
          >
            {{ score.technology }}
            <span  :class="score.score<= 5?'good': ((score.score>5 && score.score<=7)?'better' :'best')" style="float: right">{{
              score.score &lt;= 5?"GOOD": ((score.score&gt;5 && score.score&lt;=7)?"BETTER" :"BEST")
            }}</span>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
    <!-- <v-card-text class="pt-0 pb-0" style="color:#000;font-weight:bold;">Price: <span style="text-decoration:line-through; float:right;font-weight:normal;">{{candidate.price *1.6}} INR</span><span style="float:right; color:#000">{{candidate.price *1.0}} INR &nbsp;&nbsp;</span></v-card-text> -->
    <!-- <v-card-title style="justify-content:start"></v-card-title>

    <v-card-text> -->
    <!-- <div v-for="score in candidate.scores" v-bind:key="score.technology" style="height:40px">
        {{score.technology}} <v-chip color="primary" style="float:right;">{{score.score}}</v-chip>
        </div> -->
    <!-- <v-chip-group
        v-model="selection"
        active-class="deep-purple accent-4 white--text"
        column
      >
        <v-chip>5:30PM</v-chip>

        <v-chip>7:30PM</v-chip>

        <v-chip>8:00PM</v-chip>

        <v-chip>9:00PM</v-chip>
      </v-chip-group> -->
    <!-- </v-card-text> -->

    <v-card-actions>
      <v-btn block color="primary" variant="flat">View Full Profile</v-btn>
      <!-- <v-btn
        v-if="candidate.status != 'IN_CART'"
        color="primary"
        @click.stop="onAddCart(candidate.id)"
        block
        >Add to Cart</v-btn
      >
      <v-btn
        v-if="candidate.status == 'IN_CART'"
        color="#ececec"
        @click.stop="onRemoveCart(candidate.id)"
        block
        >Remove from Cart</v-btn
      > -->
      <!-- <v-btn
        color="deep-purple lighten-2"
        text
        @click="reserve"
      >
        Reserve
      </v-btn> -->
    </v-card-actions>
  </v-card>
</template>
<script>
import { sendLogs } from "@/server/api";
export default {
  props: ["candidate", "onAddCart", "onRemoveCart"],
  data: () => ({
    loading: false,
    selection: 1,
    show: false
  }),

  methods: {
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
    openurl(){
      sendLogs({type:"SOURCING_CANDIATE_DETAILS", log: this.candidate.id})
        window.open(location.origin + '/#/talents/candidatedetails/' + this.candidate.id,'_blank')
    }
  },
  watch: {
    // candidate: {
    //   deep: true,
    //   changed(value) {
    //     console.log("chnaged:")
    //     console.log(value)
    //   },
    // },
  },
};
</script>
<style src="./TalentCard.scss" lang="scss"/>