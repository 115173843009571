<template>
  <v-card
    relative
    style="border-radius: 5px; border: 1px solid #ececec; padding: 10px;position: relative;
    overflow: visible;"
  >
    <v-btn
      absolute="true"
      right
      top
      style="
        top: -20px;
        right: -10px;
        background: transparent;
        box-shadow: none;
        position: absolute;
      "
      class="mx-2 display-1"
      fab
      transparent
      size="small"
      @click="onremove(index, type)"
    >
      <i
        class="fa-solid fa-circle-xmark"
        style="font-size: 2rem; color: #ff5c93"
      ></i>
    </v-btn>
    <p>{{ label || "Question" }} {{ index }}</p>
    <v-text-field
      :placeholder="
        'Please type in your ' + (label ? label.toLowerCase() : 'question')
      "
      variant="underlined"
      :rules="clientRules"
      :maxlength="500"
      :value="weightage"
      @input="updateValue($event)"
      v-if="showeightage"
    ></v-text-field>
  </v-card>
</template>
<script>
export default {
  props: ["modelValue", "weightage", "showeightage", "index", "label", "type", "onremove"],
  data() {
    return {
        clientRules:[(v)=> !!v|| 'This field is required'],
        weightageRules:[
      (v) => {
          return (v && v>=5 && v<=100 && /^[0-9]*$/.test(v))|| "Invalid"
        },
      ],
    };
  },
  methods: {
    updateValue(e) {
      this.$emit("update:modelValue", e);
    }
  },
};
</script>