<template>
    <div>
    <router-view  />
    <!-- v-if="$vuetify.display.mdAndUp" -->
     <!-- <h1 style="color:#008036; margin-top:20px;padding: 10px 10px;" class="mt-4 pa-10 pt-10" v-if="$vuetify.display.smAndDown" >Mobile devices are not currently supported. <br/>Kindly use laptop for login</h1>  -->
    </div>
</template>

<script>
  export default {
    name: 'App'
  };
</script>

<style src="./styles/app.scss" lang="scss"/>


