<template>
  <v-navigation-drawer
    fixed
    app
    clipped
    v-model="store.drawer"
    :mini-variant="!store.drawer"
    :width="sidebarWidth"
    :permanent="$vuetify.display.mdAndUp"
    :temporary="$vuetify.display.mobile"
    :mini-variant-width="sidebarMinWidth"
    :class="{ 'drawer-mini': !store.drawer }"
  >
    
      <!-- <v-btn
      v-bind="attrs"
      v-on="on"
       width="100%"
      color="secondary"
      class="text-capitalize button-shadow mr-1 mb-4"
      >Invite Candidates</v-btn> -->
    <v-list>
      <template v-for="(item, i) in (store.profile.role.toLowerCase()=='bm_reviewer'? items.filter(i=>i.title=='Candidates') :($vuetify.display.smAndDown? mobileItems: items))" >
        <div  v-if="!item.onlyAdmin || store.profile.role.toLowerCase()=='admin'" v-bind:key="item.title">
        <v-row v-if="item.heading" :key="item.heading" align="center">
          <v-col cols="6" class="py-5">
            <span
              style="padding-left: 32px"
              class="text-body-1 subheader"
              :class="item.heading && store.drawer ? 'show ' : 'hide'"
            >
              {{ item.heading }}
            </span>
          </v-col>
          <v-col cols="6" class="text-center"> </v-col>
        </v-row>
        <v-divider
          v-else-if="item.divider"
          :key="i"
          dark
          class="my-4"
        ></v-divider>
        <v-list-group
          color="primary"
          v-else-if="item.children && store.drawer"
          :key="item.title"
          v-model="item.model"
          append-icon=""
        >
          <template v-slot:prependIcon>
            <!-- <v-icon size="28">mdi-image-filter-none</v-icon> -->
          </template>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            
            link
            @click="sendLogs(child)"
          >
            <v-list-item-action v-if="child.icon">
              <v-icon size="">{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          color="primary"
          v-else
          :key="item.text"
          :href="item.href ? item.href : null"
          
          link
          @click="sendLogs(item)"
        >
        <template v-slot:prepend>
          <v-icon style="opacity: 1;" size="28" :color="item.color ? item.color : ''">{{
              item.icon
            }}</v-icon>
        </template>
        <v-list-item-title link v-if="windowWidth < 900 || windowWidth > 1500">
              {{ item.title }}
            </v-list-item-title>
        <!-- <v-list-item-title v-text="item.text"  v-if="windowWidth < 900 || windowWidth > 1500"></v-list-item-title> -->
          <!-- <v-list-item-action v-if=" item.icon">
            <v-icon size="28" :color="item.color ? item.color : ''">{{
              item.icon
            }}</v-icon>
          </v-list-item-action> -->
          <!-- <v-list-item-content>
            
          </v-list-item-content> -->
        </v-list-item>
        
        </div>

        
      </template>
      <!-- <div style="position:absolute; bottom:0; font-size: 10px;
    padding-left: 5px;">
          <b>Contact Us</b><br/>
          <a href="mailto:care@hulkhire.com">care@hulkhire.com</a><br/>
          <a href="tel:8886080289">+91 88860 80289</a>
          <p>402, Jugla Technologies,<br/> Begumpet, Hyderabad</p>
          <a style="color:gray" target="_blank" href="https://hulkhire.com/terms/">Terms & Conditions</a>
          <br/>
          <a style="color:gray" target="_blank" href="https://hulkhire.com/privacy/">Privacy Policy</a>
        </div> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { store } from "../../store/index";
import { sendLogs } from "@/server/api";
// let window;
export default {
  props: ["items"],
  data() {
    return {
      store,
      windowWidth: window.innerWidth,
      
      sidebarWidth:
        window.innerWidth < 900 || window.innerWidth > 1500 ? 240 : 96,
      sidebarMinWidth: 96,
      mobileItems:[
        {title:"Talents",  children:[
          { title: "Sourcing", icon: "mdi-account-search", link: "/talents/sourcing" },
        { title: "Elites", icon: "mdi-star-circle", link: "/talents/elites" }

        ]},
        {title:"Interviews",children:[
         { title: "Dashboard", icon: "mdi-view-dashboard", link: "/interviews/dashboard" },
        { title: "Candidates", icon: "mdi-account-box", link: "/interviews/candidates" },
        { title: "Interviews", icon: "mdi-briefcase", link: "/interviews/interviews" }

        ]},
        {title:"Admin"
        ,children:[
         { title: "Integrations", icon: "mdi-view-dashboard", link: "/interviews/dashboard" },
        { title: "Users", icon: "mdi-account-box", link: "/admin" , onlyAdmin:true }

        ]
        }]
        
            
      
    };
  },
  methods:{
    sendLogs:function(item){
      if(this.$vuetify.display.smAndDown){
        this.store.drawer=false;
        store.drawer=false;
        
      }
        
      sendLogs({type:"TAB_SELECTED",log: item.title})
      this.$router.push(item.link);
    }
  },
  mounted() {
    // debugger;
    console.log(this.$vuetify);
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      this.sidebarWidth =
        window.innerWidth < 900 || window.innerWidth > 1500 ? 240 : 96;
    });
  },
};
</script>

<style src="./Sidebar.scss" lang="scss"/>
