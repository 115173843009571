<template>
  <v-app>
    <v-container class="pt-10" fluid>
      <v-card class="schedule-card"
        style="
          max-width: 900px;
          margin-top: 100px;
          margin-left: auto;
          margin-right: auto;
        "
        v-if="!completed && data"
      >
        <v-row class="scheduler-container ma-0">
          <v-col md="4" cols="12"  class="profile-description">
            <div class="profile-logo">
              {{ data.profile.toUpperCase() }}
            </div>
            <v-row class="mt-4 pl-2">
              <div class="green-circle-small"></div>
              <span class="">{{
                data.jobname.split("-").join(" ").toUpperCase()
              }}</span>
            </v-row>
            <v-row class="mt-8 pl-2">
              <b>Email - </b>
             
            </v-row>
            <v-row class="mt-4 pl-2">
              
           {{ $route.params.param1 }}
            </v-row>
            <v-row class="mt-8 pl-2">
              <b>Current interview schedule</b>
             
            </v-row>
            <v-row class="mt-4 pl-2">
              
           {{ new Date(data.interviewtime).toLocaleString() }}
            </v-row>
            <v-row class="mt-8 pl-2 mb-2">
              
              <span style="width:80%"
                >New Interview link will be shared with you after confirmation</span
              >
            </v-row>
          </v-col>
          <v-col md="8" cols="12" v-if="!schedule" class="p-2" style="
          align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;">
    <span class="mb-10 ml-10"><b>Note:</b> Interview can be given only in Laptop/Desktop with working camera and microphone. Schedule it for later, If you don't have it at the moment.</span>
              <v-btn class="primary" elevation="3" size="x-large" @click="setschedule('now')">Give Interview Now</v-btn>
              <br/>
              <v-btn style="border-width:2px; color:#008036;" class="mt-2" variant="outlined" elevation="3" size="x-large" @click="setschedule('later')">Schedule Interview For Later</v-btn>
          </v-col>
          <v-col md="4" cols="12" v-if="schedule && !timepicker" class="p-2">
            <h5 class="font-weight-bold">Select a Date & Time</h5>
            <v-date-picker
            v-bind:class="{'has-value': !!datepicker}"
              no-title
              @input="selectedtime=null;"
              v-model="datepicker"
              :min="getMinDate()"
              :max="getMaxDate()"
            ></v-date-picker>
          </v-col>
          <v-col md="4" sm="12" xs="12" v-if="schedule && datepicker && !timepicker">
            <span class="only-mobile" style="align-items:center;"><i @click="datepicker=null; selectedtime=null;" style="margin-right:10px;color:green;" class="fa fa-arrow-left" aria-hidden="true"></i> Selected Date - {{datepicker}}
            </span>
            <div class="v-schedule-time-list mt-12">
              <div v-for="time in times" v-bind:key="time">
                <v-btn
                  v-if="selectedtime != time"
                  style="width: 100%"
                  @click="selectedtime = time"
                 variant="outlined"
                  color="primary"
                  :disabled="Date.parse(new Date()) > Date.parse(new Date(datepicker + ', ' + time))"
                  >{{ time }}</v-btn
                >
                <div v-if="selectedtime == time">
                  <v-row style="margin: 0; justify-content: space-between">
                    <v-btn dark color="gray" class="selectedtime">{{
                      time
                    }}</v-btn>
                    <v-btn
                      @click="timepicker=selectedtime; rescheduleinterview()"
                      dark
                      variant="flat"
                      class="confirm"
                      >Confirm</v-btn
                    >
                  </v-row>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-if="completed && error" class="confirmation-box">
        <h3>{{error}}</h3>
      </v-card>
      <v-card class="confirmation-box" v-if="completed && !error">
        <h3>Confirmed</h3>
        <p>
          Your interview is scheduled with
          {{ data.profile.toUpperCase() }}
        </p>
        <hr />
        <v-row>
          <div class="green-circle-small"></div>
          {{ data.jobname.split("-").join(" ").toUpperCase() }}
        </v-row>
        <v-row>
          <i class="fa-solid fa-calendar-check"></i>
          {{ timepicker }} &nbsp;, {{ new Date(datepicker).toDateString() }}
        </v-row>
        <v-row>
          <i class="fa-solid fa-earth-asia"></i>
          <span>{{
            new Date()
              .toString()
              .substring(
                new Date().toString().indexOf("(") + 1,
                new Date().toString().indexOf(")")
              )
          }}</span>
        </v-row>
        <v-row>
          <i class="fa-solid fa-video"></i>
          <span>Interview link is shared with you on your email. <span v-if="schedule=='now'">Please check your email to start your interview immediately.</span></span>
        </v-row>

        <h5>A calendar invitation has been sent to your email address.</h5>

        <span>In case you don't receive email in next 5 mins, Please drop an email to  <a href="mailto:care@hulkhire.com">care@hulkhire.com</a></span>
        <hr style="margin-bottom: 150px" />
      </v-card>
    </v-container>
  </v-app>
</template>

<script>

import  config from "../../config" ;
import helperUtils from '../../helper.js';
let serverurl = config.serverurl;
export default {
  name: "Reschedule",
  components: {},
  data() {
    return {
      data:null,
      window: window,
      completed: false,
      times: [],
      datepicker: null,
      timepicker: null,
      error: false,
      selectedtime: null,
      schedule:null
    };
  },
  methods: {
    setschedule(type){
      this.schedule=type;
      if(type=='now'){
        this.datepicker= new Date().toLocaleDateString("en-GB").split("/").reverse().join("-");
        this.timepicker= helperUtils.formatTimeAMPM(new Date());
        this.rescheduleinterview();
      }
      
    },
    getMaxDate() {
      let date = new Date();
      date.setDate(date.getDate() + 15);
      return date.toISOString().slice(0, 10);
    },
    getMinDate() {
      return new Date().toISOString().slice(0, 10);
    },
    rescheduleinterview(){
      console.log(this.datepicker);
      console.log(this.timepicker);
      console.log(this.firstname);
      console.log(this.lastname);
      console.log(this.email);
      console.log("time:" + new Date(this.datepicker.replace(/-/g,"/") + ", " + this.timepicker));

      fetch(serverurl + "/rescheduleinterview", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          interviewtime: new Date(this.datepicker.replace(/-/g,"/") + ", " + this.timepicker),
          candidateid: this.data.id
        }),
      })
        .then((s) => s.json())
        .then((s) => {
          if(s.status=="SUCCESS")
            this.completed = true;
          else
            alert("Rescheduling failed");
        });
  },
  },
  
  created() {
    let param1 = this.$route.params.param1;
    let param2 = this.$route.params.param2;
    if(param1 && param2)
    //   fetch(serverurl+'')
    // document.title = "Reschedule your interview slot with " + profile;
    fetch(serverurl+'/rescheduledetails?email='+param1+'&testid='+param2).then(res=>res.json()).then((res)=>{
      if(res.status=="ERROR"){
        this.completed=true;
        this.error = res.ERROR;
        return;
      }
      this.data=res;
    })
  },
  mounted() {
    var x = 30; //minutes interval
    var tt = 8*60; // start time
    var ap = ["AM", "PM"]; // AM-PM

    for (var i = 0; tt < 21 * 60; i++) {
      var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      var mm = tt % 60; // getting minutes of the hour in 0-55 format
      this.times[i] =
        ("0" + (hh % 12)).slice(-2) +
        ":" +
        ("0" + mm).slice(-2) +
        " " +
        ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt = tt + x;
    }
    this.times[this.times.indexOf("00:00 PM")]="12:00 PM";
     this.times[this.times.indexOf("00:30 PM")]="12:30 PM";
  },
};
</script>

<style src="./Reschedule.scss" lang="scss"/>
