<template>
  <v-row justify="center">
    <v-dialog
    :value="show"
      max-width="600px"
      :scrim="false"
      transition="dialog-bottom-transition"
      align="center"
    >
      <v-card>
        <!-- <v-toolbar dark color="red">
          <v-toolbar-title>Payment failed</v-toolbar-title>
        </v-toolbar> -->
        <v-row justify="center" align="center">
          <v-card width="600" height="auto">
            <v-col
              class="d-flex flex-column justify-center align-center"
              style="background-color: #ececec; height: 150px; color:red"
            >
              <v-icon size="50" color="red">mdi-alert</v-icon>
              <h4 class="mt-2 mb-0">Request Failed</h4>
            </v-col>
            <v-col justify="center" align="center" class="mt-2">
              <div class="mt-4 mb-4" style="color: red">
                <b style="color: red">Sorry! &nbsp;&nbsp; Your request could not be processed because of below error:</b>
              </div>
              <div class="text-grey mb-4">
                {{error}}
              </div>
              <v-divider></v-divider>
              <br />
              <div>
                {{count }} Elite candidates are still waiting for you in your cart <br />
                Would you like to try again?
              </div>
            </v-col>
            <v-card-actions class="mt-10 mb-10">
    <v-row justify="center" align="center">
      <v-btn  variant="outlined" color="primary" @click="redirect()" class="mr-10">Take me to home page</v-btn>
      <v-btn  color="primary" @click="retryPayment()" >Retry payment</v-btn>
    </v-row>
  </v-card-actions>
          </v-card>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
    props:["show","count","error"],
  data() {
    return {
    };
  },
  methods:{
    redirect(){
        this.$parent.$parent.$data.showfailuredialog=false;
    },
    retryPayment(){
        this.$parent.$parent.checkout();
    }
  }
};
</script>
<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}
</style>