<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-3 mb-3 no-gutters" style="background: #fff;
    padding: 4px 10px;align-items: center;">
        <h1 class="page-title">Dashboard</h1>
        <v-spacer></v-spacer>
        <v-btn
        id="create-job-dashboard"
      v-bind="attrs"
      v-on="on"
      color="secondary"
      size="small"
      class="text-capitalize button-shadow mr-1"
      @click="$router.push('/interviews/create')"
      >Create Interview</v-btn>
        <!-- <v-menu offset-y>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="secondary"
              class="text-capitalize button-shadow mr-1"
              >Latest Reports</v-btn
            >
          </template>
        </v-menu> -->
      </v-row>
      <v-row style="background: #fff; padding: 10px" class="no-gutters">
        <v-col lg="2" md="3" sm="6">
          <div width="180" style="width: 200px">
            <v-select
              :items="items"
              label="Select Job"
             variant="outlined"
             item-title="text"
              item-value="id"
              v-model="selectedJob"
            ></v-select>
          </div>
        </v-col>
        <v-col lg="10" md="9" sm="6">
          <div>
            Total Applications - <span
                        class="font-weight-medium card-dark-grey"
                        style="font-size: 18px"
                        >{{ data[selectedJob]? data[selectedJob].totalApplications :null }}</span>
          </div>
          
        </v-col>

        <v-col lg="8" md="7" sm="6" v-if="data[selectedJob]">
          <v-row>
            <!-- <v-col lg="4" sm="6" md="6" cols="12">
              <v-card class="mx-1 mb-1">
                <v-card-title class="pa-6 pb-3">
                  <p>Total Applications</p>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pa-6 pt-0 pb-0">
                  <v-row no-gutters class="pb-5">
                    <v-col cols="5" class="my-auto">
                      <span
                        class="font-weight-medium card-dark-grey"
                        style="font-size: 36px"
                        >{{ data[selectedJob].totalApplications }}</span
                      >
                    </v-col>
                    <v-col cols="6">
                      <ApexChart
                        height="150"
                        width="100"
                        v-if="apexLoading"
                        :options="radialOptions"
                        type="radialBar"
                        :series="[100]"
                      ></ApexChart>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col> -->
            <v-col lg="4" sm="6" md="6" cols="12">
              <v-card class="mx-1 mb-1">
                <v-card-title class="pa-6 pb-3">
                  <p>Interview Scheduled</p>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pa-6 pt-0 pb-0">
                  <v-row no-gutters class="pb-5">
                    <v-col cols="5" class="my-auto">
                      <span
                        class="font-weight-medium card-dark-grey"
                        style="font-size: 36px"
                        >{{ data[selectedJob]["Invitation Sent"] }}</span
                      >
                    </v-col>
                    <v-col cols="6">
                      <ApexChart
                        height="150"
                        width="100"
                        v-if="apexLoading"
                        :options="getOptions('Pending')"
                        type="radialBar"
                        :series="[
                          +(
                            (data[selectedJob]['Invitation Sent'] /
                              data[selectedJob].totalApplications) *
                            100
                          ).toFixed(2) || 0,
                        ]"
                      ></ApexChart>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col lg="4" sm="6" md="6" cols="12">
              <v-card class="mx-1 mb-1">
                <v-card-title class="pa-6 pb-3">
                  <p>Review required</p>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pa-6 pt-0 pb-0">
                  <v-row no-gutters class="pb-5">
                    <v-col cols="5" class="my-auto">
                      <span
                        class="font-weight-medium card-dark-grey"
                        style="font-size: 36px"
                        >{{ data[selectedJob]["BM Verified"] }}</span
                      >
                    </v-col>
                    <v-col cols="6">
                      <ApexChart
                        height="150"
                        width="100"
                        v-if="apexLoading"
                        :options="getOptions('Review')"
                        type="radialBar"
                        :series="[
                          +(
                            (data[selectedJob]['BM Verifiedn'] /
                              data[selectedJob].totalApplications) *
                            100
                          ).toFixed(2) || 0,
                        ]"
                      ></ApexChart>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col lg="4" sm="6" md="6" cols="12">
              <v-card class="mx-1 mb-1">
                <v-card-title class="pa-6 pb-3">
                  <p>No Show</p>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pa-6 pt-0 pb-0">
                  <v-row no-gutters class="pb-5">
                    <v-col cols="5" class="my-auto">
                      <span
                        class="font-weight-medium card-dark-grey"
                        style="font-size: 36px"
                        >{{ data[selectedJob]["No Show"] }}</span
                      >
                    </v-col>
                    <v-col cols="6">
                      <ApexChart
                        height="150"
                        width="100"
                        v-if="apexLoading"
                        :options="getOptions('NoShow')"
                        type="radialBar"
                        :series="[
                          +(
                            (data[selectedJob]['No Show'] /
                              data[selectedJob].totalApplications) *
                            100
                          ).toFixed(2) || 0,
                        ]"
                      ></ApexChart>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- <v-col lg="4" sm="6" md="6" cols="12">
              <v-card class="mx-1 mb-1">
                <v-card-title class="pa-6 pb-3">
                  <p>HH Rejected</p>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pa-6 pt-0 pb-0">
                  <v-row no-gutters class="pb-5">
                    <v-col cols="5" class="my-auto">
                      <span
                        class="font-weight-medium card-dark-grey"
                        style="font-size: 36px"
                        >{{ data[selectedJob]["BM Rejected"] }}</span
                      >
                    </v-col>
                    <v-col cols="6">
                      <ApexChart
                        height="150"
                        width="100"
                        v-if="apexLoading"
                        :options="getOptions('BMRejected')"
                        type="radialBar"
                        :series="[
                          +(
                            (data[selectedJob]['BM Rejected'] /
                              data[selectedJob].totalApplications) *
                            100
                          ).toFixed(2) || 0,
                        ]"
                      ></ApexChart>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col> -->
            <!-- <v-col lg="4" sm="6" md="6" cols="12">
              <v-card class="mx-1 mb-1">
                <v-card-title class="pa-6 pb-3">
                  <p>BM Under Verification</p>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pa-6 pt-0 pb-0">
                  <v-row no-gutters class="pb-5">
                    <v-col cols="5" class="my-auto">
                      <span
                        class="font-weight-medium card-dark-grey"
                        style="font-size: 36px"
                        >{{ data[selectedJob]["BM Under Verification"] }}</span
                      >
                    </v-col>
                    <v-col cols="6">
                      <ApexChart
                        height="150"
                        width="100"
                        v-if="apexLoading"
                        :options="getOptions('BMVerification')"
                        type="radialBar"
                        :series="[
                          +(
                            (data[selectedJob]['BM Under Verificationn'] /
                              data[selectedJob].totalApplications) *
                            100
                          ).toFixed(2) || 0,
                        ]"
                      ></ApexChart>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col> -->
            <!-- <v-col lg="4" sm="6" md="6" cols="12">
              <v-card class="mx-1 mb-1">
                <v-card-title class="pa-6 pb-3">
                  <p>BM Verified</p>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pa-6 pt-0 pb-0">
                  <v-row no-gutters class="pb-5">
                    <v-col cols="5" class="my-auto">
                      <span
                        class="font-weight-medium card-dark-grey"
                        style="font-size: 36px"
                        >{{ data[selectedJob]["BM Verified"] }}</span
                      >
                    </v-col>
                    <v-col cols="6">
                      <ApexChart
                        height="150"
                        width="100"
                        v-if="apexLoading"
                        :options="getOptions('BMVerified')"
                        type="radialBar"
                        :series="[
                          +(
                            (data[selectedJob]['BM Verified'] /
                              data[selectedJob].totalApplications) *
                            100
                          ).toFixed(2) || 0,
                        ]"
                      ></ApexChart>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col> -->
            
            <v-col lg="4" sm="6" md="6" cols="12">
              <v-card class="mx-1 mb-1">
                <v-card-title class="pa-6 pb-3">
                  <p>Approved</p>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pa-6 pt-0 pb-0">
                  <v-row no-gutters class="pb-5">
                    <v-col cols="5" class="my-auto">
                      <span
                        class="font-weight-medium card-dark-grey"
                        style="font-size: 36px"
                        >{{ data[selectedJob].Approved }}</span
                      >
                    </v-col>
                    <v-col cols="6">
                      <ApexChart
                        height="150"
                        width="100"
                        colors="#05D0DD"
                        v-if="apexLoading"
                        :options="getOptions('Approved')"
                        type="radialBar"
                        :series="[
                          +(
                            (data[selectedJob]['Approved'] /
                              data[selectedJob].totalApplications) *
                            100
                          ).toFixed(2) || 0,
                        ]"
                      ></ApexChart>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col lg="4" sm="6" md="6" cols="12">
              <v-card class="mx-1 mb-1">
                <v-card-title class="pa-6 pb-3">
                  <p>Hold</p>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pa-6 pt-0 pb-0">
                  <v-row no-gutters class="pb-5">
                    <v-col cols="5" class="my-auto">
                      <span
                        class="font-weight-medium card-dark-grey"
                        style="font-size: 36px"
                        >{{ data[selectedJob].Hold }}</span
                      >
                    </v-col>
                    <v-col cols="6">
                      <ApexChart
                        height="150"
                        width="100"
                        v-if="apexLoading"
                        :options="getOptions('Hold')"
                        type="radialBar"
                        :series="[
                          +(
                            (data[selectedJob]['Hold'] /
                              data[selectedJob].totalApplications) *
                            100
                          ).toFixed(2) || 0,
                        ]"
                      ></ApexChart>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col lg="4" sm="6" md="6" cols="12">
              <v-card class="mx-1 mb-1">
                <v-card-title class="pa-6 pb-3">
                  <p>Rejected</p>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pa-6 pt-0 pb-0">
                  <v-row no-gutters class="pb-5">
                    <v-col cols="5" class="my-auto">
                      <span
                        class="font-weight-medium card-dark-grey"
                        style="font-size: 36px"
                        >{{ data[selectedJob].Rejected }}</span
                      >
                    </v-col>
                    <v-col cols="6">
                      <ApexChart
                        height="150"
                        width="100"
                        v-if="apexLoading"
                        :options="getOptions('Rejected')"
                        type="radialBar"
                        :series="[
                          +(
                            (data[selectedJob]['Rejected'] /
                              data[selectedJob].totalApplications) *
                            100
                          ).toFixed(2) || 0,
                        ]"
                      ></ApexChart>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="4" md="5" sm="6">
          <v-card
            class="mx-1 mb-1"
            style="height: 100%"
            v-if="data[selectedJob]"
          >
            <v-card-title class="pa-6 pb-3">
              <p>Recruitment Funnel</p>
            </v-card-title>
            <v-card-text>
              <br/>
              <ul class="funnel">
                <li>Interview Scheduled - {{ data[selectedJob].totalApplications }}</li>
                <li>
                  Interview given -
                  {{ data[selectedJob].reach_waitingforevaulation }}
                </li>
                <li>
                  Review Required -
                  {{ data[selectedJob].reach_bm_verified }}
                </li>
                <!-- <li>
                  BM Verified -
                  {{ data[selectedJob].reach_bm_verified }}
                </li> -->
                  <!-- <li>Hold - {{ data[selectedJob].reach_hold }}</li>
                  <li>Rejected - {{ data[selectedJob].reach_rejected }}</li> -->
                <li>Approved - {{ data[selectedJob].reach_approved }}</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row  class="no-gutters">
        <v-col lg="4"  md="4" sm="12" cols="12" class="pl-0">
          <v-card class="mx-1 mb-1" style="height: 100%" v-if="data['All']">
          <v-card-title class="pa-6 pb-3">
            <p>
              Applications by department - ({{ data["All"].totalApplications }})
            </p>
          </v-card-title>
          <v-card-text>
            <ApexChart
              v-if="apexLoading"
              
              :options="departmentapplications"
              :series="departmentapplications.series"
            ></ApexChart>
          </v-card-text>
        </v-card>
        </v-col>
        <v-col lg="4"  md="4" sm="12" cols="12">
          <v-card class="mx-1 mb-1" style="height: 100%" v-if="data['All']">
          <v-card-title class="pa-6 pb-3">
            <p>
              Open positions by department - ({{ data["All"].head_counts }})
            </p>
          </v-card-title>
          <v-card-text>
            <ApexChart
              v-if="apexLoading"
              type="donut"
              :options="departmentapplications"
              :series="departmentapplications.headcounts"
            ></ApexChart>
          </v-card-text>
        </v-card>
        </v-col>
        <v-col lg="4"  md="4" sm="12" cols="12" v-if="items.length>1">
          <v-card class="mx-1 mb-1" style="height: 100%" v-if="data['All']">
          <v-card-title class="pa-6 pb-3">
            <p>
              Active Jobs - ({{ items.length-1}})
            </p>
          </v-card-title>
          <v-card-text>
            <ul class="test-list">
              <li v-for="test in (items.length>6 ? items.slice(1,6): items.slice(1))" v-bind:key="test.id">
               <b> {{test.text}} </b>
                <v-spacer></v-spacer>{{data[test.id].totalApplications}} Applications</li>
            </ul>
          </v-card-text>
        </v-card>
        </v-col>
      </v-row> -->
      <v-row  class="no-gutters mt-2" style="">
        <b style="text-decoration:underline" class="mb-4">
      Recent Interviews</b>
      <br/>
      </v-row>
      
      <v-row style="overflow:auto"  class="no-gutters">
        <div class="c-header">
          <div>Name</div>
          <div>Job</div>
          <div>Status</div>
          <div>Technology</div>
          <div>Attempted On</div>
          <div></div>
        </div>
        <!-- {{candidates.length}} -->
        <div  v-for="c in candidates" v-bind:key="c.candidate_id" class="c-list">
          <div>{{c.name}}</div>
          <div>{{items.find(s=>s.id==c.testid)? items.find(s=>s.id==c.testid).text: "-"}}</div>
          <div>{{c.status}}</div>
          <div>{{c.Technology}}</div>
          <div>{{new Date(c.date_attempted).toDateString()  }}</div>
          <div>
            <button class="sec" style="text-decoration:underline" @click="$router.push('/interviews/candidatedetails/'+c.candidate_id)">Details</button>
          </div>
        </div>
        <div v-if="candidates.length==0"  class="c-list"> No recent interviews</div>
        <br/>
        <br/>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { store } from "../../store";
// import mock from "./mock";
// import Trend from "vuetrend";
import ApexChart from "vue3-apexcharts";
// let VueFunnelGraph;
// import { VueFunnelGraph } from 'vue-funnel-graph-js';
import  config from "../../config" 
let serverurl = config.serverurl;
import candidateStatus from '../../master/candidateStatus.js';
import statusmapping from '../../master/statusmapping';
import help from '../../helper.js';

export default {
  name: "Dashboard",
  components: {
    // Trend,
    ApexChart,
    // VueFunnelGraph
  },
  data() {
    return {
      // mock,
      departmentapplications: {
        chart: {
          width: 500,
          type: "pie",
        },

        labels: [],
        series: [],
      },
      apexLoading: false,
      items: [
        {
          id: "All",
          text: "All Jobs",
        },
      ],
      selectedJob: "All",
      // value: this.getRandomInt(10, 90),
      // value2: this.getRandomInt(10, 90),
      // mainApexAreaSelect: "Daily",
      candidates:[],
      data: {},
      radialOptions: {
        colors: ["#AD5FD8"],
        plotOptions: {
          radialBar: {
            hollow: {
              size: "50%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: 7,
                show: true,
                color: "#AD5FD8",
              },
            },
          },
        },
      },
    };
  },
  methods: {
    getOptions(category) {
      let options;
      if (category == "Approved") {
        options = JSON.parse(JSON.stringify(this.radialOptions));
        options.colors = ["#05D0DD"];
        options.plotOptions.radialBar.dataLabels.value.color = "#05D0DD";
      } else if (category == "Review") {
        options = JSON.parse(JSON.stringify(this.radialOptions));
        options.colors = ["#99914d"];
        options.plotOptions.radialBar.dataLabels.value.color = "#99914d";
      } else if (category == "Rejected") {
        options = JSON.parse(JSON.stringify(this.radialOptions));
        options.colors = ["#FF5C5C"];
        options.plotOptions.radialBar.dataLabels.value.color = "#FF5C5C";
      }
      if (category == "Pending") {
        options = JSON.parse(JSON.stringify(this.radialOptions));
        options.colors = ["#3E7BFA"];
        options.plotOptions.radialBar.dataLabels.value.color = "#3E7BFA";
      }
      if (category == "NoShow" || category == "BMRejected" || category == "BMVerification"||category == "BMVerified") {
        options = JSON.parse(JSON.stringify(this.radialOptions));
        options.colors = ["#3E7BFA"];
        options.plotOptions.radialBar.dataLabels.value.color = "#3E7BFA";
      }
      if (category == "Hold") {
        options = JSON.parse(JSON.stringify(this.radialOptions));
        options.colors = ["#FDAC42"];
        options.plotOptions.radialBar.dataLabels.value.color = "#FDAC42";
      }
      return options;
    }
  },
  mounted() {
    if(store.profile &&store.profile.role=="bm_reviewer")
      {this.$router.push('/interviews/candidates');return;}
    fetch(serverurl + "/getJobStatusData", { credentials: "include" })
      .then((res) => res.json())
      .then((res) => {

        let departments = {};
        let tests = {
          All: {
            totalApplications: 0,
            reach_interviewinvitation:0,
            reach_waitingforevaulation: 0,
            reach_noshow: 0,
            reach_bm_rejected: 0,
            reach_bm_verified: 0,
            reach_bm_verification: 0,
            reach_approved: 0,
            reach_rejected: 0,
            reach_hold: 0,
            "Slot Mail Sent":0,
            "Invitation Sent": 0,
            "Waiting for evaluation": 0,
            "No Show": 0,
            "BM Rejected": 0,
            "BM Under Verification": 0,
            "BM Verified": 0,
            Approved: 0,
            Hold: 0,
            Rejected: 0,
            head_counts: 0,
          },
        };

        res.map((r) => {
          if(r.test_status=='REVIEW')
            return;
          if (!r.candidate_count) r.candidate_count = 0;
          if (!departments[r.department]) {
            departments[r.department] = {
              applications: 0,
              head_counts: 0,
            };
          }
          departments[r.department].applications += r.candidate_count;
          if (!tests[r.testid]) {
            tests[r.testid] = {
              totalApplications: 0,
              reach_interviewinvitation:0,
              reach_waitingforevaulation: 0,
              reach_noshow: 0,
              reach_bm_rejected: 0,
              reach_bm_verified: 0,
              reach_bm_verification: 0,
              reach_approved: 0,
              reach_rejected: 0,
              reach_hold: 0,
              "Slot Mail Sent":0,
              "Invitation Sent": 0,
              "Waiting for evaluation": 0,
              "No Show": 0,
              "BM Rejected": 0,
              "BM Under Verification": 0,
              "BM Verified": 0,
              Approved: 0,
              Hold: 0,
              Rejected: 0,
            };
            this.items.push({ id: r.testid, text: r.testname +"-"+r.createdby });
            departments[r.department].head_counts += r.headcount;
            tests[r.testid].head_counts = r.headcount;
            tests["All"].head_counts += r.headcount;
          }
          tests[r.testid].totalApplications += r.candidate_count;
          tests["All"].totalApplications += r.candidate_count;
          if (candidateStatus[r.status] >= 1) {
            tests[r.testid].reach_interviewinvitation += r.candidate_count;
            tests["All"].reach_interviewinvitation += r.candidate_count;
          }
          if (candidateStatus[r.status] >= 3 && candidateStatus[r.status] != 10 ) {
            tests[r.testid].reach_waitingforevaulation += r.candidate_count;
            tests["All"].reach_waitingforevaulation += r.candidate_count;
          }
          if (candidateStatus[r.status] == 10 ) {
            tests[r.testid].reach_noshow += r.candidate_count;
            tests["All"].reach_noshow += r.candidate_count;
            tests[r.testid]["No Show"] += r.candidate_count;
            tests["All"]["No Show"] += r.candidate_count;
          }
          if (candidateStatus[r.status] == 11 ) {
            tests[r.testid].reach_bm_rejected += r.candidate_count;
            tests["All"].reach_bm_rejected += r.candidate_count;
            tests[r.testid]["BM Rejected"] += r.candidate_count;
            tests["All"]["BM Rejected"] += r.candidate_count;
          }
          if (candidateStatus[r.status] == 12) {
            tests[r.testid]['BM Under Verification'] += r.candidate_count;
            tests["All"]['BM Under Verification'] += r.candidate_count;
          }
          if (candidateStatus[r.status] == 13) {
            tests[r.testid]["BM Verified"] += r.candidate_count;
            tests["All"]['BM Verified'] += r.candidate_count;
          }
          if ([4,5,6, 7, 8, 9,12,13].indexOf(candidateStatus[r.status]) > -1) {
            tests[r.testid].reach_bm_verification += r.candidate_count;
            tests["All"].reach_bm_verification += r.candidate_count;
          }
          if ([4,5,6, 7, 8, 9,13].indexOf(candidateStatus[r.status]) > -1) {
            tests[r.testid].reach_bm_verified += r.candidate_count;
            tests["All"].reach_bm_verified += r.candidate_count;
          }
          if (candidateStatus[r.status] == 5) {
            tests[r.testid].reach_rejected += r.candidate_count;
            tests[r.testid]["Rejected"] += r.candidate_count;

            tests["All"].reach_rejected += r.candidate_count;
            tests["All"]["Rejected"] += r.candidate_count;
          }
          if (candidateStatus[r.status] == 6) {
            tests[r.testid].reach_hold += r.candidate_count;
            tests[r.testid]["Hold"] += r.candidate_count;

            tests["All"].reach_hold += r.candidate_count;
            tests["All"]["Hold"] += r.candidate_count;
          }
          if ([4, 7, 8, 9].indexOf(candidateStatus[r.status]) > -1) {
            tests[r.testid].reach_approved += r.candidate_count;
            tests[r.testid]["Approved"] += r.candidate_count;
            tests["All"].reach_approved += r.candidate_count;
            tests["All"]["Approved"] += r.candidate_count;
          }
          if (candidateStatus[r.status] <= 2 &&  candidateStatus[r.status] >= 0) {
            tests[r.testid]["Invitation Sent"] += r.candidate_count;
            tests["All"]["Invitation Sent"] += r.candidate_count;
          }
          if (candidateStatus[r.status] <= 0) {
            tests[r.testid]["Slot Mail Sent"] += r.candidate_count;
            tests["All"]["Slot Mail Sent"] += r.candidate_count;
          }
          if (candidateStatus[r.status] == 3) {
            tests[r.testid]["Waiting for evaluation"] += r.candidate_count;
            tests["All"]["Waiting for evaluation"] += r.candidate_count;
          }
        });
        this.data = tests;
        this.departmentapplications.labels = Object.keys(departments);

        this.departmentapplications.series = Object.keys(departments).map(
          (z) => departments[z].applications
        );
        this.departmentapplications.headcounts = Object.keys(departments).map(
          (z) => departments[z].head_counts
        );
        console.log(this.departmentapplications);
        // If job is not created yet
        if(Object.keys(tests).length==1){
          help.nextStep(this,0)
        }
      });
    
    fetch(serverurl+"/getRecentInterviews", { credentials: "include" }).then(res=>res.json()).then(res=>{
      res.map(c=>c.status= (statusmapping[c.status]||c.status).split("-")[0])
      
      this.candidates=res;});
    setTimeout(() => {
      this.apexLoading = true;
    });
    
  },
  beforeDestroy(){
    console.log("unmounted")
    // this.$tours["myTour"].stop()
  }
};
</script>

<style src="./Dashboard.scss" lang="scss"/>
