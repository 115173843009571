<template>
  <v-dialog
    class="pa-10"
    v-model="visible"
    max-width="400px"
    @keydown.esc="changeShow()"
    @click:outside="changeShow()"
  >
    <v-card>
      <v-card-title style="justify-content:space-between;">
        <p class="mt-4" style="color:black;">Add User</p>
        <v-btn style="float: right" size="small"   variant="plain" icon @click="changeShow()" top right>
          <v-icon>mdi-close</v-icon>
        </v-btn></v-card-title
      >
      <v-card-text class="pt-10">
        <v-row>
          <v-text-field
            v-model="username"
            color="primary"
            label="Username"
            placeholder="Username"
            :rules="clientRules"
            required
            variant="underlined"
          ></v-text-field>
        </v-row><v-row>
          <v-text-field
            v-model="email"
            label="Email"
            placeholder="Email"
            :rules="emailRules"
            color="primary"
            required
            variant="underlined"
          ></v-text-field></v-row><v-row>
          <v-select
                style="width: 200px"
                v-model="role"
                :items="roles"
                required
                variant="underlined"
                color="primary"
              >
              </v-select>

          <!-- <v-snackbar v-model="snackbar" timeout="2000">
            Link Copied
          </v-snackbar> -->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <span style="color:red">{{errormsg}}</span>
              <v-spacer></v-spacer>
              <v-btn color="primary" 
              variant="flat"
              @click="addUser" :disabled="!username ||
                  !role ||
                  !email ||
                  !pattern.test(email)">
                Add User
              </v-btn>
            </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
.v-dialog  .v-card  .v-card-title {
  background-color: #fff !important;
}

.v-card-title {
  justify-content: end;
}
</style>
<script>
import  config from "../../config" 
let serverurl = config.serverurl;
export default {
  props: ["show",  "changeShow","selecteduser"],
  data() {
    return {
      pattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      username:null,
      errormsg:null,
      role:null,
      email:null,
      visible: this.show,
      snackbar: false,
      roles:["Admin","Recruiter"],
      clientRules: [(v) => !!v || "Required"],
      emailRules: [
        (v) => !!v || "Required",
        (v) => {
          return this.pattern.test(v) || "Invalid e-mail.";
        },
      ],
    };
  },
  watch: {
    show: function (newVal) {
      console.log("Show:" + newVal);
      this.visible = newVal;
    },
    // visible: function(){
    //   this.changeShow();
    // }
  },
  methods: {
    
    addUser(){
      this.errormsg=null;
      fetch(serverurl + "/adduser", { 
        credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: this.username, role: this.role.toLowerCase(),email: this.email })
        }).then(res=>res.text())
        .then((res) => {
          if(res!="SUCCESS"){
            this.errormsg=res;
            return;
          }
            
          this.changeShow();
          this.username=null;
          this.role=null;
          this.email=null;
          });
    }
  },
};
</script>