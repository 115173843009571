<template>
  <v-app-bar class="main-header" height="40" fixed color="primary-darken-1" >
    <!-- @click.stop="store.drawer = !store.drawer" -->
    <v-btn
      size="small"
      icon
      class="mx-1 menu-btn"
      v-if="store.profile"
      @click.stop="store.drawer = !store.drawer"
    >
      <template v-if="store.drawer">
        <v-icon style="font-size: 18px">mdi-arrow-left</v-icon>
      </template>
      <template v-else>
        <v-icon style="font-size: 18px">mdi-menu</v-icon>
      </template>
    </v-btn>
    <v-toolbar-title class="pr-4" style="border-right: 2px solid #ddd;flex-grow: 0;flex-basis: auto;">HulkHire</v-toolbar-title>
    <!-- <v-menu> -->
      <div class="ml-2 hidden md:block">
      <!-- <v-btn class="ml-4" v-bind:class="{'active':active=='talents'}" @click="active='talents'" text href="#/talents/sourcing">Talents </v-btn> -->
      <v-btn text  v-bind:class="{'active': active=='interviews'}" @click="active='interviews'" href="/interviews/dashboard">Interviews </v-btn>
      <v-btn text  v-if="store.profile && store.profile.role.toUpperCase()=='ADMIN'" v-bind:class="{'active': active=='admin'}" @click="active='admin'" href="/admin/users">Admin </v-btn>
      </div>
      <!-- <v-list width="400px">
      <v-list-item>
        
      </v-list-item>
      </v-list> -->
    <!-- </v-menu> -->
    <v-spacer></v-spacer>
    <success-dialog :show="showsuccessdialog" :count="count"></success-dialog>
    <failure-dialog :show="showfailuredialog" :error="paymenterror" :count="count"></failure-dialog>

    <!-- <v-menu offset-y bottom nudge-bottom="10" left style="width: 500px">
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          style="font-size: 28px"
          icon
          class="mr-2"
        >
          <v-badge
            v-if="store.cartCandidates.length > 0"
            :value="notificationsBadge"
            color="error"
            :content="store.cartCandidates.length"
            overlap
          >
            <v-icon style="font-size: 28px" color="rgba(255, 255, 255, 0.35)"
              >mdi-cart-outline</v-icon
            >
          </v-badge>
          <v-icon
            v-if="store.cartCandidates.length == 0"
            style="font-size: 28px"
            color="rgba(255, 255, 255, 0.35)"
            >mdi-cart-outline</v-icon
          >
        </v-btn>
      </template>
      <v-list width="400px">
        <v-list-item-group
          color="primary"
          v-if="store.cartCandidates.length > 0"
        >
          <v-list-item
            width="400"
            v-for="(item, i) in store.cartCandidates"
            :key="i"
          >
            <v-list-item-icon class="mr-1 mb-1">
              <v-img height="50" @click="redirect(item.id)" width="50" :src="item.image"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-col cols="8">
                <v-list-item-title v-text="item.firstname"></v-list-item-title>
                <v-list-item-subtitle class="text-capitalize">
                  {{
                    item.experience > 0 ? item.experience + "+ yrs" : "Fresher"
                  }}
                  • {{ item.technology }} •
                  {{ item.qualification }}</v-list-item-subtitle
                >
                <v-row class="mt-0 ml-1">
                  <v-rating
                    :value="item.score / 2"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="14"
                  ></v-rating>
                </v-row>
              </v-col>
              <v-col cols="4" class="text-right">
                <v-list-item-title
                  v-text="item.price + ' INR'"
                ></v-list-item-title>
              </v-col>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="font-weight-bold"
            color="orange"
            style="color: orange"
          >
            <v-col cols="8" class="pl-0">Total Amount:</v-col>
            <v-col cols="4" class="align-end text-right" color="orange"
              >{{
                store.cartCandidates.reduce((sum, c) => (sum += c.price), 0)
              }}
              INR</v-col
            >
          </v-list-item>
          <div class="d-flex justify-center my-3">
            <v-btn
              size="large"
              color="primary"
              elevation="5"
              class="text-capitalize"
              @click="checkout()"
            >
              Checkout
              <v-icon right dark>mdi-send</v-icon>
            </v-btn>
          </div>
        </v-list-item-group>
        <v-list-item-group
          color="primary"
          v-if="store.cartCandidates.length == 0"
        >
          <v-list-item>No Talent in the Cart</v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu> -->

    
    <!-- <Search /> -->

    <!-- <v-menu
      offset-y
      bottom
      nudge-bottom="10"
      left>
        <template v-slot:activator="{ props }">
          <v-btn
            @click="notificationsBadge ? notificationsBadge = !notificationsBadge : ''"
            v-bind="attrs"
            v-on="on"
            style="font-size: 28px"
            icon
            class="mr-2">
              <v-badge
Ty                :value="notificationsBadge"
                color="error"
                content="4"
                overlap>
                  <v-icon
                    style="font-size: 28px"
                    color="rgba(255, 255, 255, 0.35)">mdi-bell-outline</v-icon>
              </v-badge>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, i) in notifications"
              :key="i">
                <v-list-item-icon class="mr-4 mb-1">
                  <v-icon
                    :color="item.color"
                    v-text="item.icon">
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
    </v-menu>
      <v-menu
      max-width="280"
      offset-y
      bottom
      nudge-bottom="10"
      left>
      <template v-slot:activator="{ props }">
        <v-btn
          @click="messageBadge ? messageBadge = !messageBadge : ''"
          v-bind="attrs"
          v-on="on"
          style="font-size: 28px"
          icon
          class="mr-2">
          <v-badge
              :value="messageBadge"
              color="warning"
              content="3"
              overlap>
              <v-icon
                  :color="config.light.iconColor"
                  style="font-size: 28px">
                  mdi-email-outline</v-icon>
            </v-badge>
        </v-btn>
      </template>
      <v-list>
        <div class="text-h5 grey--text text--darken-3 px-4 pt-4">New Messages</div>
        <div class="subtitle-2 error--text font-weight-regular px-4 pb-2 link-item">4 new Messages</div>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in messages"
            :key="i">
            <v-list-item-icon class="mr-4 mb-1 d-flex flex-column">
              <v-btn
                fab
                size="x-small"
                depressed
                :color="item.color">{{ item.text }}
              </v-btn>
              <div style="font-size: 11px">{{ item.time }}</div>
            </v-list-item-icon>
            <v-list-item two-line >
              <v-list-item-content style="width: 190px">
                <v-list-item-title v-text="item.name" class="pb-2"></v-list-item-title>
                <v-list-item-subtitle v-text="item.message" class="text-truncate"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
          </v-list-item-group>
        <div class="d-flex justify-center my-3">
            <v-btn
              size="large"
              color="primary"
              rounded
              elevation="5"
              class="text-capitalize">
              Send New Message
            <v-icon right dark>mdi-send</v-icon>
          </v-btn>
          </div>
      </v-list>
    </v-menu> -->
    <v-btn
      v-if="!store.profile"
      style="background: #fff"
      size="small"
     variant="outlined"
      color="primary"
      class="text-capitalize"
      @click="login"
      >Login
    </v-btn>
    <!-- <button style="color: #000; background: #fff;padding: 5px 10px;border-radius:3px;"> Login</button> -->
    <v-menu
      :min-width="180"
      offset-y
      bottom
      left
      nudge-bottom="10"
      v-if="store.profile"
      style="z-index: 100000"
    >
      <template v-slot:activator="{ props }">
        <v-btn class="mr-0" icon v-bind="props">
          <v-icon style="font-size: 18px" :color="config.light.iconColor"
            >mdi-account</v-icon
          >
        </v-btn>
      </template>
      <v-list :min-width="180">
        <div
          class="text-h5 grey--text text--darken-3 px-4 pt-4 text-capitalize"
          style="text-transform: capitalize"
        >
          {{ store.profile.username }}
        </div>
        <div
          class="subtitle-2 text-primary font-weight-regular px-4  text-capitalize"
          
        >
          {{ store.profile.profile }}
        </div>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in account" :key="i">
            <v-list-item-icon class="mr-4">
              <v-icon :color="item.color" v-text="item.icon"> </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                :color="config.light.textColor"
                v-text="item.text"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <div class="d-flex justify-center my-3">
          <v-btn
            width="80%"
            size="large"
           variant="outlined"
            color="primary"
            class="text-capitalize"
            @click="logOut"
            >Sign Out
          </v-btn>
        </div>
      </v-list>
    </v-menu>
    
  </v-app-bar>
</template>
<script>
import { store } from "../../store/index";
import { getUserDetails } from "../../server/api";
import { getCartItems, checkout,updatePaymentStatus } from "../../server/sourcingapi.js";
import config from "../../config";
import help from "../../helper";
import SuccessDialog from "./SuccessDialog.vue";
import FailureDialog from "./FailureDialog.vue";

export default {
  name: "Header",
  components: {
    SuccessDialog,FailureDialog
  },
  data: () => ({
    config,
    searchCollapse: true,
    account: [],
    notificationsBadge: true,
    messageBadge: true,
    store,
    orderid:null,
    showsuccessdialog:false,
    showfailuredialog:false,
    paymenterror:"",
    count:0,
    active:null
  }),
  computed: {
    
  },
  methods: {
    redirect(candidateid){
      window.open(location.origin + '/#/talents/candidatedetails/' + candidateid,'_blank')
    },
    logOut: function () {
      let domain=".hulkhire.com"
      let cookiename="token";
      if(config.environment != "PROD"){
        domain=".sandbox.hulkhire.com"
        cookiename =`token_sandbox`;
      }

      document.cookie =
        `${cookiename}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${domain};`;
      window.location.href = "/";
    },
    login: function () {
      this.$router.push("/login");
    },
    loadCartItems() {
      getCartItems().then((res) => {
        res.cartItems.map((c) => help.formatCandidateSourcing(c));
        store.cartCandidates = res.cartItems;
        this.store.cartCandidates=res.cartItems;
        this.$root.$emit("cart-items-updated")
      });
    },
    async onSuccess(response){
      await updatePaymentStatus("SUCCESS", response, this.orderid);
      this.showfailuredialog=false;
      this.showsuccessdialog=true;
      this.loadCartItems();
    },
    async onFailure(response){
      await updatePaymentStatus("FAILURE  ", response, this.orderid);
      console.log(response)
      this.paymenterror=response.error.description;
      this.showfailuredialog=true;
      this.showsuccessdialog=false;
      this.loadCartItems();
    },
    async checkout() {
      this.count=store.cartCandidates.length;
      let orderdetails= await checkout();
      this.orderid=orderdetails.receipt;
      var options = {
        key: orderdetails.key,
        amount: orderdetails.amount,
        currency: "INR",
        name: "HulkHire",
        description: store.cartCandidates.length + " Ready to Hire candidates",
        image: "https://interview.hulkhire.com/logo/minor-black.png",
        order_id: orderdetails.id,
        handler: this.onSuccess,
        prefill: {
          name: store.profile.username,
          email: store.profile.email,
          contact:"9999999999"
        },
        notes: {
          address: "HulkHire Corporate Office",
        },
        theme: {
          color: "#008036",
        },
      };
      var rzp1 = new window.Razorpay(options);
      console.log(updatePaymentStatus)
      rzp1.on("payment.failed", this.onFailure);
      rzp1.open();
    },
  },
  mounted() {
    ['talents','interviews','admins'].map(s=>{
      if(location.href.indexOf(s)>-1)
        this.active=s;
      })
    if (!store.profile) {
      console.log(store.drawer);
      getUserDetails().then((res) => {
        if (!res.profile && location.href.indexOf("candidatedetails") == -1) {
          this.$router.push("/login");
          return;
        }
        this.loadCartItems();
        store.profile = res.profile;
        store.candidateAccess = res.candidateAccess;
      });
    } else {
      this.loadCartItems();
    }
    // this.$root.$on("added-to-cart",()=>{
    //   this.loadCartItems();
    // })
  },
};
</script>

<style src="./Header.scss" lang="scss"></style>
