<template>
  <v-app>
    <v-container class="pt-10" fluid>
      <v-card class="schedule-card" style="
          max-width: 900px;
          margin-top: 100px;
          margin-left: auto;
          margin-right: auto;
        ">
        <v-row v-if="candidate" class="no-gutters">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-card-title class="text-h5" style="justify-content: start; padding: 0;">
              Invite candidate for Interview
            </v-card-title>
          </v-col>
          <v-col cols="6">
            <v-typography class="text-subtitle-2">Candidate Name:</v-typography> &nbsp; {{ candidate.name }}
          </v-col>
          <v-col cols="6">
            <v-typography class="text-subtitle-2">Candidate Email:</v-typography> &nbsp;{{ candidate.email }}
          </v-col>
          <!-- <v-col cols="6" v-if="candidate.opportunityid">
            <v-typography class="text-subtitle-2">Lever Opportunity:</v-typography> &nbsp;{{ candidate.opportunityName }}
          </v-col> -->
          <v-col cols="12">
            <label class="text-subtitle-2">Choose Interview</label>
          </v-col>
          <v-col cols="12">
            <v-select style="margin-top: -10px;" :items="tests" placeholder="Select Interview" item-title="testname"
              item-value="id" v-model="selectedTest" variant="outlined">
            </v-select>
          </v-col>
          <v-col cols="12" class="pt-0" style="margin-top: -20px;">
            <v-typography>Want to create a new interview? <a target="_blank" href="/interviews/create">Click
                Here</a></v-typography>
          </v-col>
          <v-col cols="12">
            <v-btn class="mt-10" color="primary" @click="sendInvite()" :disabled="!selectedTest || processing"
              variant="flat">Send Invitation</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { getUserDetails } from "../../server/api";
import config from "../../config"
let serverurl = config.serverurl;

// serverurl="http://localhost:8083"
export default {
  name: "InviteCandidate",
  components: {},
  data() {
    return {
      candidate: null,
      tests: null,
      selectedTest: null,
      processing: false
    }
  },
  methods: {
    getTokenDetails(token) {
      let tokenDetails = JSON.parse(atob(token.split('.')[1]));
      this.candidate = tokenDetails;
      // if (tokenDetails.opportunityid) {
      //   this.fetchOpportunityDetails(tokenDetails.opportunityid);
      // }
    },
    async fetchOpportunityDetails(opportunityid) {
      let response = await fetch(serverurl + "/lever-opportunities", {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      let opportunities = await response.json();
      let opportunity = opportunities.find(o => o.id == opportunityid);
      this.candidate.opportunityName = opportunity.name;
    },
    async fetchTests() {
      return fetch(serverurl + "/tests", {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((t) => {
          this.tests = t;
          return;
        });
    },
    async sendInvite() {
      this.processing = true;
      let response = await fetch(serverurl + "/sendInvitation", {
        credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          candidates: [{
            name: this.candidate.name,
            email: this.candidate.email,
            opportunityid: this.candidate.opportunityid,
          }],
          testid: this.selectedTest,
        }),
      });
      let data = await response.json();
      console.log(data);
      this.$router.push("/interviews/candidates");
    }
  },
  async mounted() {
    
    getUserDetails().then((res) => {
      if (!res.profile) {
        this.$router.push("/login");
        return;
      }
    });
    // access url parameter token
    let token = this.$route.params.token;
    // make API call to decode token
    this.getTokenDetails(token);
    await this.fetchTests();

  }
}
</script>
<style src="./InviteCandidate.scss" lang="scss"/>