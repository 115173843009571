<style scoped>

</style>
<template>
  <v-card
    relative
    style="border-radius: 5px; border: 1px solid #ececec; padding: 10px; border-left:2px solid green;"
    v-if="q"
  >
    <v-btn
      absolute
      right
      top
      style="
        top: -20px;
        right: -10px;
        background: transparent;
        box-shadow: none;
      "
      class="mx-2 display-1"
      fab
      transparent
      size="small"
      @click="onremove(index, type)"
    >
      <i
        class="fa-solid fa-circle-xmark"
        style="font-size: 2rem; color: #ff5c93"
      ></i>
    </v-btn>
    <p>{{ label || "Question" }} {{ index }}</p>
    <v-text-field
      placeholder="Please type in your question"
      :rules="clientRules"
      :maxlength="500"
      @input="updateValue()"
      v-model="q.text"
      variant="underlined"
    ></v-text-field>
    <v-radio-group
              v-model="q.rightanswer"
              row
              class="radio-group-full-width justify-space-between"
            >
  <div  v-for="(opt, i) in q.options" class="justify-space-between" v-bind:key="i" style="display:flex; width:40%; ">
    <v-radio
                color="green"
                :value="i"
              ></v-radio>
              <v-text-field
        :placeholder="'Option ' + (i+1)"
        @input="updateValue()"
        append-icon="mdi-close"
        @click:append="
          q.options.length > 2 ? q.options.splice(i, 1) : (showerror = true);
          updateValue();
        "
        :maxlength="50"
        v-model="opt.text"
        :rules="clientRules"
      ></v-text-field>
  </div>
  
    </v-radio-group>
    <v-btn
      v-if="q.options.length < 5"
      @click="
        q.options.push({ text: '' });
        updateValue();
      "
      >Add Option</v-btn
    >
    
      <span style="color: red" class="ml-4" v-if="!(q.rightanswer>=0)">Please choose correct answer</span>
    
    
    <v-snackbar v-model="showerror" timeout="2000">
      You cannot give less than 2 options
    </v-snackbar>
  </v-card>
</template>
<script>
export default {
  props: ["question", "index", "label", "type", "onremove","onupdate"],
  data() {
    return {
      clientRules: [(v) => !!v || "This field is required"],
      options: [{ text: "" }, { text: "" }],
      showerror: false,
      q: this.$props.question,
    };
  },
  watch: {
    question: function (newVal) {
      console.log("property came");
      console.log(newVal);
      this.q = newVal;
    },
  },
  methods: {
    updateValue() {
      this.onupdate(this.q);
      // this.$emit("input", e);
    },
  },
};
</script>
<style src="./QuestionMCQ.scss" lang="scss"/>