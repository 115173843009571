<template>
  <v-app>
    <Sidebar v-if="store.profile" :items="items"/>
    <v-main class="content">
      <div style="padding: 40px 15px 15px; flex: 1 1 auto;
    max-width: 100%;
    position: relative;">
      <router-view />
      </div>
      <Footer style="display: none" />
    </v-main>
    <!-- <Sidebar v-if="store.profile" />
    <v-main class="content">
      <router-view />
      <Footer style="display: none" />
    </v-main> -->
    <!-- <v-tour name="myTour" :steps="steps">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <div slot="actions">
            
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour> -->
  </v-app>
</template>

<script>
// import Header from "@/components/Header/Header";
import Sidebar from "@/components/Sidebar/Sidebar";
import Footer from "@/components/Footer/Footer";
// import * as Vue from "vue";
// import VueTour from "vue-tour";
// Vue.use(VueTour);
// // import "./Layout.scss";
// import "vue-tour/dist/vue-tour.css";
import { store } from "../../store/index";
import steps from "../../tour-steps";

console.log(store);
export default {
  name: "Layout",
  components: { Footer, Sidebar,  },
  data() {
    return {
      store,
      steps,
      items:[
         { title: "Dashboard", icon: "mdi-view-dashboard", link: "/interviews/dashboard" },
        { title: "Candidates", icon: "mdi-account-box", link: "/interviews/candidates" },
        { title: "Interviews", icon: "mdi-briefcase", link: "/interviews/interviews" }
      ],
      itemsmobile:[
         { title: "Dashboard", icon: "mdi-view-dashboard", link: "/interviews/dashboard" },
        { title: "Candidates", icon: "mdi-account-box", link: "/interviews/candidates" },
        { title: "Interviews", icon: "mdi-briefcase", link: "/interviews/interviews" }
      ]
    };
  },
  mounted: function () {
    // if(location.href.toLowerCase().indexOf("job")==-1)
    //   setTimeout(()=>this.$tours["myTour"].start(), 1000)
  },
};
</script>

<style src="./Interviews.scss" lang="scss" />