export default  [
    {
      target: "#create-job-dashboard",
      content: `Let's create a job!<br/><strong style="font-size: 18px;
display: block;
margin-top: 12px;
letter-spacing: 1px;">Click on Create Job</strong>`,
      params: {
        placement: "bottom",
        enableScrolling: false
      },
    },
//     {
//       target: ".v-list div:nth-of-type(3)",
//       content: `Let's create a job!<br/><strong style="font-size: 18px;
// display: block;
// margin-top: 12px;
// letter-spacing: 1px;">Click on jobs</strong>`,
//       params: {
//         placement: "right",
//       },
//     },
    // {
    //   target: "#create-job-page",
    //   content: `Click on Create Job!`,
    //   params: {
    //     placement: "left",
    //   },
    // },
    {
      target: "#step-1",
      content: `Fill up your job details and click on continue!`,
      params: {
        placement: "bottom",
      }
    },
    {
      target: "#step-2",
      content: `Add questions you would like to ask from candidate, If any! Then click on continue!`,
      params: {
        placement: "bottom",
      }
    },
    {
      target: "#step-3",
      content: `Choose your candidate selection criteria.<br/> Then click on continue!`,
      params: {
        placement: "bottom",
      }
    },
    {
      target: "#step-4",
      content: `Submit your job!`,
      params: {
        placement: "bottom",
      }
    },
    {
      target: "#step-4",
      content: `Add questions for the interview and submit your job!`,
      params: {
        placement: "bottom",
      }
    },
    ///Step 6 copy job link
    {
      target: "#copy-job-link",
      content: `<strong>Congratulations!!!</strong><br/> You have created your first job!<br/> Copy your link from here and start sharing with candidates!`,
      params: {
        placement: "right",
      }
    },
    ///Step 7 Create job on jobs page
    {
      target: "#create-job-jobs",
      content: `You have not created job yet! <br/>Click here to create your first job`,
      params: {
        placement: "bottom",
      }
    },
    {
      target: "#create-job-dashboard",
      content: `Choose job for which you would like to invite canddiates!`,
      params: {
        placement: "left",
      }
    },
    {
      target: "#create-job-dashboard",
      content: `Click on Invite Candidates!`,
      params: {
        placement: "left",
      }
    },
    {
      target: "#create-job-dashboard",
      content: `Choose job for which you would like to invite canddiates!`,
      params: {
        placement: "left",
      }
    },
    {
      target: "#create-job-dashboard",
      content: `Click on Create Job!`,
      params: {
        placement: "left",
      }
    }
  ]