<template>
    <div class="node" :class="{ selected: data.id == data.selectednodeid }">
        <v-row>
            <v-col cols="3">
                <div class="node-icon" :class="data.type.toLowerCase()">
                    <v-icon color="#fff" v-if="data.type == 'Video'" size="large">mdi-video-account</v-icon>
                    <v-icon color="#fff" v-if="data.type == 'Coding'" size="large">mdi-file-code</v-icon>
                    <v-icon color="#fff" v-if="data.type == 'In-Person'" size="large">mdi-account-switch</v-icon>
                </div>

            </v-col>
            <v-col cols="9" class="justify-start d-flex flex-column">
                <v-row no-gutters class="align-center">
                <strong>{{ data.type }} Interview</strong>  &nbsp;&nbsp;
                <span style="font-weight: bold; color:gray">({{ data.skill == "General" ? data.interviewquestions.length : ((data.type == "Coding" || data.type === "In-Person") ? 30 : 30) }}
                    Mins)</span>
                    </v-row>
                <v-btn @click.prevent="() => deleteRound(data.id)" icon variant="plain" top right
                    style="position: absolute;    right: 0px; top: -6px; color:red">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <div v-if="data.type == 'Video'">
                    Technology : {{ data.skill }} <br />
                    <div v-if="data.skill == 'General'"> Total Questions: {{ data.interviewquestions.length }} </div><br />

                    <v-chip color="secondary" variant="elevated" class="weightage-chip">{{ data.skill == "General" ?
                        data.interviewquestions.reduce((sum, b) => {
                            return sum +
                                parseInt(b.weightage)
                        }, 0) : data.weightage }}</v-chip>

                </div>
                <div v-if="data.type == 'Coding'">
                    Technology : Coding
                    <br />
                    Level:{{ data.level }}, Total Questions: 2 <br />
                    <v-chip color="secondary" variant="elevated" class="weightage-chip"> {{
                        parseInt(data.weightage.question1) + parseInt(data.weightage.question2) }}</v-chip>

                </div>
                <div v-if="data.type == 'In-Person'">
                    Intruction:{{ data.instructions ? "Received" : "Not Provided" }} <br />
                    <v-chip color="secondary" variant="elevated" class="weightage-chip"> {{ parseInt(data.weightage)
                    }}</v-chip>

                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    components: {},
    props: ["data", "deleteRound", "id"],
    data() {
        return {
        }
    }
}
</script>
