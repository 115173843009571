
import candidateStatus from "../../master/candidateStatus.js";
import statusmapping from "../../master/statusmapping";
let status = Object.keys(candidateStatus);
status = status.map(s=>statusmapping[s]||s)
export default {
  template: `
      <div style="display: inline-block; width: 200px;padding:10px; max-height:100px;">
      <label style="font-weight:bold; margin-bottom:10px; display:block;">Select status</label>
      <ul style="list-style-type:none;padding-left:0px">
      <li v-for="s in status">
 <div style="display:flex; align-items:center; padding-bottom:4px;">
 <input
 style="margin-right:4px;" type="checkbox" v-model="selectedstatus" @change="updateFilter();"
 :value="s" >{{s}}</div></li>
  </ul>
  <button style="color:blue; margin:10px;" @click="selectedstatus=[];updateFilter();">Clear</button>
      </div>
    `,
  data: function () {
    return {
      status,
      selectedstatus: []
    };
  },
  methods: {
    updateFilter() {
      console.log("update filter")
      this.params.filterChangedCallback();
    },

    doesFilterPass(params) {
      console.log("is filter pass")
      console.log(this.selectedstatus);
      return params.data.status == this.selectedstatus;
    },

    isFilterActive() {
      console.log("is active")
      return this.selectedstatus.length>0;
    },

    // this example isn't using getModel() and setModel(),
    // so safe to just leave these empty. don't do this in your code!!!
    getModel() {
      console.log("Get Model")
      return this.selectedstatus.length>0 ?{filterType:"multiselect",type:"multiselect", filter:this.selectedstatus}:null;
    },

    setModel(model) {
      console.log("Set Model")
      console.log(model)
      this.selectedstatus = model?model:[];
    },
  },
  // render(h){
  //   return h(Vue.component('dynamic-render', {template:this.template}))
  // }
};

// <v-select
      //     v-model="selectedstatus"
      //     :items="status"
      //     :menu-props="{ maxHeight: '400' }"
      //     label="Select status"
      //     multiple
      //variant="underlined"
      //     @change="updateFilter()"
      //   ></v-select>