<template>
  <div>
    <v-row no-gutters class="d-flex justify-space-between mt-3 mb-3 no-gutters" style="background: #fff;
    padding: 4px 10px;align-items: center;">
      <h1 class="page-title">Create Interview</h1> 
      <v-spacer></v-spacer>
      <v-btn id="create-job-dashboard" v-bind="attrs" v-on="on" color="secondary" size="small"
        class="text-capitalize button-shadow mr-1" :disabled=" nodes.find(s=>!!!s.data.valid) || totalWeightage() !=100 || totalDuration()>90" @click="saveInterview()">Save Interview</v-btn>
    </v-row>
    <v-row class="create-interview">
      <v-col  class=""
        style="height: calc(100vh - 150px); background: #F4F4F4; border: 1px solid rgba(0,0,0,0.12);flex-grow: initial;
    flex-basis: 530px; position: relative;">
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn variant="outlined" v-bind="props" style="position:absolute;z-index:10;background-color: #fff;">
              Add Interview Round
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="addRound('Coding')" :disabled="nodes.find(n => n.data.type == 'Coding')">
              <v-list-item-title>Coding</v-list-item-title></v-list-item>
            <v-list-item @click="addRound('Video')"><v-list-item-title>Video</v-list-item-title></v-list-item>
            <v-list-item @click="addRound('In-Person')"
              :disabled="nodes.find(n => n.data.type == 'In-Person')"><v-list-item-title>In
                Person</v-list-item-title></v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <div
          style="border: 1px solid black; border-radius: 4px; padding: 10px; width:200px; position: absolute;right:10px; background: #fff; z-index:100;">
          Total Duration: {{ totalDuration() }} Mins<br />
          <span  style="color:#008036" :class="{'red': totalWeightage() != 100}"> Total Weightage: {{ totalWeightage() }}</span>
        </div>
        <VueFlow :nodes="nodes" :edges="edges" :node-types="nodeTypes" @node-click="selectNode"
          pan-on-scroll-mode="vertical" :pan-on-scroll="true" :pan-on-drag="false" :default-zoom="1" :max-zoom="1"
          :min-zoom="1" :draggable="false" :prevent-scrolling="false">
          <template #node-InterviewRound="{ data }">
            <interview-round :data="data" :delete-round="(id) => deleteRound(id)" />
          </template>
        </VueFlow>
      </v-col>
      <v-col grow v-if="selectedNode" class="pt-0"
        style="height: calc(100vh - 150px); background-color: #fff; overflow: auto;">
        <interview-start-form v-if="selectedNode.type == 'InterviewStart'" :id="selectedNode.id" :data="selectedNode.data"
          :on-save="(data) => onSave(selectedNode, data)"></interview-start-form>
        <hiring-form-config v-if="selectedNode.type == 'HiringForm'" :id="selectedNode.id" :data="selectedNode.data"
          :on-save="(data) => onSave(selectedNode, data)"></hiring-form-config>
        <in-person-form v-if="selectedNode.type == 'InterviewRound' && selectedNode.data.type == 'In-Person'"
          :id="selectedNode.id" :data="selectedNode.data"
          :on-save="(data) => onSave(selectedNode, data)"></in-person-form>
        <video-interview-form v-if="selectedNode.type == 'InterviewRound' && selectedNode.data.type == 'Video'"
          :id="selectedNode.id" :data="selectedNode.data"
          :on-save="(data) => onSave(selectedNode, data)"></video-interview-form>
        <coding-form v-if="selectedNode.type == 'InterviewRound' && selectedNode.data.type == 'Coding'"
          :id="selectedNode.id" :data="selectedNode.data" :on-save="(data) => onSave(selectedNode, data)"></coding-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { markRaw } from 'vue'
import { VueFlow } from '@vue-flow/core';
import config from "../../config"
let serverurl = config.serverurl;

import InterviewStart from './InterviewStart.vue';
import HiringForm from './HiringForm.vue';
import InterviewRound from './InterviewRound.vue';
import End from './End.vue';

import InPersonForm from './Forms/InPersonForm.vue';
import VideoInterviewForm from './Forms/VideoInterviewForm.vue';
import InterviewStartForm from './Forms/InterviewStartForm.vue';
import CodingForm from './Forms/CodingForm.vue';
import HiringFormConfig from './Forms/HiringFormConfig.vue';

const nodeTypes = {
  "InterviewStart": markRaw(InterviewStart),
  "HiringForm": markRaw(HiringForm),
  "InterviewRound": markRaw(InterviewRound),
  "End": markRaw(End)
};

export default {
  components: { VueFlow, InPersonForm, VideoInterviewForm, CodingForm, HiringFormConfig, InterviewStartForm, InterviewRound },
  data() {
    return {
      nodes: [
        {
          id: 'start',
          position: { x: 50, y: 100 },
          label: 'Interview Details',
          type: "InterviewStart",
          draggable: false,
          data: {
            title: "",
            email: "",
            id: 'start',
            valid: false
          }
        },
        {
          id: 'hiring',
          position: { x: 50, y: 300 },
          label: 'Hiring form',
          type: "HiringForm",
          draggable: false,
          data: {
            hiringquestions: [],
            enable: true,
            id: 'hiring',
            valid: true
          }
        },
        {
          id: '1',
          position: { x: 50, y: 500 },
          label: 'Interview Round 1',
          type: "InterviewRound",
          data: {
            id: '1',
            type: "Video",
            skill: "React",
            weightage: "30",
            interviewquestions: [],
            valid: true
          },
          draggable: false
        },
        {
          id: 'end',
          position: { x: 175, y: 700 },
          label: 'End',
          type: "End",
          draggable: false,

          data: { id: 'end', valid: true }
        }
      ],
      edges: [],
      nodeTypes,
      selectedNode: null
    }
  },
  methods: {
    totalDuration() {
      let duration = 0;
      this.nodes.filter(s => s.type == 'InterviewRound').map(s => {
        if (s.data.type == "Video" && s.data.skill != 'General')
          duration += 30;
        else if (s.data.type == "Video" && s.data.skill == 'General')
          duration += s.data.interviewquestions.length;
        else
          duration += 30;

      })
      return duration;
    },
    totalWeightage() {
      let weightage = 0;
      this.nodes.filter(s => s.type == 'InterviewRound').map(s => {
        if (s.data.type == "Video" && s.data.skill != 'General')
          weightage += parseInt(s.data.weightage);
        else if (s.data.type == "Video" && s.data.skill == 'General')
          weightage += s.data.interviewquestions.reduce((sum, b) => {
                            return sum +
                                parseInt(b.weightage)
                        }, 0);
        else if (s.data.type == "Coding")
          weightage += (parseInt(s.data.weightage.question1) + parseInt(s.data.weightage.question2));
        else if (s.data.type == "In-Person")
          weightage += parseInt(s.data.weightage);

      })
      return weightage;

    },
    addRound(type) {
      let lastnode = this.nodes[this.nodes.length - 2];
      let newid = (parseInt(lastnode.id) + 1).toString();
      if (lastnode.id == 'hiring')
        newid = '1'
      let data = null
      if (type == "Video") {
        data = {
          id: newid,
          type: "Video",
          skill: "Java",
          weightage: 30,
          interviewquestions: [],
          valid:true
        }
      }
      if (type == "Coding") {
        data = {
          id: newid,
          type: "Coding",
          level: "MEDIUM-MEDIUM",
          weightage: { question1: 30, question2: 30 },
          valid:true
        }
      }
      if (type == "In-Person") {
        data = {
          id: newid,
          type: "In-Person",
          instructions: "",
          weightage: 30,
          valid:false
        };
      }



      let newNode = {
        id: newid,
        position: { x: 50, y: lastnode.position.y + 200 },
        label: 'Interview Round ' + (lastnode.id + 1).toString(),
        type: "InterviewRound",
        data,
        draggable: false
      }
      this.nodes.pop();
      this.nodes.push(newNode)
      this.nodes.push({
        id: 'end',
        position: { x: 175, y: lastnode.position.y + 400 },
        label: 'End',
        type: "End",
        draggable: false,
        data: { id: 'end', valid:true }
      })
    },
    updateEdges() {
      let edges = [];
      this.nodes.map((el, i) => {
        if (i > 0) {
          edges.push({
            id: this.nodes[i - 1].id + "-" + this.nodes[i].id,
            source: this.nodes[i - 1].id,
            target: this.nodes[i].id,
            type: 'straight'
          })
        }

      })
      this.edges = edges
    },
    selectNode(event) {
      if (event.node.data.id == "end")
        return;
      this.selectedNode = event.node;
      this.nodes.map(n => n.data.selectednodeid = event.node.data.id)
    },
    onSave(selectedNode, data) {
      this.selectedNode.data = data
    },
    deleteRound(id) {
      let index = this.nodes.findIndex(s => s.id == id);
      this.nodes.splice(index, 1);
      this.nodes.map((s, i) => {
        if (i > 0) {
          this.nodes[i].position.y = this.nodes[i - 1].position.y + 200;
        }
      })
      this.updateEdges()
    },
    saveInterview() {
      
      let skills = [];
      let weightage = []
      let questions = { "GENERAL": [] };
      this.nodes.map(s => {
        if (s.type == 'InterviewRound') {
          if (s.data.type == "Video") {
            if (s.data.skill != 'General')
              weightage.push({ skill: s.data.skill.toUpperCase(), weightage: s.data.weightage });
            else {
              questions.GENERAL = s.data.interviewquestions;
              weightage.push({ skill: s.data.skill.toUpperCase(), weightage: s.data.interviewquestions.map(s => s.weightage) });
            }
            skills.push(s.data.skill)
          }
          else {
            skills.push(s.data.type.toUpperCase())
            weightage.push({ skills: s.data.type.toUpperCase(), weightage: s.data.weightage })
          }
        }
      });


      let hiringdata = this.nodes.find(n => n.id == 'hiring').data;
      let job = {
        title: this.nodes[0].data.title,
        email: this.nodes[0].data.email, //new
        duration: "",
        rskill: skills.map((s) =>
          s.trim().toUpperCase().split(" ").join("_")
        ),
        oskill: [],
        level: this.nodes.find(n => n.data.type == 'Coding')?.data.level,
        department: null,
        hiringquestions: hiringdata.enable ? hiringdata.hiringquestions.map((q, i) => {
          return {
            name: q.text,
            type: "text",
            required: true,
            mappedfield: "custom" + (i + 1),
          };
        }) : null,
        interviewquestions: skills.indexOf("General") > -1 ? questions : [],
        filtrations: null,
        headcount: null,
        weightage: weightage, //new,
        instructions: this.nodes.find(n => n.data.type == 'In-Person')?.data.instructions
      };
      console.log(job)
      fetch(serverurl + "/postjob", {
        credentials: "include",
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(job),
      })
        .then((res) => res.json())
        .then((res) => {
          this.$router.push("/interviews/interviews/" + res.testid);
        });
    }
  },
  mounted() {
    this.updateEdges()
    this.selectedNode = this.nodes[0];
    this.nodes.map(n => n.data.selectednodeid = this.nodes[0].id)
  }

}
</script>

<style src="./CreateInterview.scss" lang="scss"/>
