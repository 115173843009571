<template>
  <v-row justify="center">
    <v-dialog
    :value="show"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
      align="center"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Request is processed Successfully</v-toolbar-title>
        </v-toolbar>
        <v-row justify="center" align="center">
          <v-card width="774" class="mt-10" height="auto">
            <v-col
              class="d-flex flex-column justify-center align-center"
              style="background-color: #008036; height: 150px; color: #fff"
            >
              <v-icon size="50" color="#fff">mdi-check-circle</v-icon>
              <h4 class="mt-2 mb-0">SUCCESS</h4>
            </v-col>
            <v-col justify="center" align="center" class="mt-2">
              <div class="mt-4 mb-4" style="color: #008036">
                <b style="color: #008036">Awesome! &nbsp;&nbsp; {{count}} Elite {{count>1? "candidates are" : "candidate is"}} added to your Account.</b>
              </div>
              <div class="text-grey mb-4">
                You can now access their contact details and start talking to
                them
              </div>
              <v-divider></v-divider>
              <br />
              <div>
                These candidates are the top scorers out of hundreds of people
                interview. <br />They possess the potential of becoming your
                next employee.
              </div>
              <div>Don't wait further, And take their profile to next level</div>
            </v-col>
            <v-card-actions class="mt-10 mb-10">
    <v-row justify="center" align="center">
      <v-btn variant="outlined" color="primary" @click="redirect('/sourcing')" class="mr-10">Find more such elites</v-btn>
      <v-btn  color="primary" @click="redirect('/elites')" >Access these candidates</v-btn>
    </v-row>
  </v-card-actions>
          </v-card>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
    props:["show","count"],
  data() {
    return {
    };
  },
  methods:{
    redirect(path){
        this.$parent.$parent.$data.showsuccessdialog=false;
        this.$root.$emit("cart-change",{});
        this.$router.push(path).catch(()=>{});
    }
  }
};
</script>
<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}
</style>