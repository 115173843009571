<template>
  <v-container fluid>
    <div class="dashboard-page">
        <v-row
        no-gutters
        class="d-flex justify-space-between mt-3 pt-3 pb-3 mb-3"
        style="background: #fff; padding: 4px 10px; align-items: center"
      >
        <h1 class="page-title">Talent Search</h1>
        <v-spacer></v-spacer>
      </v-row>
      <v-row class="ma-0" style="background:#fff;">
        <v-col cols="2" >
          <span>FILTER BY</span>
          <div style="height:calc(100vh - 200px); overflow:auto;border-right: 1px solid #ececec;" class="pr-10">
            <p class="mt-6 mb-0">Skills</p>
                      <v-checkbox class="mt-1 mb-1" style="height:20px" v-model="filters.tech[tech]" @change="changeFilters()"  v-for="tech in technologies" v-bind:key="tech"
      :label="tech"
    ></v-checkbox>
    <p class="mt-6 mb-4">Experience (In yrs)</p>
    <div>
      <v-row>
      <v-col cols="6">
        <v-text-field
          v-model.number="filters.experience.min"
          type="number"
          label="Min"
          variant="underlined"
          @change="changeFilters()" 
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model.number="filters.experience.max"
          type="number"
          label="Max"
          variant="underlined"
          @change="changeFilters()" 
        ></v-text-field>
      </v-col>
    </v-row>
      
    </div>
    <p class="mt-6 mb-0">Work Model</p>
    <v-checkbox class="mt-1 mb-1" style="height:20px" v-model="filters.workModel['In Office']" @change="changeFilters()" 
      label="In Office"
    ></v-checkbox>
    <v-checkbox class="mt-1 mb-1" style="height:20px" v-model="filters.workModel['Hybrid']" @change="changeFilters()" 
      label="Hybrid"
    ></v-checkbox>
    <v-checkbox class="mt-1 mb-1" style="height:20px" v-model="filters.workModel['Remote']" @change="changeFilters()" 
      label="Remote"
    ></v-checkbox>
    <p class="mt-6 mb-4">Notice Period (In days)</p>
    <div>
      <v-row>
      <v-col cols="6">
        <v-text-field
          v-model.number="filters.notice_period.min"
          type="number"
          label="Min"
          variant="underlined"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model.number="filters.notice_period.max"
          type="number"
          label="Max"
          variant="underlined"
        ></v-text-field>
      </v-col>
    </v-row>
      
    </div>
    <p class="mt-6 mb-4">Current Salary (In lakhs)</p>
    <div>
      <v-row>
      <v-col cols="6">
        <v-text-field
          v-model.number="filters.current_ctc.min"
          type="number"
          label="Min"
          variant="underlined"
          @change="changeFilters()" 
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model.number="filters.current_ctc.max"
          type="number"
          label="Max"
          variant="underlined"
          @change="changeFilters()" 
        ></v-text-field>
      </v-col>
    </v-row>
      
    </div>
    <p class="mt-6 mb-4">Expected Salary (In lakhs)</p>
    <div>
      <v-row>
      <v-col cols="6">
        <v-text-field
          v-model.number="filters.expected_ctc.min"
          type="number"
          label="Min"
          variant="underlined"
          @change="changeFilters()" 
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model.number="filters.expected_ctc.max"
          type="number"
          label="Max"
          variant="underlined"
          @change="changeFilters()" 
        ></v-text-field>
      </v-col>
    </v-row>
      
    </div>
          </div>
        </v-col>
        <v-col cols="10">
          <h4>Talents ({{total}})</h4>
    <v-row  v-if="candidates.length==0 && !loadingTalents">
              <div class="ma-auto pl-10 pr-10 mt-10" style="font-size:16px; padding: 10px; border: 2px solid #008060; background: #fff;">
      <b>No matching candidates</b><br/> 
      <span style="font-size:14px; color:gray; display:block;" class="mt-2">
      Try changing filtration criteria </span>
      </div></v-row>
          <v-row no-gutters style="display: grid;
        grid-template-columns: repeat(auto-fill,minmax(320px, 1fr));max-height: calc(100vh - 200px);height: calc(100vh - 200px); overflow:auto;" id="infinite-list">
            <v-col v-for="c in candidates" v-bind:key="c.id" style="">
               <talent-card   class="mr-10" :candidate="c" :onAddCart="onAddCart" :onRemoveCart="onRemoveCart" ></talent-card>
            </v-col>
            <v-row class="justify-center">
            <v-progress-circular v-if="loadingTalents"
      :size="50"
      :width="3"
      color="primary"
      :rotate="360"
      :indeterminate="true"
    ></v-progress-circular>
          </v-row>
          </v-row>
          
          
          
          
        </v-col>
      </v-row>
    </div>
    
  </v-container>
</template>

<script>

// import config from "../../config";
// let serverurl = config.serverurl;
import { store } from "@/store/index";
import { sendLogs } from "@/server/api";
import { getReadyToHireCandiates, AddToCart,RemoveFromCart , getCartItems} from '@/server/sourcingapi.js';
import help from '../../helper.js';

import TalentCard from './TalentCard.vue';
export default {
  name: "TalentSearch",
  components: {
    // CandidateCard,
    TalentCard
  },
  data() {
    return {
      technologies:["REACT","ANGULAR","PYTHON","NODEJS","JAVA","CODING"],
      loadingTalents:false,
      total:0,
      end:0, 
      filters:{
        tech:{},
        experience:{min:0,max:99},
        current_ctc:{min:0,max:100},
        expected_ctc:{min:0,max:100},
        notice_period:{min:0,max:200},
        workModel:{"In Office":true,"Hybrid":true, "Remote":true}
      },
      conditions:[],
      candidates:[
      ]
    };
  },
  methods: {
    onAddCart(id){
      sendLogs({type:"ADD_TO_CART", log: id})
      let index=this.candidates.findIndex(s=>s.id==id);
      store.cartCandidates.push(this.candidates[index]);
      this.candidates[index].status="IN_CART";
      this.$set(this.candidates[index], 'status', "IN_CART");
      AddToCart(this.candidates[index].id);
    },
    onRemoveCart(id){
      sendLogs({type:"REMOVE_CART", log: id})
      let index_s=store.cartCandidates.findIndex(s=>s.id==id);
      store.cartCandidates.splice(index_s,1);

      let index=this.candidates.findIndex(s=>s.id==id);
      this.candidates[index].status="OUT_CART";
      this.$set(this.candidates[index], 'status', "OUT_CART");
      RemoveFromCart(this.candidates[index].id);
    },
    async loadCandidates(){
      
      // debugger // eslint-disable-line
      this.loadingTalents=true;
      let start = this.end||0;
      let end = start+20;
      sendLogs({type:"LOADING_CANDIATES", log: start })
      let result= await getReadyToHireCandiates({start,end}, this.conditions);
      result.candidates.map(c=>{
        help.formatCandidateSourcing(c);
        let inCart=store.cartCandidates.find(cc=>cc.id==c.id);
        if(inCart)
          c.status="IN_CART"
      })
      this.candidates=this.candidates.concat(result.candidates);
      this.end=this.end+20
      this.loadingTalents=false
      console.log("count:"+result.count)
      this.total = result.count;
    },
    getTechnologyCondition(){
      let selectedTech= Object.keys(this.filters.tech).filter(t=>this.filters.tech[t]);
      if(selectedTech.length==0)
      selectedTech= this.technologies;
      return `technology in ('${selectedTech.join("','").replace(/ /g,"_")}')`
    },
    getExperienceCondition(){
      return `c.experience >= ${this.filters.experience.min} and c.experience <= ${this.filters.experience.max} `
    },
    getNoticePeriodCondition(){
      return `c.notice_period >= ${this.filters.notice_period.min} and c.notice_period <= ${this.filters.notice_period.max} `
    },
    getWorkModelCondition(){
      let selectedModel= Object.keys(this.filters.workModel).filter(t=>this.filters.workModel[t]);
      if(selectedModel.length>0)
        return "("+selectedModel.map(m=> `c.workmodel Like '%${m}%'`).join(" OR ")+")"
      return ""
    },
    getCurrentSalaryCondition(){
      return `c.current_ctc >= ${this.filters.current_ctc.min * 100000} and c.current_ctc <= ${this.filters.current_ctc.max*100000} `
    },
    getExpectedSalaryCondition(){
      return `c.expected_ctc >= ${this.filters.expected_ctc.min*100000} and c.expected_ctc <= ${this.filters.expected_ctc.max*100000} `
    },
    changeFilters(){
      sendLogs({type:"SOURCING_FILTERS", log: JSON.stringify(this.filters) })
      this.candidates=[];
      this.end=0;
      console.log(this.filters)
      let conditions=[];
      conditions.push(this.getTechnologyCondition());
      conditions.push(this.getExperienceCondition());
      conditions.push(this.getWorkModelCondition());
      conditions.push(this.getNoticePeriodCondition());
      conditions.push(this.getCurrentSalaryCondition());
      conditions.push(this.getExpectedSalaryCondition());
      this.conditions=conditions;
      this.loadCandidates()
    }
  },
  async created(){
    let res= await getCartItems()
    res.cartItems.map((c) => help.formatCandidateSourcing(c));
    store.cartCandidates = res.cartItems; 
    await this.changeFilters();
  },
  mounted() {
    // Detect when scrolled to bottom.
    const listElm = document.querySelector('#infinite-list');
    listElm.addEventListener('scroll', () => {
      if(this.end<this.total && this.end>0 && !this.loadingTalents  ){
        if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          this.loadCandidates();
      }
      }
      
    });
    console.log("mounted")
    // this.$root.$on("cart-change",()=>{
    //   this.candidates=[];
    //   this.end=0; 
    //   this.loadCandidates();
    // });
    
  },
  beforeDestroy() {
    
  },
};
</script>

<style src="./TalentSearch.scss" lang="scss"/>
