<template>
    <div class="node"  :class="{invalid: !data.valid, selected: data.id==data.selectednodeid}">
        
        <v-row>
            <v-col cols="3">
                <div class="node-icon hiring">
                    <v-icon color="#fff" size="large">mdi-list-box</v-icon>
                </div>

            </v-col>
            <v-col cols="9" class="justify-start d-flex flex-column">
                <strong> Hiring form</strong> 
                <div v-if="data.enable">
                    All General details with {{ data.hiringquestions.length }} additional question
                </div>
                <div v-if="!data.enable">
                    Not enabled
                </div>
            </v-col>
        </v-row>


    </div>
</template>
<script>
export default {
    components: {},
    props: ["data"],
    data() {
        return {
        }
    }
}
</script>
