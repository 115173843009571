<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row
        no-gutters
        class="d-flex justify-space-between mt-3 mb-3"
        style="background: #fff; padding: 4px 10px; align-items: center"
      >
        <h1 class="page-title">Jobs > Create</h1>
      </v-row>
      <v-row class="no-gutters">
        <v-card width="100vw">
          <template>
            <v-stepper v-model="el">
              <v-stepper-header>
                <v-stepper-step :complete="el > 1" step="1">
                  Job details
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="el > 2" step="2">
                  Hiring Form Questions
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="el > 3" step="3">
                  Filtration Criteria
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step step="4"> Interview Questions </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1" class="pb-12">
                  <v-card class="mb-12" style="box-shadow: none !important">
                    <v-row class="mt-0 mb-0 ml-0 mr-0">
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="title"
                          label="Job Title"
                          placeholder="Job Title"
                          :rules="clientRules"
                          required
                          variant="underlined"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="department"
                          :items="items"
                          variant="underlined"
                          label="Department"
                          :rules="clientRules"
                          required
                          style="z-index: 1000000"
                          @change="
                            (s) => {
                              skills = allskills[s];
                              rskill = [];
                              oskill = [];
                              // loadNextStep();
                            }
                          "
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="headcount"
                          single-line
                          type="number"
                          placeholder="Head Counts (Enter number)"
                          :rules="clientRules"
                          min="1"
                          step="1"
                          variant="underlined"
                        />
                      </v-col>
                      <!-- <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="duration"
                          :items="durations"
                          label="Interview duration per skill"
                          :rules="clientRules"
                          required
                          variant="underlined"
                        ></v-select>
                      </v-col> -->
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-model="rskill"
                          :items="skills"
                          label="Must have skills (Max 3)"
                          multiple
                          chips
                          variant="underlined"
                          style="z-index: 1000000"
                          v-on:input="(e) => (e.length > 3 ? e.pop() : '')"
                          :rules="clientRules"
                          :disabled="skills.length == 0"
                          required
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              <v-avatar
                                class="accent white--text"
                                left
                                v-text="data.item.slice(0, 1).toUpperCase()"
                              ></v-avatar>
                              {{ data.item }}
                            </v-chip>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        v-if="rskill.indexOf('CODING') > -1"
                      >
                        <v-select
                          v-model="level"
                          :items="codinglevel"
                          label="Coding question level"
                          :rules="clientRules"
                          required
                          variant="underlined"
                          style="z-index: 1000000"
                        ></v-select>
                      </v-col>
                      <!-- <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-model="oskill"
                          :items="skills"
                          label="Good to have skills (optional, Max 2)"
                          multiple
                          optional
                          chips
                          variant="underlined"
                          :disabled="skills.length == 0"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              <v-avatar
                                class="accent white--text"
                                left
                                v-text="data.item.slice(0, 1).toUpperCase()"
                              ></v-avatar>
                              {{ data.item }}
                            </v-chip>
                          </template>
                        </v-select>
                      </v-col> -->
                    </v-row>
                    <v-row style="flex-direction: column">
                      <span class="ml-4" v-if="department == 'Technology'">
                        <u><b>Note:</b></u> Questions will be default loaded
                        from our library. If you want to ask custom questions,
                        Select 'Technology-General' as a skill.</span
                      >
                    </v-row>
                    <v-row style="flex-direction: column">
                      <span
                        style="color: red"
                        class="ml-4"
                        v-if="rskill.length + oskill.length > 3"
                        >You cannot choose more than 3 skills</span
                      >

                      <span
                        style="color: red"
                        class="ml-4"
                        v-if="
                          rskill.find((s) => oskill.indexOf(s) > -1) != null
                        "
                        >{{ rskill.find((s) => oskill.indexOf(s) > -1) }} cannot
                        be both required and optional skill</span
                      >
                    </v-row>
                  </v-card>

                  <v-btn
                    id="step-1"
                    color="primary"
                    :disabled="
                      duration.length == 0 ||
                      department.length == 0 ||
                      title.length == 0 ||
                      rskill.length == 0 ||
                      (rskill.indexOf('CODING') > -1 && !level) ||
                      headcount < 1 ||
                      rskill.length + oskill.length > 3 ||
                      rskill.find((s) => oskill.indexOf(s) > -1) != null
                    "
                    @click="
                      () => {
                        el = 2;
                        let interview = {};
                        rskill.map(
                          (s) => (interview[s] = interviewquestions[s] || [])
                        );
                        oskill.map(
                          (s) => (interview[s] = interviewquestions[s] || [])
                        );
                        interviewquestions = interview;
                      }
                    "
                  >
                    Continue
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="2" class="pb-12">
                  <v-card
                    class="mb-12 pt-4 pb-12"
                    style="box-shadow: none !important"
                  >
                    <h2>Hiring Form Questions</h2>
                    <p>
                      Below data will be collected from candidates before
                      interview is started.<br />
                      <span
                        >Name, Phone Number, Email, Experience, Qualification,
                        Notice period, Current CTC, Expected CTC,City</span
                      >
                      If you would like to collect any additional information,
                      please provide it here...
                    </p>
                    <div
                      class="mb-4"
                      v-for="(q, index) in hiringquestions"
                      v-bind:key="index"
                    >
                      <question
                        :index="index + 1"
                        v-model="q.text"
                        type="HIRING"
                        :onremove="removeQuestion"
                      ></question>
                    </div>
                    <v-btn
                      color="blue-grey"
                      class="ma-2 white--text"
                      @click="
                        hiringquestions.push({ text: '' });
                        // loadNextStep();
                      "
                      ><v-icon right dark> mdi-plus </v-icon>
                      Add Question
                    </v-btn>
                  </v-card>

                  <v-btn
                    color="primary"
                    @click="
                      el = 3;
                    "
                    :disabled="
                      hiringquestions.filter((s) => !s.text).length > 0
                    "
                    id="step-2"
                  >
                    Continue
                  </v-btn>

                  <v-btn text @click="el = 1"> Back </v-btn>
                </v-stepper-content>

                <v-stepper-content step="3" class="pb-12">
                  <v-card
                    class="mb-12 pt-4 pb-12"
                    style="box-shadow: none !important"
                  >
                    <h2>Filtartion Criteria</h2>
                    <p>Ideal candidate should have</p>

                    <v-row class="ma-0 ml-1">
                      <v-col cols="12" sm="4" md="2"> Choose Criteria </v-col>
                      <v-col cols="12" sm="4" md="2"> Expected match </v-col>
                    </v-row>
                    <div
                      class="mb-0"
                      v-for="(q, index) in filtrations"
                      v-bind:key="index"
                    >
                      <criteria
                        :index="index + 1"
                        :items="
                          filtrationList.filter(
                            (f) =>
                              !filtrations.find((i) => i.name == f.name) ||
                              f.name == q.name
                          )
                        "
                        type="FILTRATIONS"
                        :onremove="removeQuestion"
                        :onadd="
                          () => {
                            if (filtrations.length < filtrationList.length)
                              filtrations.push({});
                            // loadNextStep();
                          }
                        "
                        :setname="(name) => (q.name = name)"
                        :setvalue="(value) => (q.matchvalues = value)"
                        :filtration="q"
                      ></criteria>
                    </div>
                    <!-- <v-btn
                      color="blue-grey"
                      class="ma-2 white--text"
                      @click="filtrations.push({ text: '' })"
                      ><v-icon right dark> mdi-plus </v-icon>
                      Add Criteria
                    </v-btn> -->
                  </v-card>

                  <v-btn
                    color="primary"
                    id="step-3"
                    @click="
                      () => {
                        if (
                          filtrations.filter((s) => !s.name || !s.matchvalues)
                            .length > 0
                        )
                          return;
                        el = 4;
                      }
                    "
                    :disabled="
                      filtrations.filter((s) => !s.name || !s.matchvalues)
                        .length > 0
                    "
                  >
                    Continue
                  </v-btn>

                  <v-btn text @click="el = 1"> Back </v-btn>
                </v-stepper-content>
                <v-stepper-content step="4" class="pb-12">
                  <v-card
                    class="mb-12 pt-4 pb-12"
                    style="box-shadow: none !important"
                  >
                    <h2>Interview Questions</h2>
                    <p v-if="generalSkillsAdded().length > 0">
                      Please provide interview questions you would like to ask
                      candidate.
                    </p>
                    <p v-if="generalSkillsAdded().length == 0">
                      Interview questions will be default loaded from our
                      library.
                    </p>
                    <div
                      v-for="skill in generalSkillsAdded()"
                      v-bind:key="skill"
                    >
                      <p class="interview-question-skill">{{ skill }}</p>
                      <div
                        class="mb-4"
                        v-for="(q, index) in interviewquestions[skill]"
                        v-bind:key="index"
                      >
                        <question
                          v-if="skill.indexOf('-MCQ') == -1"
                          :index="index + 1"
                          v-model="q.text"
                          type="INTERVIEW"
                          :onremove="
                            (index, type) => removeQuestion(index, type, skill)
                          "
                        ></question>
                        <question-mcq
                          v-if="skill.indexOf('-MCQ') > -1"
                          :index="index + 1"
                          :question="q"
                          type="INTERVIEW"
                          :onremove="
                            (index, type) => removeQuestion(index, type, skill)
                          "
                          :onupdate="
                            (newval) => {
                              mcqupdate(newval, skill, index);
                            }
                          "
                        ></question-mcq>
                      </div>
                      <v-btn
                        color="blue-grey"
                        class="ma-2 white--text"
                        @click="
                          interviewquestions[skill].push({
                            text: '',
                            options: [{ text: '' }, { text: '' }],
                          });
                          // loadNextStep();
                        "
                        ><v-icon right dark> mdi-plus </v-icon>
                        Add Question
                      </v-btn>
                    </div>
                  </v-card>

                  <v-btn
                    color="primary"
                    @click="
                      saveJob();
                    "
                    :disabled="el == 4 && validateQuestions().length > 0"
                    id="step-4"
                  >
                    Submit
                  </v-btn>
                  <v-btn text @click="el = 3"> Back </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </template>
        </v-card>
      </v-row>
    </div>
  </v-container>
</template>


<script>
import config from "../../config";
let serverurl = config.serverurl;
import { store } from "@/store/index";

import Question from "./Question.vue";
import QuestionMcq from "./QuestionMCQ.vue";
import Criteria from "./Criteria.vue";
// import help from "../../helper.js";

export default {
  name: "AddJob",
  components: { Question, Criteria, QuestionMcq },
  data() {
    return {
      store,
      el: 1,
      level: null,
      items: [
        "Technology",
        "Sales & Marketing",
        "BPO & Customer Support",
        "HR",
        "Business",
      ],
      skills: [],
      codinglevel: [
        "EASY-EASY",
        "EASY-MEDIUM",
        "EASY-HARD",
        "MEDIUM-MEDIUM",
        "MEDIUM-HARD",
        "HARD-HARD",
      ],
      allskills: {
        "Sales & Marketing": [
          // "Sales",
          // "Marketing Intern",
          // "SEO Specialist",
          // "Social Media Marketing",
          // "Content Strategist",
          // "Copywriter",
          // "Digital Marketing",
          // "Marketing Analyst",
          // "Marketing Manager",
          "Marketing-General",
          "Sales-General",
          "Sales-MCQ",
        ],
        HR: [
          // "HR Manager",
          "HR-General",
          "HR-MCQ",
        ],
        Business: [
          // "Business Development Manager",
          "Business-General",
          "Business-MCQ",
        ],
        Technology: [
          "React",
          "Angular",
          "NodeJS",
          "Javascript",
          "Python",
          "Java",
          "SQL",
          "HTML",
          "CSS",
          "CODING",
          "Technology-MCQ",
          "Technology-General",
        ],
        "BPO & Customer Support": [
          // "BPO",
          // "Call Center",
          // "Help Desk",
          // "Office Clerk",
          // "Technical Support",
          "Support-General",
          "Support-MCQ",
        ],
      },
      filtrationList: [
        {
          name: "Experience (in years)",
          type: "number",
          min: 0,
          max: 30,
          required: true,
          mappedfield: "experience",
        },
        {
          name: "Notice period (in days)",
          type: "number",
          min: 0,
          max: 100,
          required: true,
          mappedfield: "notice_period",
        },
        {
          name: "Current Annual CTC",
          type: "number",
          min: 0,
          max: 100,
          required: true,
          mappedfield: "current_ctc",
        },
        {
          name: "Expected Annual CTC",
          type: "number",
          min: 0,
          max: 100,
          required: true,
          mappedfield: "expected_ctc",
        },
        {
          name: "Qualification",
          type: "dropdown",
          required: true,
          items: [
            "B.Tech",
            "B.Sc",
            "B.Com",
            "B.C.A",
            "M.C.A",
            "B.Ed",
            "M.Tech",
            "M.S",
          ],
          mappedfield: "qualification",
        },
      ],
      headcount: "",
      clientRules: [
        (v) => {
          console.log(v);
          return (!!v && v.length > 0) || "This field is required";
        },
      ],
      duration: "10 Minutes",
      title: "",
      rskill: [],
      oskill: [],
      department: "",
      durations: ["10 Minutes", "15 Minutes", "20 Minutes"],
      hiringquestions: [{ text: "" }],
      filtrations: [{}],
      interviewquestions: {},
    };
  },
  methods: {
    removeQuestion(index, type, skill) {
      switch (type) {
        case "HIRING":
          this.hiringquestions.splice(index - 1, 1);
          break;
        case "FILTRATIONS":
          {
            if (this.filtrations.length == 1) {
              alert("you need to provide at least one filtration criteria");
              return;
            }
            this.filtrations.splice(index - 1, 1);
          }
          break;
        case "INTERVIEW":
          this.interviewquestions[skill].splice(index - 1, 1);
          break;
      }
      // this.loadNextStep();
    },
    saveJob() {
      if (this.filtrations.filter((s) => !s.name || !s.matchvalues).length > 0)
        return;
      this.filtrations.map((s) => {
        let filter = this.filtrationList.find((f) => f.name == s.name);
        s.filter_name = s.name;
        s.mapped_column = filter.mappedfield;
        s.type = filter.type;
        if (s.type == "number")
          s.values = JSON.stringify([{ min: filter.min, max: filter.max }]);
        else s.values = filter.items.join("$");
      });
      let job = {
        title: this.title,
        duration: this.duration.replace(" Minutes", ""),
        rskill: this.rskill.map((s) =>
          s.trim().toUpperCase().split(" ").join("_")
        ),
        oskill: this.oskill.map((s) =>
          s.trim().toUpperCase().split(" ").join("_")
        ),
        level: this.level,
        department: this.department,
        hiringquestions: this.hiringquestions.map((q, i) => {
          return {
            name: q.text,
            type: "text",
            required: true,
            mappedfield: "custom" + (i + 1),
          };
        }),
        interviewquestions: this.interviewquestions,
        filtrations: this.filtrations,
        headcount: this.headcount,
      };
      fetch(serverurl + "/postjob", {
        credentials: "include",
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(job),
      })
        .then((res) => res.json())
        .then((res) => {
          this.$router.push("/interviews/jobs/" + res.testid);
        });
    },
    generalSkillsAdded() {
      if (this.department != "Technology")
        return this.rskill.concat(this.oskill);
      else
        return this.rskill
          .concat(this.oskill)
          .filter((s) => s.indexOf("General") > -1 || s.indexOf("MCQ") > -1);
    },
    validateQuestions() {
      let errors = [];
      this.generalSkillsAdded().map((s) => {
        if (this.interviewquestions[s].length == 0)
          errors.push("Please add questions for skill - " + s);
      });
      Object.keys(this.interviewquestions).map((s) => {
        if (s.indexOf("-MCQ") > -1) {
          let questions = this.interviewquestions[s];
          let invalidq = questions.filter(
            (q) =>
              !q.text || !(q.rightanswer >= 0) || q.options.find((o) => !o.text)
          );
          console.log(invalidq);
          if (invalidq.length > 0)
            errors.push(
              invalidq.length + " Invalid questions for skill - " + s
            );
        } else {
          let questions = this.interviewquestions[s];
          let invalidq = questions.filter((q) => !q.text);
          if (invalidq.length > 0)
            errors.push(
              invalidq.length + " Invalid questions for skill - " + s
            );
        }
      });
      return errors;
    },
    mcqupdate(newval, skill, index) {
      console.log(newval);
      this.interviewquestions[skill][index] = newval;
      console.log(this.interviewquestions[skill]);
      // this.loadNextStep();
    },
    // loadNextStep() {
    //   if(this.el==4 && this.generalSkillsAdded().length>0){
    //     help.nextStep(this, this.el+1);  
    //   }
    //   else
    //     help.nextStep(this, this.el);  
    // },
  },
  watch:{
    el:function(){
        // this.loadNextStep();
    }
  },
  mounted() {
    // help.nextStep(this, this.el,1000);
  },
  beforeDestroy() {
    console.log("unmoubted")
    // help.stopTour(this);
  },
};
</script>

<style src="./AddJob.scss" lang="scss"/>
