<template>

  <v-container fluid>
    <div class="dashboard-page">
      <v-row
        no-gutters
        class="d-flex justify-space-between mt-3 mb-3"
        style="
          background: #fff;
          padding: 4px 10px;
          align-items: center;
        "
      >
        <h1 class="page-title">Credits</h1>
        <v-spacer></v-spacer>
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="secondary"
          variant="flat"
          class="text-capitalize button-shadow mr-1"
          >Buy Credits</v-btn
        >
      </v-row>
      <v-row class="no-gutters">
        <v-card width="100vw">
          <v-data-table
            :headers="headers"
            :items="credits"
            :items-per-page="10"
            class="elevation-1"
          >
          </v-data-table>
        </v-card>
      </v-row>
    </div>
  </v-container>

</template>

<script>
import  config from "../../config" 
let serverurl = config.serverurl;
import { store } from "@/store/index";
import { VDataTable } from 'vuetify/labs/VDataTable'
export default {
  name: "Credits",
  components: {
    VDataTable
  },
  data() {
    return {
      store,
      credits: [],
      headers: [
        { title: "Name", key: "creditName" },
        { title: "Type", key: "displayType" },
        { title: "Total Credits", key: "totalCredits" },
        { title: "Credit balance", key: "availableCredits" },
        { title: "Current Status", key: "status" },
        { title: "Expiry Date", key: "expirationTimeLocal" },
        { title: "Payment Status", key: "isPaid" },
        
      ],
    };
  },
  methods: {
    getCredits() {
      fetch(serverurl + "/credits", { credentials: "include" })
        .then((res) => res.json())
        .then((res) => {
          this.credits = res.map((credit) => {
            credit.status = credit.isExpired? "Expired": "Active";
            credit.isPaid = credit.isPaid? "Paid": ((credit.creditName.toUpperCase().indexOf("FREE")>-1)? "FREE": "DUE");
            credit.displayType= credit.type=="F2F"? "Face to Face Interviews": "Automated Interviews";
            credit.expirationTimeLocal= credit.expirationDate? new Date(credit.expirationDate).toDateString(): "N/A";
            return credit;
          });
        });
    },
  },

  mounted() {
    this.getCredits();
  },
};
</script>

<style src="./Credits.scss" lang="scss"/>
