export default  {
  "Pending Slot Invitation":-2,
  "Slot Mail Sent":-1,
  "Pending Invitation": 1,
  "Invitation Sent": 2,
  "No Show":10,
  "Waiting for evaluation": 3,
  "BM Under Verification": 12,
  "BM Rejected": 11,
  "BM Verified":13,
  "Ready to Hire": 3,
  "Approved": 4,
  "Rejected": 5,
  "On hold": 6,
  "Moved to next Round": 7, 
  "Recruited": 8,
  "Moved to training": 9
};