<template>
  <v-container fluid v-if="selectedcandidate">
    <v-row no-gutters class="d-flex justify-space-between mt-3 mb-3 align-center"
      style="background: #fff; padding: 4px 10px; height: 45px">
      <h1 class="page-title" style="font-size: 24px">
        Applicant Interview -
        <span class="header-tech-list">
          {{ selectedcandidate.profile.map((p) => p.technology).join(", ") }}
        </span>
      </h1>
      <div v-if="store &&
        store.profile &&
        !statuschange &&
        !encrypted &&
        selectedcandidate.profile[0].status
        " style="float: right; display: flex; position: absolute; right:50px;">
        <div style="padding-left: 2px; padding: 1px; margin-right: 10px">
          <span style="
              font-weight: normal;
              padding: 4px 10px;
              background-color: #ff5c93;
              color: #fff;
              font-size: 16px;
            ">{{ selectedcandidate.profile[0].status }}</span>
        </div>
        <button class="sec" style="font-size: 16px" @click="onstatuschange()">
          ( Change Status )
        </button>
      </div>
      <div v-if="encrypted" style="float: right; display: flex">
        <v-btn color="error" v-if="!incart && !disableBtns" @click="AddToCart()">Add to Cart</v-btn>
        <v-btn v-if="incart && !disableBtns" @click="RemoveFromCart()">Remove from Cart</v-btn>
      </div>
      <div v-if="statuschange" style="position: absolute; right:50px;float: right; display: flex; font-size: 16px; gap: 13px">
        <v-select style="width: 200px" v-model="status" :items="availablestatus" variant="underlined" color="primary" class="pt-0"></v-select>
        <button class="sec" @click="saveStatus()">save</button>
        <button class="sec" @click="statuschange = false">cancel</button>
      </div>
    </v-row>
    <div v-if="selectedcandidate && selectedcandidate.profile" class="candidate-page">
      <div style="display: flex; position: relative" class="applicant-container">
        <div v-bind:class="{ encrypted: encrypted }" style="width: 100%" class="left-panel">
          <div class="candidate-info">
            <div>
              <img :src="selectedcandidate.profile_url || selectedcandidate.image"
                style="width: auto; height: 235px; min-height: 235px" />
                <!-- <img src="../../../images/demo-girl.png"
                style="width: auto; height: 235px; min-height: 235px" /> -->
            </div>
            <div style="padding-left: 15px" class="info-container">
              <strong class="text-capitalize" style="color: #ff5c93; font-size: 20px">
                <!-- {{"Grace Lee" }} -->
                 {{selectedcandidate.profile[0].firstname }}
                 {{ selectedcandidate.profile[0].lastname }}
                <!-- {{ encrypted ? "" : "-" }} -->
                <v-menu bottom right v-if="!encrypted && selectedcandidate.profile[0].profileAvailable">
                  <template v-slot:activator="{ props }">
                    <v-btn light icon v-bind="props" variant="plain">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title style="cursor: pointer">
                        <a target="_blank" :href="serverurl + '/resume?id=' + selectedcandidate.id
                          ">Resume</a>
                      </v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item
                      ><v-list-item-title
                        style="cursor: pointer"
                        @click="getReport()"
                        ><a>Download Report</a></v-list-item-title
                      >
                    </v-list-item> -->
                  </v-list>
                </v-menu> </strong><br />
              <span style="font-size: 12px; color: gray">Interview was given on
                {{
                  new Date(
                    selectedcandidate.profile[0].attempt_time
                  ).toDateString()
                }}</span>
              <br />
              <div v-if="showsection == 'OVERVIEW' && selectedcandidate.profile[0].profileAvailable">
                <span class="applicant-info"><i class="fa-solid fa-user-tie"></i>{{
                  selectedcandidate.profile[0].experience == "0"
                  ? "Fresher"
                  : 
                  (3||selectedcandidate.profile[0].experience)
                   + " yrs"
                }}</span>
                <span class="applicant-info"><i class="fa-solid fa-location-dot"></i>
                  {{ selectedcandidate.profile[0].city }} ,
                  {{ selectedcandidate.profile[0].country }}</span>
                <span class="applicant-info">
                  <i class="fa-solid fa-graduation-cap"></i>{{ selectedcandidate.profile[0].qualification }} ,
                  {{ selectedcandidate.profile[0].stream }}</span>
                <span style="margin-left: 30px">CGPA - {{ selectedcandidate.profile[0].score }}</span><br />
                <span style="margin-left: 30px">
                  {{ selectedcandidate.profile[0].college || 'Stanford University, California' }}</span>
                <span class="applicant-info"><i class="fa-solid fa-hourglass"></i> Notice Period -
                  {{
                    selectedcandidate.profile[0].notice_period == "0"
                    ? "Immediate Joiner"
                    : selectedcandidate.profile[0].notice_period
                  }}</span>
                <span class="skill-tile" v-for="skill in selectedcandidate.technicalSkills" v-bind:key="skill">{{ skill
                }}</span>
              </div>
              <div v-if="showsection == 'CONTACT' || !selectedcandidate.profile[0].profileAvailable"
                class="d-flex flex-column" style="font-size:18px">
                <span class="mt-2 mb-2"><i class="fa-solid fa-envelope"></i> &nbsp;
                  <a target="_blank" @click="sendLogs('CD_CONTACT_EMAIL')"
                    :href="'mailto:' + selectedcandidate.profile[0].email">
                    <!-- grace.lee@example.com -->
                    {{ selectedcandidate.profile[0].email
                    }}
                    </a></span>
                <span style="align-items:center" class="d-flex mt-2 mb-2"> <svg style="zoom:0.6"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"
                    width="48px" height="48px">
                    <path style="fill:#FFFFFF;"
                      d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z" />
                    <g>
                      <path style="fill:#FFFFFF;"
                        d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z" />
                      <path style="fill:#CFD8DC;"
                        d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z" />
                    </g>
                    <path style="fill:#40C351;"
                      d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z" />
                    <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;"
                      d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z" />
                  </svg>&nbsp;
                  <a class="ml-2" @click="sendLogs('CD_CONTACT_WHATSAPP')" target="_blank"
                    :href="'https://api.whatsapp.com/send?phone=' + selectedcandidate.profile[0].whatsapp + '&text=Hello%20' + selectedcandidate.profile[0].firstname + '%0D%0AI%20came%20across%20your%20profile%20on%20HulkHire'">
                    {{
                      selectedcandidate.profile[0].whatsapp }}
                      <!-- +1 9999999999999 -->
                      </a></span>
                <span class="mt-2 mb-2" @click="sendLogs('CD_CONTACT_PHONE')"><i class="fa-solid fa-phone"></i>&nbsp;
                  {{ selectedcandidate.profile[0].phonenumber || selectedcandidate.profile[0].whatsapp }}
                  <!-- +1 888888888888 -->
                </span>
              </div>

            </div>
            <div class="contact-part" style="margin-right:-10px; margin-top:-10px"
              v-if="selectedcandidate.profile[0].profileAvailable">
              <v-btn :color="showsection == 'OVERVIEW' ? 'primary' : 'gray'" style="box-shadow:none" size="large"
                class="rounded-0 mb-1" @click="sendLogs('OVERVIEW'); showsection = 'OVERVIEW'">Overview</v-btn>
              <v-btn :color="showsection == 'CONTACT' ? 'primary' : 'gray'" style="box-shadow:none" size="large"
                class="rounded-0 mb-1" @click="sendLogs('CONTACT'); showsection = 'CONTACT'">Contact Details</v-btn>
              <!-- <v-btn :color="showsection!='RESUME'?'primary':'white'"  style="box-shadow:none" size="large" class="rounded-0 mb-1" @click="sendLogs('RESUME');showsection='RESUME'">RESUME</v-btn>
              <v-btn :color="showsection!='INVITE'?'primary':'white'"  style="box-shadow:none" size="large" class="rounded-0 mb-1" @click="sendLogs('INVITE');showsection='INVITE'">Interview Invite</v-btn>
              <v-btn :color="showsection!='SHORTLIST'?'primary':'white'"  style="box-shadow:none" size="large" class="rounded-0 mb-1" @click="sendLogs('SHORTLIST');showsection='SHORTLIST'">Shortlist</v-btn> -->

            </div>
          </div>

          <div style="background-color: #fff; margin-top: 15px">
            <div style="border-bottom: 1px solid black; display: flex">
              <button class="tab-btn sec" style="max-width: 150px; border-right: 1px solid gray" :class="[
                {
                  active: round.technology == 'profile',
                },
              ]" @click="
  round = {};
round.technology = 'profile';
" v-if="selectedcandidate.profile[0].profileAvailable">
                <i class="fa-solid fa-brands fa-user mr-0"></i>
                <br />Profile
              </button>
              <button class="tab-btn sec" style="max-width: 150px; border-right: 1px solid gray" :class="[
                {
                  active: round.technology == 'comments',
                },
              ]" @click="
  round = {};
round.technology = 'comments';
">
                <i class="fa-solid fa-brands fa-comments mr-0"></i>
                <br />Notes
              </button>
              <button class="tab-btn sec" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " :class="[
                  {
                    active: round.technology == pf.technology,
                  },
                ]" @click="
  round = pf;
round.technology == 'CODING' ? loadCoding() : null;
" v-for="pf in selectedcandidate.profile" v-bind:key="pf.technology">
                <div>
                  <i class="fa-brands" :class="[
                    {
                      'fa-java': pf.technology == 'JAVA',
                      'fa-react': pf.technology == 'REACT',
                      'fa-angular': pf.technology == 'ANGULAR',
                      'fa-node-js': pf.technology == 'NODEJS',
                      'fa-html5': pf.technology == 'HTML',
                      'fa-css3-alt': pf.technology == 'CSS',
                      'fa-python': pf.technology == 'PYTHON',
                      'fa-database': pf.technology == 'SQL',
                      'fa-solid fa-code': pf.technology == 'CODING',
                      'fa-solid fa-handshake': pf.technology == 'IN-PERSON',
                    },
                  ]"></i>
                  <br />
                  <div>{{ pf.technology }}</div>
                </div>
                <!-- <div>
                  <v-btn
                    if="pf.result != null"
                    size="small"
                    fab
                    style="font-size: 18px"
                    class="white--text ml-2"
                    v-bind:color="pf.result == 1 ? 'teal' : 'error'"
                  >
                    {{ pf.overallscore }}
                  </v-btn>
                </div> -->
              </button>
            </div>

            <div style="background-color: #fff; padding-bottom: 30px">
              <div v-if="round.technology == 'profile' && selectedcandidate.profile[0].profileAvailable">
                <br />
                <br />
                <h4 class="subheading" style="margin-left: 10px; margin-top: 13px">
                  Education and Work Experience:
                </h4>
                <br />
                <div class="test-details" style="display: flex">
                  <div style="width: 400px">
                    <strong>Qualification : </strong>{{ selectedcandidate.profile[0].qualification }} <br />
                    <strong>Score : </strong>{{ selectedcandidate.profile[0].score }}<br />
                    <strong>Experience: </strong>{{ selectedcandidate.profile[0].experience + " Years"
                    }}<br />
                  </div>
                </div>

                <h4 class="subheading" style="margin-left: 10px; margin-top: 13px">
                  Recruitment Details:
                </h4>
                <br />
                <div class="test-details" style="display: flex">
                  <div style="width: 400px">
                    <strong>Notice period (in days): </strong>{{ selectedcandidate.profile[0].notice_period }} <br />
                    <strong>Current Annual CTC: </strong>{{ selectedcandidate.profile[0].current_ctc }}<br />
                    <strong>Expected Annual CTC: </strong>{{ selectedcandidate.profile[0].expected_ctc }}<br />
                  </div>
                </div>

                <h4 class="subheading" style="margin-left: 10px; margin-top: 13px">
                  Job Preferences:
                </h4>
                <br />
                <div class="test-details" style="display: flex">
                  <div style="width: 400px">
                    <strong>Work Model: </strong>{{ selectedcandidate.profile[0].workmodel.replace(/,/g, ", ") }} <br />
                    <strong>Preferred Location: </strong>{{
                      selectedcandidate.profile[0].location_preferences.split(",").map(s => s.split("-")[0]).join(",")}}<br />
                  </div>
                </div>

                <h4 class="subheading" style="margin-left: 10px; margin-top: 13px">
                  Hiring Questions:
                </h4>
                <br />
                <div class="ml-3">
                  <div class="mb-2" v-for="q in selectedcandidate.profile[0].hiringquestions" v-bind:key="q.mappedfield">
                    <strong>{{ q.name }} </strong><br />{{
                      selectedcandidate.profile[0][q.mappedfield]
                    }}
                    <br />
                  </div>
                  <p v-if="selectedcandidate.profile[0].hiringquestions.length == 0
                    ">
                    Not available
                  </p>
                </div>
              </div>
              <div v-if="round.technology == 'comments'">

                <div v-if="!encrypted" style="
                    width: 100%;
                  " id="feedback-panel">
                  <div style="
                      margin-left: 10px;
                      background-color: #fff;
                      padding: 10px;
                      height: auto;
                      
                    ">

                    <div>
                      <label><b>Add Notes:</b></label>
                      <v-textarea rows="4" variant="outlined" v-model="newfeedback['comments']">
                      </v-textarea>
                    </div>
                    <button style="
                        margin-top: 5px;
                        background: #439671;
                        color: #fff;
                        padding: 5px 10px;
                        border-radius: 5px;
                      " @click="addFeedback()">
                      Submit
                    </button>
                  </div>
                  <!-- <i
            class="fa-solid fa-chevron-up fbk-btn"
            @click="setScroll('TOP')"
            v-if="
              selectedcandidate.feedbacks &&
              selectedcandidate.feedbacks.length > 4
            "
          ></i> -->
                  <div style="
                      height: calc(100vh - 320px);
                      overflow: auto;
                      scroll-behavior: smooth;
                    " id="feedback" v-if="selectedcandidate.feedbacks">
                    <div v-for="fb in selectedcandidate.feedbacks" v-bind:key="fb.id" style="
                        margin-left: 10px;
                        margin-top: 10px;
                        background-color: #fff;
                        padding: 10px;
                        overflow: auto;
                      ">
                      <i class="fa-solid fa-hand-point-right"></i><b style="text-transform: capitalize">{{
                        store && store.profile ? fb.username : "Anonymous"
                      }}:</b><br />
                      {{ fb.comments }}

                    </div>
                  </div>
                  <!-- <i
            class="fa-solid fa-chevron-down fbk-btn"
            @click="setScroll('BOTTOM')"
            v-if="
              selectedcandidate.feedbacks &&
              selectedcandidate.feedbacks.length > 4
            "
          ></i> -->
                </div>
              </div>
              <div v-show="!['CODING', 'profile', 'comments', 'IN-PERSON'].includes(round.technology)
                ">
                <h4 class="subheading" style="margin-left: 10px; margin-top: 13px">
                  Highlights:
                </h4>
                <!-- <div v-if="round.video_analysis">
                  Frames-{{JSON.parse(round.video_analysis).total_frames}}<br/>
                  Total Looking-{{JSON.parse(round.video_analysis).looking_count}}<br/>
                  Total Reading-{{JSON.parse(round.video_analysis).reading_count}}<br/>
                  Looking% -{{JSON.parse(round.video_analysis).looking_percentage}}<br/>
                  Reading% -{{JSON.parse(round.video_analysis).reading_percentage}}
                </div> -->
                <br />
                <div class="test-details" style="display: flex">
                  <!-- <strong>Time : </strong>{{new Date(round.attempt_time).toLocaleString()}}<br /> -->

                  <div style="width: 400px">
                    <strong>Total Questions : </strong>{{ round.total_questions_count }} <br />
                    <strong>Attempted Questions : </strong>{{ round.questions_attempted_count }}<br />
                    <strong>Skipped Questions: </strong>{{ round.skipped_questions_count }}<br />
                  </div>
                  <div>
                    <strong>Tab switch : </strong>{{ round.tab_switched }}<br />
                    <strong>Full screen switch : </strong>{{ round.full_screen_switched }}<br />
                  </div>
                </div>
                <div style="display: flex; padding: 10px; flex-direction: column" v-if="round.technology != 'CODING' &&
                  round.technology != 'profile' &&
                  round.technology != 'comments' &&
                  round.technology != 'IN-PERSON' &&
                  round.technology.indexOf('-MCQ') == -1
                  ">
                  <h4 class="subheading">Interview Video:</h4>
                  <br />

                  <video autoplay controls controlsList="nodownload" style="width: 60%; margin: auto; margin-bottom: 20px"
                    type="video/webm" :src="'https://drive.google.com/uc?export=download&confirm=t&id=' +
                      (round.processed
                        ? round.processed_url
                        : round.video_url.split('/')[
                        round.video_url.split('/').length - 1
                        ])
                      ">
                  </video>
                  <!-- <video autoplay loop controls controlsList="nodownload" style="width: 60%; margin: auto; margin-bottom: 20px"
                    type="video/webm" src="../../images/demo-user-1.mp4">
                  </video> -->


                  <h4 class="subheading">Interview Questions:</h4>
                  <p style="
                      font-size: 12px;
                      margin-bottom: 3px;
                      margin-top: -8px;
                    ">
                    Note: Please click on any question to directly jump to that
                    answer
                  </p>
                  <div style="
                      border: 1px solid gray;
                      padding: 10px;
                      width: calc(100%);
                    " v-if="round.technology != 'CODING'">
                    Questions
                    <div style="
                        height: 200px;
                        overflow: auto;
                        padding: 10px;
                        padding: 4px;
                      ">
                      <div v-for="subtitle in round.subtitles" v-bind:key="subtitle.id" @click="setCurrenttime(subtitle)"
                        class="subtitle" :class="[
                          {
                            attempted: subtitle.status == 'ATTEMPTED',
                            skipped: subtitle.status == 'SKIPPED',
                          },
                        ]">
                        {{ subtitle.id }}. &nbsp;{{ subtitle.subtitle }}

                        <v-btn v-if="subtitle.answer" size="x-small" class="white--text"
                          v-bind:color="subtitle.marks >= 6 ? 'teal' : 'error'" depressed rounded>
                          {{ subtitle.marks || "0" }}
                        </v-btn>
                        &nbsp;<span v-if="subtitle.status == 'SKIPPED'">
                          [Skipped]</span>
                        <v-tooltip max-width="600" location="top" v-if="subtitle.answer">
                          <template v-slot:activator="{ props }">
                            <v-btn style="float: right" v-bind="props" variant="text">
                              solution - ({{ subtitle.duration }})S
                            </v-btn>
                          </template>
                          <span>{{ subtitle.answer }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ml-2 mr-2" v-if="round.technology.indexOf('-MCQ') > -1">
                  <h4 class="subheading">Interview Questions:</h4>
                  <v-expansion-panels accordion>
                    <v-expansion-panel v-for="(question, i) in interviewquestions[
                      round.technology
                    ]" v-bind:key="question.id">
                      <v-expansion-panel-header class="pa-0 pl-5">
                        <strong> {{ i + 1 + ". " + question.question }}</strong>
                        <div v-if="JSON.parse(round.mcq_answers)[
                          'CUSTOM' + question.id
                          ] &&
                          JSON.parse(round.mcq_answers)[
                            'CUSTOM' + question.id
                          ].score
                          " style="flex-grow: 1; margin-right: 10px;">
                          <i class="fa fa-check" style="color: green; float: right; font-size: 20px"
                            aria-hidden="true"></i>
                        </div>
                        <div style="flex-grow: 1; margin-right: 10px;" v-if="!JSON.parse(round.mcq_answers)[
                          'CUSTOM' + question.id
                          ] ||
                          !JSON.parse(round.mcq_answers)[
                            'CUSTOM' + question.id
                          ].score
                          ">
                          <i class="fa fa-exclamation-triangle" style="color: red; float: right; font-size: 20px"
                            aria-hidden="true"></i>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content style="overflow: auto">
                        <p v-for="(opt, i) in JSON.parse(question.options)
                          .options" v-bind:key="opt" class="option" :class="{
    'right-answer':
      JSON.parse(question.options).answer.indexOf(i) >
      -1,
    'candidate-answer': JSON.parse(round.mcq_answers)[
      'CUSTOM' + question.id
    ]
      ? JSON.parse(round.mcq_answers)[
        'CUSTOM' + question.id
      ].a == i
      : false,
  }">
                          <span>{{ String.fromCharCode(65 + i) + " " }}</span>{{ opt }}
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
              <div v-if="round.technology == 'CODING'" style="position: relative">
                <div style="position: absolute; width: 30px">
                  <button style="
                      display: block;
                      width: 30px;
                      height: 30px;
                      color: #fff;
                      background-color: #439671;
                      border: 1px solid #ececec;
                    " class="question-btn" :class="[{ active: index == selectedIndex }]"
                    v-for="(i, index) in round.questions_attempted" @click="SelectCodingQuestion(index)"
                    v-bind:key="index">
                    Q{{ index + 1 }}.
                  </button>
                </div>

                <div style="
                    display: flex;
                    flex-direction: column;
                    padding-top: 6px;
                  " v-if="coding_questions.length > 0 && selectedIndex >= 0">
                  <div width="700px" style="
                      margin-right: 20px;
                      margin-left: 60px;
                      height: 400px;
                      overflow: auto;
                    " v-if="coding_questions[selectedIndex]">
                    <strong>Coding Question {{ selectedIndex + 1 }}</strong><br />
                    <div v-html="coding_questions[selectedIndex].question"></div>
                  </div>
                  <div v-if="coding_questions[selectedIndex] && coding_score" style="display: flex; flex-direction: row">
                    <!-- {{  coding_questions[selectedIndex].solution[
                          coding_questions[selectedIndex].lasttech
                        ] }} -->
                    <!-- <Editor :tech="coding_questions[selectedIndex].lasttech" :code="coding_questions[selectedIndex].solution[coding_questions[selectedIndex].lasttech]"></Editor> -->
                    <Editor v-if="selectedIndex == 0" :tech="coding_questions[selectedIndex].lasttech" :code="coding_questions[selectedIndex].solution[
                      coding_questions[selectedIndex].lasttech
                      ]
                      "></Editor>
                    <Editor v-if="selectedIndex == 1" :tech="coding_questions[selectedIndex].lasttech" :code="coding_questions[selectedIndex].solution[
                      coding_questions[selectedIndex].lasttech
                      ]
                      "></Editor>
                    <div class="testcases" style="width: 50%">
                      <v-expansion-panels accordion>
                        <v-expansion-panel v-for="(tc, i) in coding_score[selectedIndex].results" :key="i">
                          <v-expansion-panel-title>

                            <template v-slot:default="{}">
                              Testcase {{ i + 1 }} -
                              <div v-if="tc.result == 'PASS'" style="flex-grow: 1; margin-right: 10px;">
                                <i class="fa fa-check" style="
                                  color: green;
                                  float: right;
                                  font-size: 20px;
                                " aria-hidden="true"></i>
                              </div>
                              <div v-if="tc.result == 'FAIL'" style="flex-grow: 1; margin-right: 10px;">
                                <i class="fa fa-exclamation-triangle" style="
                                  color: red;
                                  float: right;
                                  font-size: 20px;
                                " aria-hidden="true"></i>
                              </div>
                            </template>
                          </v-expansion-panel-title>
                          <v-expansion-panel-text style="overflow: auto">
                            <b> Input -</b> <br />
                            {{
                              coding_questions[selectedIndex].params
                                .map(
                                  (p, l) =>
                                    p.name +
                                    "=" +
                                    JSON.stringify(JSON.parse(tc.input)[l])
                                )
                                .join(",")
                            }}
                            <br />
                            <b>Actual Output -</b><br />
                            {{ tc.output }}<br />
                            <b>Expected Output -</b><br />
                            {{ tc.expectedoutput }}
                          </v-expansion-panel-text>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="round.technology == 'IN-PERSON'" style="position: relative">
                <v-row class="mt-4 no-gutters">
                  <v-col class="pl-8">
                    <div class="inperson-observation">
                      <strong>Observations</strong>
                      <p style="white-space: pre;">{{ inperson.observations }}</p>
                    </div>

                    <div  class="inperson-observation">
                      <strong>Project</strong>
                      <p style="white-space: pre;">{{ inperson.projects }}</p>
                    </div>

                    <div  class="inperson-observation">
                      <strong>Communication / Self Starter</strong>
                      <p style="white-space: pre;">{{ inperson.communication }}</p>
                    </div>

                    <div  class="inperson-observation">
                      <strong>Excitement to Join</strong>
                      <p style="white-space: pre;">{{ inperson.excitementToJoin }}</p>
                    </div>

                    <div  class="inperson-observation">
                      <strong>Attitude and Commitment</strong>
                      <p style="white-space: pre;">{{ inperson.attitude }}</p>
                    </div>





                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import Editor from "./Editor.vue";
import { store } from "../../store";
import { sendLogs } from "@/server/api";
import candidateStatus from "../../master/candidateStatus.js";
import { AddToCart, RemoveFromCart } from "../../server/sourcingapi";
import config from "../../config";
import helper from "../../helper";
import statusmapping from "../../master/statusmapping";
let serverurl = config.serverurl;
export default {
  name: "CandidateDetails",
  components: {
    Editor,
  },
  data() {
    return {
      user: null,
      candidateAccess: null,
      selectedcandidate: null,
      tests: [],
      round: null,
      video_url: null,
      editor: null,
      coding_questions: [],
      selectedIndex: null,
      selectedtech: null,
      newfeedback: {},
      videoloaded: false,
      videoData: null,
      availablestatus: [],
      status: "",
      statuschange: false,
      store,
      serverurl,
      coding_score: [],
      interviewquestions: {},
      encrypted: true,
      incart: false,
      disableBtns: false,
      showsection: 'OVERVIEW',
      inperson: {}
    };
  },
  methods: {
    sendLogs(type) {
      sendLogs({ type, log: this.selectedcandidate.id });
    },
    triggerMounted() {
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    async AddToCart() {
      sendLogs({ type: "ADD_TO_CART_CD", log: this.selectedcandidate.id })
      this.disableBtns = true;
      this.incart = true;
      await AddToCart(this.selectedcandidate.id);
      store.cartCandidates.push(this.selectedcandidate);
      this.$root.$emit("added-to-cart");

      setTimeout(() => {
        this.disableBtns = false;
      }, 1000);
    },
    async RemoveFromCart() {
      sendLogs({ type: "REMOVE_FROM_CART_CD", log: this.selectedcandidate.id })
      this.disableBtns = true;
      this.incart = false;
      await RemoveFromCart(this.selectedcandidate.id);

      let index_s = store.cartCandidates.findIndex(
        (s) => s.id == this.selectedcandidate.id
      );
      store.cartCandidates.splice(index_s, 1);
      setTimeout(() => {
        this.disableBtns = false;
      }, 1000);
    },
    saveStatus() {
      if (this.status) {
        let finalstatus = this.status.split("/")[0].trim();
        sendLogs({ type: "CHANGE_STATUS_CD", log: finalstatus + "-" + this.selectedcandidate.id })
        fetch(serverurl + "/changeCandidateStatus", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: this.selectedcandidate.id,
            status: finalstatus,
            reason:
              this.status.split(" / ").length == 2
                ? this.status.split("/")[1].trim()
                : null,
            testid: this.selectedcandidate.profile[0].testid,
          }),
        })
          .then((res) => res.text())
          .then((res) => {
            if (res == "SUCCESS") {
              this.selectedcandidate.profile[0].status = this.status;
              this.statuschange = false;
            } else {
              alert("Status update failed");
            }
          });
      }
    },
    onstatuschange() {
      this.statuschange = true;
      console.log("profile:");
      console.log(store);
      if (store.profile.role.toUpperCase() == "MASTER") {
        let statuses = Object.keys(candidateStatus).filter(
          (s) => candidateStatus[s] > 2
        );
        this.availablestatus = statuses.concat([
          "BM Rejected / Cheating",
          "BM Rejected / incomplete",
          "BM Rejected / Poor communication",
          "BM Rejected / irrelevant content",
        ]);
      } else if (store.profile.role.toUpperCase() == "BM_REVIEWER") {
        this.availablestatus = [
          "BM Rejected / Cheating",
          "BM Rejected / incomplete",
          "BM Rejected / Poor communication",
          "BM Rejected / irrelevant content",
          "BM Verified",
          "BM Under Verification",
        ];
      } else
        this.availablestatus = Object.keys(candidateStatus).filter(
          (s) => candidateStatus[s] > 3 && candidateStatus[s] < 10
        );
    },
    addFeedback() {
      if (!this.newfeedback.comments || this.newfeedback.comments.length < 10) {
        alert("Please share feedback in few lines");
        sendLogs({ type: "FEEDBACK_FAILED", log: this.selectedcandidate.id + "-" + this.newfeedback.comments })
        return;
      }
      sendLogs({ type: "FEEDBACK_ADDED", log: this.selectedcandidate.id })
      fetch(serverurl + "/postResult", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          profile: store.profile
            ? store.profile.profile
            : store.candidateAccess
              ? store.candidateAccess.org
              : null,
          username: store.profile
            ? store.profile.username
            : store.candidateAccess
              ? store.candidateAccess.org
              : null,
          source: store.profile
            ? "CLIENT"
            : store.candidateAccess
              ? store.candidateAccess.source
              : null,
          ...this.newfeedback,
          id: this.selectedcandidate.profile[0].id,
        }),
      })
        .then((res) => res.text())
        .then((res) => {
          if (res == "Success") {
            this.selectedcandidate.feedbacks = [
              {
                username: store.profile ? store.profile.username : "Anonymous",
                comments: this.newfeedback.comments,
              },
            ].concat(this.selectedcandidate.feedbacks);
            this.newfeedback = {};
          }
        });
    },

    SelectCodingQuestion(i) {
      sendLogs({ type: "REVIEW_CODING", log: this.selectedcandidate.id + "-" + i })
      let selectedquestion = this.coding_questions[i];
      this.selectedtech = selectedquestion.lasttech;
      this.selectedIndex = i;
    },

    setScroll(direction) {
      // let fbBox = document.getElementById("feedback");
      direction == "TOP"
        ? (document.getElementById("feedback").scrollTop -= 100)
        : (document.getElementById("feedback").scrollTop += 100);
    },
    getCandidateDetails(id) {
      let url = null;
      let org = null;
      if (store.candidateAccess) org = store.candidateAccess.org;
      console.log(store.profile);
      url =
        serverurl +
        "/getCandidateDetails?id=" +
        id +
        "&org=" +
        (store.profile ? store.profile.profile : org);
      fetch(url, { credentials: "include" })
        .then((res) => res.json())
        .then((res) => {
          if (res[0].email == "xxxxx@xxxxx.com") this.encrypted = true;
          else this.encrypted = false;
          this.dashboard = false;
          this.selectedcandidate = {
            id,
          };
          this.selectedcandidate.feedbacks = res[0].feedbacks.reverse();
          this.selectedcandidate.interviewanswers = res[0].evaluation_result;
          if (!res[0].hiringquestions) res[0].hiringquestions = [];
          res[0].interview_questions.map((q) => {
            this.interviewquestions[q.skill] ||
              (this.interviewquestions[q.skill] = []);
            this.interviewquestions[q.skill].push(q);
          });
          this.selectedcandidate.profile = res;
          let status = this.selectedcandidate.profile[0].status;
          if (!this.selectedcandidate.profile[0].whatsapp)
            this.selectedcandidate.profile[0].whatsapp = this.selectedcandidate.profile[0].phonenumber
          this.selectedcandidate.profile[0].status =
            statusmapping[status] || status;
          this.selectedcandidate.technicalSkills = res[0].technicalskill
            ? res[0].technicalskill.split(",").slice(0, 5)
            : null;
          this.selectedcandidate.profile_url = res[0].profile_url;
          if (res.find((z) => z.technology != "CODING")) {
            let img = res.find((z) => z.technology != "CODING").processed_url;
            if(img)
            this.selectedcandidate.image =
              "https://drive.google.com/thumbnail?id=" + img;
          }

          this.round = this.selectedcandidate.profile[0];
          if (this.round.technology == "CODING") {
            this.loadCoding();
          }
          let inpersonround= this.selectedcandidate.profile.find(s=>s.technology=="IN-PERSON");
          if(inpersonround)
            this.inperson= JSON.parse(inpersonround.coding_score)
          // else {
          fetch(serverurl + "/getAccessToken")
            .then((res) => res.text())
            .then((token) => {
              res.map((s, i) => {
                if (s.technology != "CODING") {
                  this.fetchFile(
                    s.subtitlefileid,
                    token,
                    this.selectedcandidate.profile[i]
                  );
                }
              });
            });
          // }
        });

      

    },
    getReport() {
      sendLogs({ type: "GET_REPORT", log: this.selectedcandidate.id })
      let cd = this.selectedcandidate.profile[0];
      let fb = cd.feedbacks.find(
        (f) =>
          f.username.toUpperCase() == "HULKHIRE" ||
          f.username.indexOf("bm-reviewer") > -1
      );
      if (!fb) {
        alert("Candidate scores are not yet available");
        return;
      }
      let techs = this.selectedcandidate.profile.map((s) => s.technology);
      let scores = {};
      let total = 0;
      techs.map((t) => {
        scores[t] = fb[t.toLowerCase()];
        total += fb[t.toLowerCase()];
      });
      if (Object.keys(scores).length == 0) {
        alert("Candidate scores are not yet available");
        return;
      }
      let technical_avg = (total / Object.keys(scores).length).toFixed(2);
      let candidate = {
        firstname: cd.firstname,
        lastname: cd.lastname,
        testname: cd.testname,
        interviewtime: new Date(cd.interviewtime),
        email: cd.email,
        phonenumber: cd.phonenumber,
        comments: fb.comments,
        communication: fb.communication,
        scores,
        technical_avg,
      };
      helper.generateReport(candidate);
    },
    loadCoding() {
      sendLogs({ type: "CODING_SELECTED", log: this.selectedcandidate.id })
      if (!Array.isArray(this.round.questions_attempted))
        this.round.questions_attempted =
          this.round.questions_attempted.split(",");
      this.coding_questions = JSON.parse(
        this.round.coding_solutions.replace(/\\%/gm, "%")
      );
      this.coding_score = JSON.parse(
        this.round.coding_score.replace(/\\%/gm, "%")
      );
      this.SelectCodingQuestion(0);
    },
    fetchFile(id, token, profile) {
      const url =
        "https://www.googleapis.com/drive/v3/files/" + id + "?alt=media";
      if (self.fetch) {
        var setHeaders = new Headers();
        setHeaders.append("Authorization", "Bearer " + token);
        setHeaders.append("Content-Type", "text/plain");

        var setOptions = {
          method: "GET",
          headers: setHeaders,
        };
        return fetch(url, setOptions)
          .then((response) => {
            if (response.ok) {
              var reader = response.body.getReader();
              var decoder = new TextDecoder();
              reader.read().then((result) => {
                var data = {};
                data = decoder.decode(result.value, { stream: !result.done });
                let answers = [];
                let subtitlesection = data.split("\r\n\r\n").filter((s) => s);
                if (this.selectedcandidate.profile[0].evaluation_result) {
                  answers =
                    this.selectedcandidate.profile[0].evaluation_result.filter(
                      (t) =>
                        t.technology == profile.technology &&
                        t.candidateid == profile.candidate_id
                    );
                }

                profile.subtitles = subtitlesection.map((s, i) => {
                  let data = s.split("\r\n");
                  return {
                    id: data[0],
                    time: data[1],
                    subtitle: data[2],
                    answer: answers.find((s) => s.sequenceid == i + 1)?.answer,
                    marks: answers.find((s) => s.sequenceid == i + 1)?.marks,
                    duration: answers.find((s) => s.sequenceid == i + 1)
                      ?.duration,
                    status:
                      profile.skipped_questions_list.findIndex(
                        (z) => z.question == data[2]
                      ) > -1
                        ? "SKIPPED"
                        : "ATTEMPTED",
                  };
                });
                if (this.round.technology == profile.technology) {
                  this.round = JSON.parse(JSON.stringify(profile));
                }
                console.log(this.round);
                return data;
              });
            } else {
              console.log("Response wast not ok");
            }
          })
          .catch((error) => {
            console.log("There is an error " + error.message);
          });
      }
    },
    async loadMetadata() {
      let vid = document.querySelector("video");
      console.log(new Date());
      if (vid && vid.duration == Infinity) {
        vid.currentTime = 1200;
        // while (vid.duration === Infinity) {
        //   await new Promise((r) => setTimeout(r, 1000));
        //   vid.currentTime = 10000000 * Math.random();
        // }
        console.log(vid.duration);
        this.videoloaded = true;
        // vid.ontimeupdate =  () => {
        //   console.log(new Date());
        //   this.videoloaded =true;
        //   // this.videoloaded =true;
        //   this.ontimeupdate = () => {
        //     console.log(new Date());
        //     // vid.subtitle(document.querySelector("track").getAttribute("src"));
        //     return;
        //   }
        //   vid.currentTime = 0;
        //   return;
        // }
      } else {
        this.videoloaded = true;
      }
    },
    setCurrenttime(subtitle) {
      let video = document.getElementsByTagName("video")[0];
      let time = subtitle.time.split(",")[0].split(":");
      console.log(subtitle);
      console.log(time[0] * 60 * 60 + time[1] * 60 + parseInt(time[2]));
      video.currentTime = (
        time[0] * 60 * 60 +
        time[1] * 60 +
        parseInt(time[2])
      ).toString();
    },
  },
  mounted() {
    if (!sessionStorage.getItem("loaded")) {
      sessionStorage.setItem("loaded", true);
      location.reload();
    }
    this.getCandidateDetails(this.$route.params.id);
    // this.$root.$on("cart-items-updated", () => {
    //   this.incart =
    //     store.cartCandidates.find((c) => c.id == this.$route.params.id) != null;
    // });
  },
};
</script>

<style src="./CandidateDetails.scss" lang="scss"/>


// <!-- <h3 style="text-align: center; text-decoration: underline">
//               Give Feedback Here
//             </h3> -->
//                     <!-- <div
//               class="rating-box"
//               v-for="pf in selectedcandidate.profile"
//               v-bind:key="pf.technology"
//             >
//               <strong>{{ pf.technology }} Score: </strong>
//               <v-rating
//                 half-increments
//                 hover
//                 color="yellow"
//                 length="5"
//                 size="30"
//                 value="3"
//                 v-model="newfeedback[pf.technology]"
//               ></v-rating>
//             </div>
//             <div class="rating-box">
//               <strong>Communication Score: </strong>
//               <v-rating
//                 half-increments
//                 hover
//                 color="yellow"
//                 length="5"
//                 size="30"
//                 value="3"
//                 v-model="newfeedback['communication']"
//               ></v-rating>
//             </div> -->

// <!-- <div
//                 v-for="pf in selectedcandidate.profile"
//                 v-bind:key="pf.technology"
//               >
//                 <strong>{{ pf.technology }} Score: </strong>
//                 {{ fb[pf.technology.toLowerCase()] }}
//               </div>
//               <div>
//                 <strong>Communication Score: </strong> {{ fb["communication"] }}
//               </div> -->
//                       <!-- <div><strong>Feedback: </strong> {{ fb.comments }}</div> -->