<template>
  <v-dialog class="pa-10" v-model="visible" max-width="600px" @keydown.esc="changeShow()" @click:outside="changeShow()">
    <v-card>

        <v-card-title>
          <v-btn
          icon="$close"
          density="comfortable"
          variant="plain"
          @click="changeShow()"
        ></v-btn>
            <!-- <v-btn  style="float:right; " icon @click="changeShow()" top right>
        <v-icon size="large">mdi-close</v-icon>
      </v-btn> -->
    </v-card-title>
      <v-card-text  class="pb-15 pt-10 pl-10 pr-10">
          <v-row class="pb-10">
              <h5 dark class="font-weight-black">{{title}}</h5>
              Copy this link and start sharing to all potential candidates in job posts, emails or LinkedIn.
              <br/>
              Candidates can give their interview using this link.
          </v-row>
          <v-row>
        <v-text-field
        
        class="rounded-0"
         
          
          :value="link"
          variant="outlined"
        ></v-text-field>
        <v-btn id="copy-job-link" variant="flat" dark size="x-large" class="rounded-0"  style="height:56px;background-color:#cfd5e2; color:black;" @click="copylink();snackbar=true;"> COPY </v-btn>
        <v-snackbar
      v-model="snackbar"
      timeout="2000"
    >
      Link Copied
    </v-snackbar>
      </v-row>
      </v-card-text>
      
    </v-card>
  </v-dialog>
</template>
<style>
.v-dialog  .v-card  .v-card-title{
    background-color: #fff !important;
}

.v-card-title{
    justify-content: end;
}
.v-field__outline__start,.v-field__outline__end{
  border-radius: 0px !important;
}

</style>
<script>
export default {
  props: ["show", "link", "changeShow","title"],
  data() {
    return {
      visible: this.show,
      snackbar: false,
    };
  },
  watch: {
    show: function (newVal) {
      console.log("Show:" + newVal);
      this.visible = newVal;
    },
    // visible: function(){
    //   this.changeShow();
    // }
  },
  methods: {
    copylink(){
      navigator.clipboard.writeText(this.link);
    }
  },
};
</script>