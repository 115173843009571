<template>
  <v-app>
    <Sidebar v-if="store.profile" :items="items"/>
    <v-main class="content">
      <div style="padding: 40px 15px 15px; flex: 1 1 auto;
    max-width: 100%;
    position: relative;">
      <router-view />
      </div>
      
      <Footer style="display: none" />
    </v-main>
    
  </v-app>
</template>

<script>
// import Header from "@/components/Header/Header";
import Sidebar from "@/components/Sidebar/Sidebar";
import Footer from "@/components/Footer/Footer";
// import * as Vue from "vue";

import { store } from "../../store/index";


console.log(store);
export default {
  name: "Layout",
  components: { Footer, Sidebar,  },
  data() {
    return {
      store,
      items:[
         { title: "Users", icon: "mdi-view-dashboard", link: "/admin/users" },
        { title: "Integrations", icon: "mdi-account-box", link: "/admin/integrations" },
        { title: "Credits", icon: "mdi-currency-usd", link: "/admin/credits" },
      ],
      itemsmobile:[
         { title: "Users", icon: "mdi-view-dashboard", link: "/admin/users" },
        { title: "Integrations", icon: "mdi-account-box", link: "/admin/integrations" },
        { title: "Credits", icon: "mdi-currency-usd", link: "/admin/credits" }
      ]
    };
  },
  mounted: function () {
    // if(location.href.toLowerCase().indexOf("job")==-1)
    //   setTimeout(()=>this.$tours["myTour"].start(), 1000)
  },
};
</script>

<style src="./Admins.scss" lang="scss" />