<template>
  <v-app>
    <v-container class="pt-10" fluid>
      <v-row cols="3">
        <v-img src="https://interview.hulkhire.com/logo/full-black-tp.png" alt="HulkHire" style=" height: 36px;" />
      </v-row>
      <v-card class="schedule-card pa-4 text-center" v-if="success" style="
          max-width: 900px;
          margin-top: 100px;
          margin-left: auto;
          margin-right: auto;
        ">
        <v-row>
          <v-col
              class="d-flex flex-column justify-center align-center"
              style="background-color: #008036; height: 150px; color: #fff"
            >
              <v-icon size="50" color="#fff">mdi-check-circle</v-icon>
              <h4 class="mt-2 mb-0">SUCCESS</h4>
            </v-col>
        </v-row>
        <v-card-title class="justify-center mt-8"> Authorization Successful</v-card-title>
        <div class="justify-center">You are now connected to Lever</div>
        
        <br />
        <v-btn color="primary" class="mt-4" @click="$router.push('/admin/integrations')">Go Back to Integrations</v-btn>
      </v-card>
      <v-card class="schedule-card pa-4 text-center" v-if="failure" style="
          max-width: 900px;
          margin-top: 100px;
          margin-left: auto;
          margin-right: auto;
        ">
        <v-row>
          <v-col
              class="d-flex flex-column justify-center align-center"
              style=" height: 150px; color:red"
            >
              <v-icon size="50" color="red">mdi-alert</v-icon>
              <h4 class="mt-2 mb-0">Authorization Failed</h4>
            </v-col>
        </v-row>
        <!-- <v-card-title class="justify-center"> Authorization Failed</v-card-title> -->
        <v-row class="no-gutters">
          <v-col>
            If you think, this is a mistake, reach out to us on <a href="mailto:care@hulkhire.com">care@hulkhire.com</a><br />
          </v-col>
        </v-row>
        
        <v-btn color="primary" class="mt-8" @click="$router.push('/admin/integrations')">Go Back to Integrations</v-btn>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import config from "../../config"
let serverurl = config.serverurl;

// serverurl="http://localhost:8083"
export default {
  name: "LeverIntegration",
  components: {},
  data() {
    return {
      success: false,
      failure: false
    }
  },
  methods: {
    connectToLever: async (code) => {
      let response = await fetch(serverurl + "/lever-connect", {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: code
        }),
      })
      let data = await response.text();
      await new Promise((resolve) => setTimeout(resolve, 2000));
      this.success = true;
      await new Promise((resolve) => setTimeout(resolve, 2000));
      this.$router.push("/admin/integrations");

      console.log(data);
    }
  },
  mounted() {
    // make api call to send url parameters to backend
    // access code url parameter

    let code = this.$route.query.code;
    if (code){
      this.connectToLever(code);
      this.success = true;
    }
    else
      this.failure = true;
      
  }
}
</script>
<style src="./LeverIntegration.scss" lang="scss"/>