import  config from "../config" 
let serverurl = config.serverurl;
import {store} from "../store/index";
let headers={
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};
export const getUserDetails = ()=>                                             {
    return fetch(serverurl + "/userdetails", { credentials: "include" }).then(res => res.json()).then(s => {
        let url = new URL(location.href);
        let profile = null;
        if (s.profile) {
          profile = { ...s, profile: s.profile, username: s.user };
        }
        // else {
        
        //   if (url.href.indexOf("candidatedetails") > -1)
        //     window.history.pushState(null, "Applicant", "/candidatedetails");
        // }
        if (url.href.indexOf("candidatedetails") > -1) {
          let id = url.searchParams.get("id");
          let name = url.searchParams.get("name");
          let source = url.searchParams.get("source");
          let org = url.searchParams.get("org");
          let candidateAccess = { id, name, source, org };
          if (org)
            sessionStorage.setItem("candidate", JSON.stringify(candidateAccess));
          else if (!id)
            candidateAccess = JSON.parse(sessionStorage.getItem("candidate"));
          return {
              page: 'candidate-details',
              profile,
              candidateAccess
          }    
        }
        else 
        if (profile) {
            return {
                page: 'dashboard',
                profile
            }   
        }
        else {
            return {
                page: 'login',
                profile
            }   
        }
      })

}

export const login = (data)=>{
    return fetch(serverurl + "/login", {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data   )
      }).then(s => s.json()).then(p => {
        if (p.length > 0) {
            return {
                profile:p[0]
            }
        }
        else {
          return {
              error: "Invalid credentials                           "
          }
        }
      })    
}

export const register = (data)=>{
  return fetch(serverurl + "/register", {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(s => s.json()).then(p => {
      let msg;
      switch(p.event){
        case "ACCOUNT_EXIST": 
          msg="An account already exist for this user.\nPlease login.";
          break;
        case "INVALID_EMAIL_ADDRESS": 
          msg="Please provide a valid business email";
          break;
        default:
          msg=p.event;
      }
      return msg;
    })    
}

export const verifyuser =(data)=>{
  return fetch(serverurl + "/verifyUser", {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(s => s.json()).then(p => {
    return p;
  })  
}

export const sendLogs = (data) => {
  if (!data.email) {
    if (store.profile && store.profile.email)
      data.email = store.profile.email;
    else
      data.email="ANONYMOUS"
  }
  if (!localStorage.getItem("tempid")) {
    let tempid = Date.now().toString(36) + Math.random().toString(36).substring(2);
    localStorage.setItem("tempid", tempid);
  }
  data.uniqueid= localStorage.getItem("tempid");

  return fetch(serverurl + "/recruiterlogs", {
    credentials: "include",
    method: 'POST',
    headers,
    body: JSON.stringify(data)
  });
}
// sendLogs