<template>
    <v-card class="mb-12 pl-4 pr-4 pt-10 pb-10" style="box-shadow: none !important;">
        <v-card-title class="pl-3">Coding Interview Details</v-card-title>
        <v-col cols="12">
            <label>Coding question level</label>
          <v-select v-model="details.level" :items="codinglevel"
            :rules="clientRules" required variant="outlined" style="z-index: 1000000;width: 250px;"></v-select>
            <br/>
            <label>Weightage Question 1 </label>
          <v-text-field v-model="details.weightage.question1" style="width: 100px;" min="5" max="100" single-line type="number"
            placeholder="Weightage for Question 1 " :rules="weightageRules" variant="outlined" />
            <br/>
            <label>Weightage Question 2 </label>
          <v-text-field v-model="details.weightage.question2" style="width: 100px;"  min="5" max="100" single-line type="number"
            placeholder="Weightage for Question 2 " :rules="weightageRules" variant="outlined" />
        </v-col>
    </v-card>

</template>
<script>
export default {
    components: {  },
    props:["data","onSave","id"],
    watch:{
        id:function(){
            this.details=this.$props.data
        },
        isFormValid(newVal) {
           this.details.valid=newVal
        }
    },
    computed: {
        isFormValid() {
            return this.weightageRules[0](this.details.weightage.question1) != "Invalid" && this.weightageRules[0](this.details.weightage.question2) != "Invalid"
        }
    },
    data() {
        return {
            details:this.data,
            clientRules: [
        (v) => {
          console.log(v);
          return (!!v && v.length > 0) || "This field is required";
        },
      ],weightageRules:[
      (v) => {
          return (v && v>=5 && v<=100 && /^[0-9]*$/.test(v))|| "Invalid"
        },
      ],
            codinglevel: [
        "EASY-EASY",
        "EASY-MEDIUM",
        "EASY-HARD",
        "MEDIUM-MEDIUM",
        "MEDIUM-HARD",
        "HARD-HARD",
      ]
        }
    }
}
</script>

