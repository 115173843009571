// import Vue from 'vue';
import * as VueRouter from 'vue-router';


import Layout from '@/components/Layout/Layout';
import Talents from '@/components/Talents/Talents';
import Interviews from '@/components/Interviews/Interviews';
import Admins from '@/components/Admins/Admins';

// Pages
import Dashboard from '@/pages/Dashboard/Dashboard';
import Candidate from '@/pages/Candidate/Candidate';
import CandidateDetails from '@/pages/CandidateDetails/CandidateDetails';
import Jobs from '@/pages/Jobs/Jobs';
import Elites from '@/pages/Elites/Elites';
import Users from '@/pages/Users/Users';
import Credits from '@/pages/Credits/Credits';

import Integrations from '@/pages/Integrations/Integrations';
import AddJob from '@/pages/AddJob/AddJob';
import CreateInterview from '@/pages/CreateInterview/CreateInterview';
import TalentSearch from '@/pages/TalentSearch/TalentSearch';
// import Typography from '@/pages/Typography/Typography'
// import Tables from '@/pages/Tables/Basic'
// import Notifications from '@/pages/Notifications/Notifications'
// import Icons from '@/pages/Icons/Icons'
// import Charts from '@/pages/Charts/Charts'
// import Maps from '@/pages/Maps/Google'
import Error from "@/pages/Error/Error";
import Login from "@/pages/Login/Login";
import LeverIntegration from "@/pages/LeverIntegration/LeverIntegration"
import SelfInvitation from "@/pages/SelfInvitation/SelfInvitation";
import Reschedule from "@/pages/Reschedule/Reschedule";
import InviteCandidate from "@/pages/InviteCandidate/InviteCandidate";
import DummyInterview from "@/pages/DummyInterview/DummyInterview";

// Vue.use(Router);

export default VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/dummyinterview/:candidateid',
      name: 'Interview Sandbox',
      component: DummyInterview
    },
    {
      path: '/signup',
      name: 'Sign up',
      component: Login
    },
    {
      path: '/integrate/lever',
      name: 'Lever and HulkHire',
      component: LeverIntegration
    },
    {
      path: '/interviews/invitecandidate/:token',
      name: 'Invite Candidate',
      component: InviteCandidate
    },
    {
      path: '/schedule/:profile/:testname/:testid/:linkid/:data?',
      name: 'Book your slot',
      component: SelfInvitation
    },
    {
      path: '/reschedule/:param1/:param2',
      name: 'Reschedule your interview',
      component: Reschedule
    },
    {
    path: '/',
    redirect: 'login',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: 'talents',
        name: 'Talent Search',
        component: Talents,
        children: [
          {
            path: 'sourcing',
            name: 'Applicant Details',
            component: TalentSearch,
          },
          {
            path: 'elites',
            name: 'Elites',
            component: Elites,
          },
          {
            path: 'candidatedetails/:id',
            name: 'Applicant Details',
            component: CandidateDetails,
          },
        ]
      },
      {
        path: 'interviews',
        name: 'Interviews Search',
        component: Interviews,
        children: [
          {
            path: 'dashboard',
            name: 'Dashboard',
            component: Dashboard,
          },
          {
            path: 'interviews/:sharejobid?',
            name: 'Interviews',
            component: Jobs,
          },
          {
            path: 'create',
            name: 'Create New Interview',
            component: CreateInterview,
          },
          {
            path: 'candidates',
            name: 'Candidate',
            component: Candidate,
          },
          {
            path: 'candidatedetails/:id',
            name: 'Applicant Details',
            component: CandidateDetails,
          },
      {
        path: 'job/add',
        name: 'Add new job',
        component: AddJob,
      }
        ]
      },
      {
        path: 'admin',
        name: 'Admin',
        component: Admins,
        children: [
          {
            path: '/admin/users',
            name: 'Users',
            component: Users,
          },
          {
            path: '/admin/integrations',
            name: 'Integrations',
            component: Integrations,
          },
          {
            path: '/admin/credits',
            name: 'Credits',
            component: Credits,
          },]
      }
    ],
  },
    {
      path: '/:catchAll(.*)',
      name: 'Error',
      component: Error
    }
  ]
});


// new Router({
//   mode: 'history',
//   routes: [
//     {
//       path: '/login',
//       name: 'Login',
//       component: Login
//     },
//     {
//       path: '/signup',
//       name: 'Sign up',
//       component: Login
//     },
//     {
//       path: '/integration/lever',
//       name: 'Lever and HulkHire',
//       component: LeverIntegration
//     },
//     {
//       path: '/schedule/:profile/:testname/:testid/:linkid/:data?',
//       name: 'Book your slot',
//       component: SelfInvitation
//     },
//     {
//       path: '/reschedule/:param1/:param2',
//       name: 'Reschedule your interview',
//       component: Reschedule
//     },
//     {
//     path: '/',
//     redirect: 'login',
//     name: 'Layout',
//     component: Layout,
//     children: [
//       {
//         path: 'talents',
//         name: 'Talent Search',
//         component: Talents,
//         children: [
//           {
//             path: 'sourcing',
//             name: 'Applicant Details',
//             component: TalentSearch,
//           },
//           {
//             path: 'elites',
//             name: 'Elites',
//             component: Elites,
//           },
//           {
//             path: 'candidatedetails/:id',
//             name: 'Applicant Details',
//             component: CandidateDetails,
//           },
//         ]
//       },
//       {
//         path: 'interviews',
//         name: 'Interviews Search',
//         component: Interviews,
//         children: [
//           {
//             path: 'dashboard',
//             name: 'Dashboard',
//             component: Dashboard,
//           },
//           {
//             path: 'interviews/:sharejobid?',
//             name: 'Interviews',
//             component: Jobs,
//           },
//           {
//             path: 'interviews/create',
//             name: 'Create New Interview',
//             component: CreateInterview,
//           },
//           {
//             path: 'candidates',
//             name: 'Candidate',
//             component: Candidate,
//           },
//           {
//             path: 'candidatedetails/:id',
//             name: 'Applicant Details',
//             component: CandidateDetails,
//           },
//       {
//         path: 'job/add',
//         name: 'Add new job',
//         component: AddJob,
//       }
//         ]
//       },
//       {
//         path: 'admin',
//         name: 'Admin',
//         component: Admins,
//         children: [
//           {
//             path: '/admin/users',
//             name: 'Users',
//             component: Users,
//           },
//           {
//             path: '/admin/integrations',
//             name: 'Integrations',
//             component: Integrations,
//           }]
//       }
//     ],
//   },
//     {
//       path: '*',
//       name: 'Error',
//       component: Error
//     }
//   ],

// });
