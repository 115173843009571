<template>
    <v-card class="mb-12 pl-4 pr-4 pt-10 pb-10" style="box-shadow: none !important;">
        <v-card-title>Video Interview (AI)</v-card-title>
        <!-- {{ id }} {{ data }} {{ details }} -->
        <v-col cols="12">
            <label>Choose Technology</label>
            <v-select v-model="details.skill" :items="Technology" variant="outlined"
                style="z-index: 1000000; width:200px" v-on:input="(e) => (e.length > 3 ? e.pop() : '')" :rules="clientRules"
                required >
            </v-select>
            <div v-if="details.skill != 'General'" style="font-size: 12px; color:gray; margin-top: -20px; margin-left: 5px;"> Note: Question will be loaded from HulkHire Library</div>
            <div v-if="details.skill == 'General'" style="font-size: 12px; color:gray; margin-top: -20px;margin-left: 5px;"> Note: Please share the questions you would like to ask candidate</div>
        </v-col>
        <v-col  v-if="details.skill != 'General'">
            <label>Weightage</label>
            <v-text-field style="width:100px;" :min="5" :max="100" :step="1" v-model="details.weightage" placeholder="weightage" type="number" :rules="weightageRules"
                required variant="outlined"></v-text-field>
        </v-col>
        <v-col v-if="details.skill == 'General'">
            <div class="mb-4" v-for="(q, index) in details.interviewquestions" v-bind:key="index">
                <!-- eslint-disable-next-line  -->
                <question :showeightage="true" :index="index + 1" v-model:weightage ="q.weightage" v-model="q.text" type="INTERVIEW" :onremove="(index, type) => removeQuestion(index, type, skill)
                    "></question>
                
                
            </div>
            <v-btn
                        color="blue-grey"
                        class="ma-2 white--text"
                        @click="
                          details.interviewquestions.push({
                            text: '',
                            weightage:5
                          });
                          // loadNextStep();
                        "
                        ><v-icon right dark> mdi-plus </v-icon>
                        Add Question
                      </v-btn>
        </v-col>
    </v-card>
</template>
<script>
import Question from "../Temp/Question.vue";
export default {
    components: { Question },
    props: ["data", "onSave","id"],
    data() {
        return {
            details: this.data,
            clientRules: [
        (v) => {
          return (!!v && v.length > 0) || "Required";
        },
      ],
      weightageRules:[
      (v) => {
          return (v && v>=5 && v<=100 && /^[0-9]*$/.test(v))|| "Invalid"
        },
      ],
            Technology: [
                "React",
                "Angular",
                "NodeJS",
                "Javascript",
                "Python",
                "Java",
                "SQL",
                "HTML",
                "CSS",
                "General"
            ],
        }
    },
    watch:{
        id:function(){
            this.details=this.$props.data
        },
        isFormValid(newVal) {
            this.details.valid=newVal
        }
    },

    computed: {
        isFormValid() {
            if(this.details.skill=='General')
                return this.details.interviewquestions.length>0 && this.details.interviewquestions.find(s=>!s.text || this.weightageRules[0](s.weightage)=="Invalid")==null
            else
                return this.weightageRules[0](this.details.weightage)!='Invalid'
        }
    },
    methods: {
        removeQuestion(index) {
            this.details.interviewquestions.splice(index - 1, 1);
        },
    },
    
}
</script>
