<template>
    <div class="node end">
        End
    </div>
</template>
<script>
export default {
    components: {  },
    data() {
        return {
        }
    }
}
</script>
