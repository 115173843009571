<template>
  <div v-if="params.data">
    <v-menu bottom left>
      <template v-slot:activator="{ props }"
        v-if="candidateStatus[params.data.status] != 3 && candidateStatus[params.data.status] != 12">
        <v-btn light icon v-bind="props" variant="plain">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-if="environment != 'PROD'">
          <v-list-item-title style="cursor: pointer">
            <a target="_blank" :href="'/dummyinterview/' +params.data.id">Mock Interview</a>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="candidateStatus[params.data.status] <= 2">
          <v-list-item-title style="cursor: pointer" @click="resendInvite()">Resend Invitation Email</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="candidateStatus[params.data.status] == 2 ||
          candidateStatus[params.data.status] == 10
          ">
          <v-list-item-title>
            <a target="_blank" :href="'https://jobs.hulkhire.com/reschedule/' +
              params.data.email +
              '/' +
              params.data.testid
              ">Reschedule Interview</a>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="(candidateStatus[this.params.data.status] > 3 &&
            candidateStatus[this.params.data.status] < 10) ||
          candidateStatus[this.params.data.status] == 11 ||
          candidateStatus[this.params.data.status] == 13
          ">
          <v-list-item-title style="cursor: pointer" @click="getDetails()"> Candidate details</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="((candidateStatus[this.params.data.status] > 3 &&
            candidateStatus[this.params.data.status] < 10) ||
            candidateStatus[this.params.data.status] == 11 ||
            candidateStatus[this.params.data.status] == 13) && this.params.data.hiringform
          ">
          <v-list-item-title style="cursor: pointer">
            <a target="_blank" :href="serverurl +
              '/resume?id=' +
              params.data.id
              ">Resume</a>
          </v-list-item-title>
        </v-list-item>
        <!-- <v-list-item v-if="(candidateStatus[params.data.status] >= 4 &&
            candidateStatus[params.data.status] <= 9) ||
          candidateStatus[params.data.status] == 13 ||
          (candidateStatus[params.data.status] == 12 && store.profile.role == 'master')
          ">
          <v-list-item-title style="cursor: pointer" @click="getReport()">Download Report</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-menu>
    <!-- <div v-if="check()">
    
    <button class="sec" style="text-decoration: underline" @click="click()">
      Details
    </button>
  </div> -->
    <v-snackbar v-model="snackbar" timeout="2000"> Invitation Sent </v-snackbar>
  </div>
</template>
<script>
import { store } from "../../store";
import candidateStatus from "../../master/candidateStatus.js";
import statusmapping from "../../master/statusmapping.js";
import config from "../../config";
import helper from "../../helper";

let cs = JSON.parse(JSON.stringify(candidateStatus));
Object.keys(cs).map(s => statusmapping[s] ? cs[statusmapping[s]] = candidateStatus[s] : null);

let serverurl = config.serverurl;
export default {
  data() {
    return {
      candidateStatus: cs,
      snackbar: false,
      store,
      serverurl,
      environment: config.environment,
    };
  },

  methods: {
    getReport: function () {

      helper.generateReport(this.params.data);

    },
    getDetails: function () {
      if (
        candidateStatus[this.params.data.status] <= 2 &&
        candidateStatus[this.params.data.status] > 9
      )
        return;

      window.open(
        location.origin + "/interviews/candidatedetails/" + this.params.data.id + "?timestamp=" + Date.now(),
        "_blank"
      );
      // newTab.onload = function () {
      //   newTab.triggerMounted();
      // };
      // this.$parent.$parent.onRowClicked({ data: { ...this.params.data } });
    },
    resendInvite: function () {
      fetch(
        serverurl +
        "/resendInvitationEmail?email=" +
        this.params.data.email +
        "&testid=" +
        this.params.data.testid
      )
        .then((s) => s.json())
        .then(() => {
          this.snackbar = true;
        });
    },
    check: function () {
      return (
        (this.candidateStatus[this.params.data.status] > 2 &&
          this.candidateStatus[this.params.data.status] < 10) ||
        this.candidateStatus[this.params.data.status] >= 11
      );
    },
  },
};
</script>
