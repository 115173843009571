<template>

  <v-container fluid>
    <div class="dashboard-page">
      <v-row
        no-gutters
        class="d-flex justify-space-between mt-3 mb-3"
        style="
          background: #fff;
          padding: 4px 10px;
          align-items: center;
        "
      >
        <h1 class="page-title">Users</h1>
        <v-spacer></v-spacer>
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="secondary"
          variant="flat"
          class="text-capitalize button-shadow mr-1"
          @click="
            selecteduser = null;
            showadduser = true;
          "
          >Create User</v-btn
        >
      </v-row>
      <v-row class="no-gutters">
        <v-card width="100vw">
          <v-data-table
            :headers="headers"
            :items="users"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.role`]="{ item }">
              
              <div v-if="store.profile.id != item.raw.id">
                <span v-if="!item.raw.editing" @click="item.raw.editing=!item.raw.editing"> {{item.raw.role}} </span>
                <!-- <template v-slot:input> label="Edit Role"  @change=" editUser(item.raw.id, 'role', newrole); :on-change="()=> editUser(item.raw.id, 'role', newrole)""-->
                  <v-select v-if="item.raw.editing"
                    
                  
                    v-model="newrole"
                    :items="['Admin', 'Recruiter']"
                    @blur="editUser(item.raw.id, 'role', newrole);item.raw.editing=!item.raw.editing"
                    variant="underlined"
                  ></v-select>
                <!-- </template> -->
              </div>
              <span v-else> {{item.raw.role}}</span>
            </template>
            <template v-slot:[`item.isInternal`]="{ item }">
              
              <v-switch v-if="store.profile.id != item.raw.id" color="primary"
                @change="
                  // item.raw.isInternal = !item.raw.isInternal;
                  editUser(item.raw.id, 'isInternal', item.raw.isInternal);
                "
                v-model="item.raw.isInternal"
                hide-details="true"
              ></v-switch>
              <span v-else> {{item.raw.isInternal? "Yes":"No"}}</span>
            </template>
            <template v-slot:[`item.confidentialAccess`]="{ item }">
              <v-switch  v-if="store.profile.id != item.raw.id"  color="primary"
                @change="
                  // item.raw.confidentialAccess = !item.raw.confidentialAccess;
                  editUser(
                    item.raw.id,
                    'confidentialAccess',
                    item.raw.confidentialAccess
                  );
                "
                hide-details="true"
                v-model="item.raw.confidentialAccess"
              ></v-switch>
              <span v-else> {{item.raw.isInternal? "Yes":"No"}}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-menu bottom left  v-if="store.profile.id != item.raw.id">
                <template v-slot:activator="{ props }">
                  <v-btn light icon v-bind="props" variant="plain" si>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="
                      showdeleteconfirmation = true;
                      selecteduser = item.raw;
                    "
                  >
                    <v-list-item-title>Delete User</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </div>
    <add-user
      :show="showadduser"
      :user="selecteduser"
      :changeShow="
        () => {
          showadduser = false;
          getUsers();
        }
      "
    ></add-user>
    <v-dialog v-model="showdeleteconfirmation" width="500">
      <v-card v-if="selecteduser">
        <v-card-text class="pt-10 font-weight-bold" style="font-size: 20px">
          Are you sure you want to delete user -
          {{ selecteduser.username }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
           variant="outlined"
            @click="showdeleteconfirmation = false"
          >
            Cancel
          </v-btn>
          <v-btn
          variant="flat"
            color="primary"
            @click="
              showdeleteconfirmation = false;
              deleteUser();
            "
          >
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
import  config from "../../config" 
let serverurl = config.serverurl;
import AddUser from "./AddUser.vue";
import { store } from "@/store/index";
import { VDataTable } from 'vuetify/labs/VDataTable'
export default {
  name: "Users",
  components: {
    AddUser,
    VDataTable
  },
  data() {
    return {
      store,
      newrole:null,
      edituser: null,
      users: [],
      selecteduser: null,
      showdeleteconfirmation: false,
      showadduser: false,
      headers: [
        { title: "Username", key: "username" },
        { title: "Email", key: "email" },
        { title: "Role", key: "role" },
        { title: "Internal", key: "isInternal" },
        { title: "Access secure detail", key: "confidentialAccess" },
        { title: "Actions", key: "action" },
        
      ],
    };
  },
  methods: {
    deleteUser() {
      if (store.profile.username == this.selecteduser.username) {
        alert("You cannot delete your own account");
        return;
      }
      fetch(serverurl + "/edituser", {
        credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: this.selecteduser.id, active: false }),
      }).then(() => this.getUsers());
    },
    editUser(id, type, value) {
      console.log("hey")
      let data = { id };
      data[type] = value;
      fetch(serverurl + "/edituser", {
        credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(() => this.getUsers());
    },
    getUsers() {
      fetch(serverurl + "/users", { credentials: "include" })
        .then((res) => res.json())
        .then((res) => {
          this.users = res.map((user) => {
            user.isInternal= !!user.isInternal;
            user.confidentialAccess= !!user.confidentialAccess;
            // user.isInternal = user.isInternal ? ( "Yes") : "No";
            // user.confidentialAccess = user.confidentialAccess ? ( "Yes") : "No";
            user.role = user.role.toUpperCase();
            return user;
          });
        });
    },
  },

  mounted() {
    this.getUsers();
  },
};
</script>

<style src="./Users.scss" lang="scss"/>
