import  config from "../config" 
let serverurl = config.serverurl;

let headers={
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};
export const getReadyToHireCandiates = (pagination, conditions)=>                                             {
    return fetch(serverurl + "/getReadyToHireCandiates", { credentials: "include" ,
    method: 'POST',
    headers,
    body: JSON.stringify({pagination, conditions})})
    .then(res => res.json()).then(res => {
        return {
          candidates: res.candidates,
          count: res.rowCount[0].count
        }
      })

}

export const checkout=()=>{
  return fetch(serverurl + "/getorderid", { credentials: "include"})
  .then(res => res.json()).then(res => {
      return res.result;
    })
}

export const getCartItems = ()=>{
  return fetch(serverurl + "/getCartdata", { credentials: "include"})
  .then(res => res.json()).then(res => {
      return res;
    })
}

export const AddToCart = (candidateid)=>{
  return fetch(serverurl + "/addInCart", { credentials: "include" ,
  method: 'POST',
  headers,
  body: JSON.stringify({candidateid})})
  .then(res => res.text()).then(res => {
      return res;
    })
}

export const RemoveFromCart = (candidateid)=>{
  return fetch(serverurl + "/removeFromCart", { credentials: "include" ,
  method: 'POST',
  headers,
  body: JSON.stringify({candidateid})})
  .then(res => res.text()).then(res => {
      return res;
    })
}

export const updatePaymentStatus= (status,response, orderid)=>{
  return fetch(serverurl + "/updatePaymentStatus", { credentials: "include" ,
  method: 'POST',
  headers,
  body: JSON.stringify({status,response,orderid})})
  .then(res => res.text()).then(res => {
      return res;
    })
}