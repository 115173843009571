<template>
    <v-card class="mb-12 pl-4 pr-4 pt-10 pb-10" style="box-shadow: none !important; width: 700px;">
        <v-card-title>Hiring Form <v-switch color="primary"  v-model="details.enable" style="position: absolute; right:10px"></v-switch></v-card-title>
        <br/>
        <v-col>

        
        <div v-if="details.enable">
            <p>
                Below data will be collected from candidates before
                interview is started.<br />
                <span>Name, Phone Number, Email, Experience, Qualification,
                    Notice period, Current CTC, Expected CTC,City</span>
                    <br />
                    <br />
                If you would like to collect any additional information,
                please provide it here...
            </p>
            <br/>
            <div class="mb-4" v-for="(q, index) in details.hiringquestions" v-bind:key="index">
                <!-- eslint-disable-next-line -->
                <question :index="index + 1" v-model="q.text" :showeightage="false" type="HIRING" :onremove="removeQuestion"></question>
            </div>
            <v-btn color="blue-grey" class="ma-2 white--text" @click="
                details.hiringquestions.push({ text: '' });
            // loadNextStep();
            "><v-icon right dark> mdi-plus </v-icon>
                Add Question
            </v-btn>
            <br />
            <!-- <v-btn color="primary" @click="
                el = 3;
            " :disabled="details.hiringquestions && details.hiringquestions.filter((s) => !s.text).length > 0
    " id="step-2">
                Continue
            </v-btn> -->
        </div>
    </v-col>
    </v-card>
</template>
<script>
import Question from "../Temp/Question.vue";
export default {
    components: { Question },
    props: ["data", "onSave","id"],
    data() {
        return {
            details: this.data,
            clientRules: [
        (v) => {
          console.log(v);
          return (!!v && v.length > 0) || "This field is required";
        },
      ],
        }
    },
    watch:{
        id:function(){
            this.details=this.$props.data
        },
        isFormValid(newVal) {
            this.details.valid=newVal
        }
    },
    methods: {
        removeQuestion(index) {
            this.details.hiringquestions.splice(index - 1, 1);
        },
    },
    computed: {
        isFormValid() {
            return !this.details.enable || (this.details.hiringquestions.find(s=>!s.text)==null)
        }
    },
}
</script>
