<template>
  <v-app>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="7" class="main-part d-none d-md-none d-lg-flex">
          <div class="d-flex">
            <v-img src="https://interview.hulkhire.com/logo/minor-white.png" width="228" contain></v-img>
            <p>HulkHire</p>
          </div>
        </v-col>
        <v-col cols="12" lg="5" class="login-part d-flex align-center justify-center">
          <v-row no-gutters class="align-start">
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper pt-md-4 pt-0">
                <div v-if="!signup.showverificationpage">
                  <v-tabs v-model="active_tab" grow color="primary">
                    <v-tab value="tab-login">
                      LOGIN
                      <br />
                    </v-tab>
                    <v-tab value="tab-signup">
                      SIGN UP
                      <br />
                    </v-tab>



                  </v-tabs>
                  <v-window v-model="active_tab">
                    <v-window-item value="tab-login">
                      <v-form>
                        <v-container>
                          <v-row class="flex-column">
                            <v-form @submit.prevent="login">
                              <v-col>
                                <br />
                                <br />
                                <v-text-field v-model="email" :rules="emailRules" label="Business Email" required
                                  variant="underlined"></v-text-field>

                                <v-text-field v-model="password" :rules="passRules" type="password" label="Password"
                                  required variant="underlined"></v-text-field>


                              </v-col>
                              <v-col class="d-flex justify-space-between">
                                <v-btn type="submit" class="text-capitalize" size="large" :disabled="ajaxRequest ||
                                  password.length === 0 ||
                                  email.length === 0 ||
                                  !pattern.test(email)
                                  " color="primary" variant="flat">
                                  Login</v-btn>
                                <br />
                                <span style="color: red">{{ errorMsg }}</span>

                                <!-- <v-btn size="large" text class="text-capitalize text-primary">Forget Password</v-btn> -->
                              </v-col>
                            </v-form>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-window-item>

                    <v-window-item value="tab-signup">

                      <v-form>
                        <v-container>
                          <v-row class="flex-column">
                            <v-form @submit.prevent="register">
                              <v-col>
                                <br />
                                <br />
                                <v-text-field color="primary" v-model="signup.email" :rules="emailRules"
                                  label="Business Email" required variant="underlined"></v-text-field>

                                <v-text-field color="primary" v-model="signup.password" :rules="signup.passRules"
                                  type="password" label="Password" required variant="underlined"></v-text-field>
                              </v-col>

                              <v-row class="no-gutters">


                                <v-col cols="4" class="pr-0">
                                  <v-select class="pa-0" :items="countries" item-title="text" item-value="phonecode"
                                    variant="underlined" v-model="selectedCountry" style="margin-top:-2px;">
                                    <template v-slot:selection="data">
                                        {{ data.item.raw.flag}} {{ data.item.raw.phonecode }} 
                                    </template>
                                  </v-select>
                                </v-col>
                                <v-col cols="7" class="pl-0">
                                  <v-text-field class="pl-0 dark-hint" persistent-hint v-model="phoneNumber" variant="underlined"
                                    placeholder="Contact number" hint="Preferably Whatsapp "
                                    :rules="[rules.required, rules.phoneNumber]"></v-text-field>
                                </v-col>
                              </v-row>

                              <v-col>
                                <v-checkbox color="primary"
                                  :label="`You can contact me with offers and promotions via email`"></v-checkbox>
                              </v-col>
                              <v-col class="d-flex justify-space-between">
                                <v-btn type="submit" class="text-capitalize" size="large" variant="flat" :disabled="ajaxRequest ||
                                  signup.password.length < 6 ||
                                  signup.email.length === 0 ||
                                  !pattern.test(signup.email) ||
                                  !phoneNumber|| !phonepattern.test(phoneNumber)
                                  " color="primary">
                                  Sign Up</v-btn>


                              </v-col>
                              <v-col>
                                <span>
                                  To know more about HulkHire,
                                  <a href="https://hulkhire.com">Click Here</a></span>
                              </v-col>
                              <v-col class="d-flex justify-space-between">
                                <span style="color: red; white-space: pre">{{
                                  signup.errorMsg
                                }}</span>
                              </v-col>
                            </v-form>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-window-item>

                  </v-window>
                </div>
                <div v-if="signup.showverificationpage">
                  <v-tabs grow color="primary" v-model="active_tab2">
                    <v-tab value="tab-verification">
                      Email Verification
                      <br />
                    </v-tab>
                  </v-tabs>
                  <v-window v-model="active_tab2">
                    <v-window-item value="tab-verification">
                      <br />
                      A verification code is sent your email - <br /> <span
                        style="text-decoration:underline; color :green;">{{ signup.email }}</span>. If not received,
                      please check your spam/junk folder as well.
                      <br />
                      <br />
                      <strong>Enter verification code:</strong>
                      <v-form @submit.prevent="verify">
                        <!-- <v-col> -->
                        <v-text-field v-model="signup.code" solo type="text" placeholder="999999" required
                          variant="underlined"></v-text-field>
                        <!-- </v-col><v-col class="d-flex justify-space-between"> -->
                        <v-btn type="submit" class="text-capitalize" size="large"
                          :disabled="ajaxRequest || signup.code.length === 0" color="primary" variant="flat">
                          Submit</v-btn>
                        <br />
                        <br />
                        <!-- </v-col> -->
                        <div v-if="signup.invalidverificationcode">
                          <span style="color: red">Invalid verification code.</span><br />
                          <span>Please provide a valid verification code or
                            <a @click.prevent="resetsignup()" style="text-decoration:underline;">change your email
                              address</a></span>
                        </div>
                      </v-form>
                    </v-window-item>
                  </v-window>

                </div>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="text-primary">
                  © {{ new Date().getFullYear() }}
                  <a target="_blank" href="https://hulkhire.com/" color="primary"
                    class="text-decoration-none">HulkHire</a>. All rights
                  reserved.
                </div>
              </v-footer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { getUserDetails, login, register, verifyuser } from "../../server/api";
console.log(register)
import { sendLogs } from "@/server/api";
import { store } from "../../store/index";
import config from "../../config";
let serverurl = config.serverurl;
export default {
  name: "Login",
  data() {
    return {
      active_tab: "tab-signup",
      active_tab2: "",
      email: "",
      pattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      emailRules: [
        (v) => !!v || "Required",
        (v) => {
          return this.pattern.test(v) || "Invalid e-mail.";
        },
      ],
      phonepattern:/^[1-9]\d{1,14}$/,
      rules: {
        required: (value) => !!value || "Required.",
        phoneNumber: (value) =>
          /^[1-9]\d{1,14}$/.test(value) ||
          "Please enter a valid phone number.",
      },
      password: "",
      passRules: [(v) => !!v || "Password is required"],
      errorMsg: "",
      countries: [],
      selectedCountry: null,
      phoneNumber: null,
      signup: {
        email: "",
        password: "",
        code: "",
        errorMsg: "",
        active_tab: 'tab-signup',
        passRules: [
          (v) => !!v || "Password is required",
          (v) => v.length >= 6 || "Min 6 characters",
        ],
        showverificationpage: false,
        invalidverificationcode: false,
      },
      ajaxRequest: false
    };
  },
  methods: {
    resetsignup() {
      this.signup.email = "";
      this.signup.password = "";
      this.signup.code = "";
      this.signup.errorMsg = "";
      this.signup.showverificationpage = false;
      this.signup.invalidverificationcode = false;
      this.active_tab = "tab-signup";
    },
    register() {
      this.ajaxRequest = true;
      this.signup.errorMsg = "";
      sendLogs({ type: "USER_REGISTER", log: this.signup.email })
      register({
        email: this.signup.email,
        password: this.signup.password,
        phoneNumber:this.selectedCountry+this.phoneNumber
      }).then((res) => {
        this.ajaxRequest = false;
        if (res.indexOf("UNVERIFIED_ACCOUNT") > -1) {
          this.signup.showverificationpage = true;
          this.active_tab2 = "tab-verification"
        }
        else this.signup.errorMsg = res;
      });
    },
    verify() {
      sendLogs({ type: "USER_VERIFY", log: this.signup.email })
      this.ajaxRequest = true;
      this.signup.invalidverificationcode = false;
      verifyuser({
        email: this.signup.email,
        code: this.signup.code,
      }).then((res) => {
        this.ajaxRequest = false;
        if (res.event == "ACCOUNT_VERIFIED") {
          store.profile = res.user;
          this.$router.push("/interviews/interviews");
          // this.$router.push("/talents/sourcing");
        } else {
          this.signup.invalidverificationcode = true;
        }
      });
    },
    login() {
      this.ajaxRequest = true;
      this.errorMsg = "";
      // window.localStorage.setItem('authenticated', true);
      login({
        email: this.email,
        password: this.password,
      }).then((res) => {
        sendLogs({ type: "USER_LOGIN", log: "" })
        this.ajaxRequest = false;
        if (res.error) {
          this.errorMsg = res.error;
        } else {
          store.profile = res.profile;
          this.$router.push("/interviews/interviews");
          // this.$router.push("/talents/sourcing");
        }
      });
    },
  },
  created() {
    this.active_tab = location.href.indexOf("signup") > -1 ? "tab-signup" : "tab-login";
    getUserDetails().then((res) => {
      sendLogs({ type: "GET_USER", log: "" })
      console.log(res);
      if (res.profile) {
        store.profile = res.profile;
        store.candidateAccess = res.candidateAccess;
        this.$router.push("/interviews/interviews");
        // this.$router.push("/talents/sourcing");
      }
    });

    // if (window.localStorage.getItem('authenticated') === 'true') {
    //   this.$router.push('/interviews/dashboard');
    // }
  },
  async mounted() {
    let results = await fetch(serverurl + "/countries");
    let countries = await results.json();
    countries.sort((a, b) => a.name > b.name).map(c => c.text = c.flag + " " + c.name + " (+" + c.phonecode.replace("+", "").split(" ")[0] + ")");
    this.countries = countries;
    let userlocation = await fetch(serverurl + "/getLocation");
    let location = await userlocation.json();
    this.selectedCountry = countries.find(c => c.isoCode == location.countryCode).phonecode;
  }
};
</script>

<style src="./Login.scss" lang="scss"/>
