<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row
        no-gutters
        class="d-flex justify-space-between mt-3 mb-3"
        style="background: #fff; padding: 4px 10px; align-items: center"
      >
        <h1 class="page-title">Elites</h1>
        <v-spacer></v-spacer>
        <v-btn
          id="create-job-jobs"
          v-bind="attrs"
          v-on="on"
          variant="flat"
          color="secondary"
          class="text-capitalize button-shadow mr-1"
          @click="$router.push('/talents/sourcing')"
          >Find more Elites</v-btn
        >
      </v-row>
      <v-row class="no-gutters">
        <v-card width="100vw">
          <v-data-table
            :headers="headers"
            :items="elites"
            :items-per-page="5"
            class="elevation-1"
            item-key="index"
          >
          <template  v-slot:[`item.firstname`]="{ item }">
            <div
              :class="[`text-subtitle-2 mt-2`]"
              class="transition-swing"
              v-text="item.firstname+' '+item.lastname"
            >
            </div>
            <a :href="'mailto:'+item.email">{{item.email}}</a><br/>
            <a  class="d-flex mb-2" :href="'tel:'+item.phonenumber">{{item.phonenumber}}</a>
            </template>
            <template  v-slot:[`item.insert_time`]="{ item }">
              <v-chip size="small" class="ma-2" text-color="white"   label v-if="item.formattteddate=='TODAY'" color="cyan">{{item.formattteddate}}</v-chip>
              <v-chip size="small" class="ma-2" text-color="white" label v-if="item.formattteddate=='YESTERDAY'" color="orange">{{item.formattteddate}}</v-chip>
              <span v-if="item.formattteddate.indexOf(' ')>-1" >{{item.formattteddate}}</span>
            </template>
            <template  v-slot:[`item.technology`]="{ item }">
              <span >{{Object.keys(item.scores).join(",")}}</span>
            </template>
            <template  v-slot:[`item.score_avg`]="{ item }">
              <b style="color:#008036" class="text-body-1">{{item.score_avg}}</b>
              <div style="font-size:12px;" v-for="sc in Object.keys(item.scores)" v-bind:key="sc">{{sc}}: &nbsp;&nbsp;{{item.scores[sc]}}</div>
            </template>
            <template  v-slot:[`item.price_paid`]="{ item }">
              <span >{{item.price_paid +" INR"}}</span>
            </template>
            <template  v-slot:[`item.current_ctc`]="{ item }">
              <span >{{item.current_ctc +" "}}</span>
            </template>
            <template  v-slot:[`item.expected_ctc`]="{ item }">
              <span >{{item.expected_ctc +" "}}</span>
            </template>
            
            <template  v-slot:[`item.experience`]="{ item }">
              <span >{{item.experience>0?item.experience+"+ yrs":"Fresher"}}</span>
            </template>
            <template  v-slot:[`item.notice_period`]="{ item }">
              <span>{{item.notice_period>1 ? item.notice_period+" Days":"Immediate Joiner"}}</span>
            </template>
            <template  v-slot:[`item.buyer`]="{ item }">
              <span class="text-capitalize">{{item.buyer}}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-menu bottom left >
                <template v-slot:activator="{ props }">
                  <v-btn light icon v-bind="props" >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item >
                    <v-list-item-title style="cursor: pointer" @click="getDetails(item.id)"> Candidate details</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </div>
    
  </v-container>
</template>

<script>

import config from "../../config";
let serverurl = config.serverurl;
import { store } from "@/store/index";
import help from '../../helper.js';
export default {
  name: "Elites",
  components: {
  },
  data() {
    return {
      store,
      headers: [
        { text: "Candidate", value: "firstname" },
        { text: "Experience", value: "experience" },
        { text: "Skill", value: "technology", sortable: false },
        { text: "Score", value: "score_avg" },
        { text: "Notice Period", value: "notice_period" },
        { text: "Current Salary", value: "current_ctc" },
        { text: "Expected Salary", value: "expected_ctc" },
        { text: "Qualification", value: "qualification" },
        { text: "Selected By", value: "buyer" },
        { text: "Selected On", value: "insert_time" },
        { text: "Price Paid", value: "price_paid" },
        { text: "Actions", value: "action" ,sortable: false},
      ],
      elites: []
    };
  },
  methods: {
    formatDate(date){
      date= new Date(date);
      let datestring="";
      const today = new Date();

      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (today.toDateString() === date.toDateString())
        datestring="TODAY"
      else if(yesterday.toDateString() === date.toDateString())
        datestring="YESTERDAY"
      else
        datestring= date.toDateString()
      return datestring;
    },
    getElites() {
      fetch(serverurl + "/getElites", { credentials: "include" })
        .then((res) => res.json())
        .then((res) => {

          res.map((r,i)=>{
            r.index=i+1;
            // r.notice_period= r.notice_period>0? r.notice_period + " Days":"Immediate Joiner";
            // r.experience= r.notice_period>0? r.notice_period + " Days":"Immediate Joiner";
            r.score_report= JSON.parse(r.list);
            r.scores={};
            
            r.score_sum=0;
            r.score_report.map(sr=>{
              r.scores[sr.technology]= sr.score
              
              })
            Object.keys(r.scores).map(sc=> r.score_sum+=r.scores[sc])
            r.score_avg=r.score_sum/Object.keys(r.scores).length;
            r.formattteddate= this.formatDate(r.insert_time);
          })
            this.elites=res;
        });
    },
    stopTour(){
      help.stopTour(this);
    },
    getDetails(id){
      window.open(
        location.origin + "/talents/#/candidatedetails/" + id,
        "_blank"
      );
    }
  },
  created() {
  },
  mounted() {
    this.getElites();
  },
  beforeDestroy() {
    console.log("unmounted");
    help.stopTour(this);
  },
};
</script>

<style src="./Elites.scss" lang="scss"/>
