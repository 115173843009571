<template>
  <v-container fluid>
    <v-row
      no-gutters
      class="d-flex justify-space-between mt-3 mb-3"
      style="background: #fff; padding: 4px 10px; align-items: center"
    >
      <h1 class="page-title">Candidates</h1>
      <v-spacer></v-spacer>
      <button class="sec mr-2"  v-if="tests && tests.length>0" @click="resetFilter()">Reset</button>
      <!-- <tag-reviewers :onrevieweradd="(reviews)=>tagReviewers(reviews)"></tag-reviewers> -->
      <!-- <i
        class="fa-solid fa-refresh"
        style="font-size: 24px; color: green; cursor: pointer"
        @click="onRefresh()"
      ></i> -->
      <a :href="downloadurl" download  v-if="tests && tests.length>0"
        ><i
          class="fa-solid fa-file-excel"
          style="font-size: 24px; color: green; cursor: pointer"
        ></i
      ></a>

      <v-btn
        v-if="
          store.profile && store.profile.role.toLowerCase() != 'bm_reviewer' &&  tests && tests.length>0
        "
        size="small"
        v-bind="attrs"
        v-on="on"
        color="secondary"
        class="text-capitalize button-shadow mr-1"
        @click="dialog = true"
        variant="flat"
        style="float: right; display: block"
        >+ Invite Candidates</v-btn
      >
    </v-row>
    <v-row no-gutters v-if="tests && tests.length>0">
      <div style="width: 98vw; background: #fff; padding: 8px">
        <ag-grid-vue
          style="height: calc(100vh - 135px)"
          class="ag-theme-balham"
          :suppressRowClickSelection="true"
          :column-defs="columnDefs"
          :rowModelType="rowModelType"
          :row-height="80"
          @grid-ready="onGridReady"
          :pagination="true"
          :paginationPageSize="10"
          :check-box-selection="true"
          :default-col-def="defaultColDef"
          :row-selection="rowSelection"
          :get-row-class="getRowClass"
          :autoSize-all-columns="true"
          @filter-changed="onFilterChanged"
          @selection-changed="onSelectionChanged"
          :overlayLoadingTemplate="overlayLoadingTemplate"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
        >
        </ag-grid-vue>
      </div>
    </v-row>
    <v-row no-gutters v-if="!tests || tests.length==0">
      <!-- <v-card
  elevation="2"
 variant="outlined"
>

<h3>Lets start by creating job first</h3>
</v-card> -->
      <div  style="width: 98vw; background: #fff; padding: 8px;" class="text-center">
        <h2 class="text-center mt-10 mb-10">Let's start by creating interview first</h2>
        <v-btn
        v-if="
          store.profile && store.profile.role.toLowerCase() != 'bm_reviewer'
        "
        size="large"
        v-bind="attrs"
        v-on="on"
        color="secondary mb-10"
        class="text-capitalize button-shadow mr-1"
        @click="$router.push('/interviews/create')"
        >Create Interview</v-btn
      >
      </div>
    </v-row>
    <v-dialog v-model="dialog" width="1000">
      <v-card v-if="!sending">
        <v-card-text style="padding-top: 20px">
          <h4 class="font-weight-bold mb-10" style="color: black">
            Invite Candidates 
          </h4>
          <div>
            <v-btn
              @click="invitetype = 'self'"
              :class="[{ selected: invitetype == 'self' }]"
              class="mr-10"
              >Self Invitation Link</v-btn
            >
            <v-btn
              @click="invitetype = 'bulk'"
              :class="[{ selected: invitetype == 'bulk' }]"
              class="mr-10"
              >Bulk Candidate Upload</v-btn
            >
            <!-- <v-btn @click="invitetype = 'direct'">Direct Interview Link</v-btn> -->
          </div>
          <label class="mt-4 d-flex font-weight-bold">Choose Interview</label>
          <v-select
          placeholder="Select Interview"
            :items="tests"
            item-title="testname"
            item-value="id"
            v-model="selectedTest"
            variant="outlined"
          >
          </v-select>
          <div v-if="invitetype == 'self'">
            This is the Direct invitation link for the job you have created,
            meaning that if applicant navigate to this URL, applicant can
            directly book their slot for interview. <br />You can send it out
            via email to invite individual candidates to their video
            interview.<br />
            Or you can also include this link in your job listings so interested
            candidates can directly apply for the interview.
            <br /><br /><v-row class="ma-0">
              <v-text-field
                class="rounded-0"
               variant="outlined"
                readonly
                :value="getLink()"
                solo
              ></v-text-field>
              <v-btn
                dark
                size="x-large"
                class="rounded-0"
                style="height: 56px; background-color: #cfd5e2; color: black"
                @click="
                  copylink();
                  snackbar = true;
                "
              >
                COPY
              </v-btn></v-row
            >
          </div>
          <div v-if="invitetype == 'bulk'">
            You can Upload list of the candidates, you would like to invite for
            the interview.<br />
            On click of Invite Candidates, an email will be shared to all
            applicants with a self inviation link.<br />
            Using that link, they can book their slot for video interview.
            <div style="margin-top: 20px">
              <label class="font-weight-bold"
                >Upload Candidate List - (Max 100)</label
              ><br />
              <br />
              <input
                type="file"
                id="excelFile"
                ref="fileupload"
                @change="excelExport"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
              <button class="sec" style="float: right" @click="downloadSample">
                Download sample file
              </button>
            </div>
            <div style="height: 200px; overflow: auto; margin-top: 20px">
              <table id="candidates">
                <tr>
                  <td>Name</td>
                  <td>Email</td>
                  <td>Whatsapp</td>
                </tr>
                <tr v-for="cd in excelData" v-bind:key="cd.id">
                  <td>{{ cd.name }}</td>
                  <td>{{ cd.email }}</td>
                  <td>{{ cd.whatsapp }}</td>
                </tr>
              </table>
            </div>
            {{ excelData.length }} candidates imported
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" variant="flat" @click="sendInvitation">
                Invite candidates
              </v-btn>
            </v-card-actions>
          </div>
          <!-- <div v-if="invitetype == 'direct'">
            This is the direct interview link for the selected job.
            <b>Please note that this link is only valid for next 24 hours.</b
            ><br />
            Candidate does not need to book any interview slot, while accessing
            this link and can start with interview directly. <br /><br /><v-row
              class="ma-0"
            >
              <v-text-field
                style="border-color: lightgray; color: #cfd5e2"
                class="rounded-0"
               variant="outlined"
                readonly
                :value="getLink()"
                solo
              ></v-text-field>
              <v-btn
                dark
                size="x-large"
                class="rounded-0"
                style="height: 56px; background-color: #cfd5e2; color: black"
                @click="
                  copylink();
                  snackbar = true;
                "
              >
                COPY
              </v-btn>
            </v-row>
          </div> -->
        </v-card-text>
      </v-card>
      <v-card color="primary" dark v-if="sending">
        <v-card-text>
          <br />
          Please wait.. Sending invitations..
          <br />
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
            height="6"
          ></v-progress-linear>
          <br />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="2000">
      {{ type == "bulk" ? "Invitation Sent" : "Link Copied" }}
    </v-snackbar>
  </v-container>
</template>

<style>
</style>
<script>
import { store } from "../../store";
import { saveAs } from "file-saver";
import config from "../../config";
let serverurl = config.serverurl;

import * as XLSX from "xlsx";

import { AgGridVue } from "ag-grid-vue3";
import CandidateContact from "./CandidateContact.vue";
import Experience from "./Experience.vue";
// import Match from "./Match.vue";
import Actions from "./Actions.vue";
import Score from "./Score.vue";

// import CTC from "./CTC.vue";
import Test from "./Test.vue";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css";
// import "@ag-grid-community/core/dist/styles/ag-grid.css";
// import "@ag-grid-community/core/dist/styles/ag-theme-balham.css";
import candidateStatus from "../../master/candidateStatus.js";
import statusmapping from "../../master/statusmapping.js";
import statusFilter from "./statusFilter.js";
import jobFilter from "./jobFilter.js";

// import TagReviewers from './TagReviewers.vue';

// let isFirstColumn = function isFirstColumn(params) {
//   var displayedColumns = params.columnApi.getAllDisplayedColumns();
//   var thisIsFirstColumn = displayedColumns[0] === params.column;
//   return thisIsFirstColumn;
// };

let fieldmapping = {
  fullcontact: ["email", "firstname", "lastname", "phonenumber"],
  insert_dateformat: ["insert_date"],
};

export default {
  name: "Candidate",
  components: {
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    CandidateContact: CandidateContact,
    Test: Test,
    // CTC: CTC,
    Actions: Actions,
    Score: Score,
    // Match: Match,
    Experience: Experience,
    statusFilter,
    jobFilter,
    // TagReviewers
  },
  data() {
    return {
      store,
      snackbar: false,
      invitetype: "self",
      link: "google.com",
      tests: null,
      selectedTest: null,
      sending: false,
      dialog: false,
      excelData: [],
      overlayLoadingTemplate: null,
      overlayNoRowsTemplate: null,
      getRowClass: (params) => {
        if (params.data)
          return params.data.status.toLowerCase().replace(/ /g, "-");
        else return null;
      },
      columnDefs: [
        {
          headerName: "Candidate",
          cellRenderer: "CandidateContact",
          field: "fullcontact",
          minWidth: 280,
          autoHeight: true,
          filterParams: {
            filterOptions: ["contains", "notContains"],
            suppressAndOrCondition: true,
          },
          suppressSizeToFit: true,
          sortable: false,
        },

        {
          headerName: "Job",
          field: "testid",
          minWidth: 200,
          cellRenderer: "Test",
          autoHeight: true,
          filter: "jobFilter",
        },
        {
          headerName: "Status",
          field: "status",
          minWidth: 150,
          filter: "statusFilter",
        },
        { headerName: "Reason", field: "reason", minWidth: 150, filter: false },
        {
          headerName: "BM Technical Score",
          field: "technical_avg",
          cellRenderer: "Score",
          minWidth: 180,
          filter: "agNumberColumnFilter",
          filterParams: {
            filterOptions: [
              "greaterThanOrEqual",
              "lessThanOrEqual",
              "equals",
              "inRange",
            ],
            suppressAndOrCondition: true,
          },
        },
        // {
        //   headerName: "BM Communication Score",
        //   field: "communication",
        //   minWidth: 200,
        //   filter: "agNumberColumnFilter",
        //   filterParams: {
        //     filterOptions: [
        //       "greaterThanOrEqual",
        //       "lessThanOrEqual",
        //       "equals",
        //       "inRange",
        //     ],
        //     suppressAndOrCondition: true,
        //   },
        // },
        // { headerName: "BM Comments", field: "comments", width: 150, filter: false },

        // { headerName: "Skills", field: "requiredskill" },
        {
          headerName: "Experience",
          field: "experience",
          filter: "agNumberColumnFilter",
          cellRenderer: "Experience",
          minWidth: 150,
          filterParams: {
            filterOptions: [
              "greaterThanOrEqual",
              "lessThanOrEqual",
              "equals",
              "inRange",
            ],
            suppressAndOrCondition: true,
          },
        },
        {
          headerName: "Notice Period",
          field: "notice_period",
          filter: "agNumberColumnFilter",
          cellRenderer: (params) => {
            if (params.value == null) return "-";
            else return params.value > 0 ? params.value + " days" : "Immediate";
          },
          minWidth: 120,
          filterParams: {
            filterOptions: [
              "lessThanOrEqual",
              "greaterThanOrEqual",
              "equals",
              "inRange",
            ],
            suppressAndOrCondition: true,
          },
        },
        {
          headerName: "CTC",
          filter: "agNumberColumnFilter",
          field: "current_ctc",
          cellRenderer: (params) => {
            let ctc = params.value;
            //.replace("LPA","").trim();
            if (ctc == null) return "-";
            else return ctc > 0 ? ctc + " " : " Not Earning";
          },
          minWidth: 150,
          autoHeight: true,
          filterParams: {
            filterOptions: [
              "lessThanOrEqual",
              "greaterThanOrEqual",
              "equals",
              "inRange",
            ],
            suppressAndOrCondition: true,
          },
        },
        {
          headerName: "Expected CTC",
          filter: "agNumberColumnFilter",
          field: "expected_ctc",
          cellRenderer: (params) => {
            let ctc = params.value;
            //.replace("LPA","").trim();
            if (ctc == null) return "-";
            else return ctc > 0 ? ctc + " " : " Not mentioned";
          },
          minWidth: 150,
          autoHeight: true,
          filterParams: {
            filterOptions: [
              "lessThanOrEqual",
              "greaterThanOrEqual",
              "equals",
              "inRange",
            ],
            suppressAndOrCondition: true,
          },
        },
        // {
        //   headerName: "Match",
        //   field: "match",
        //   cellRenderer: "Match",
        //   minWidth: 350,
        //   autoHeight: true,
        //   filter: false,
        //   sortable: false,
        // },
        {
          headerName: "Interview Time",
          filter: "agDateColumnFilter",
          field: "interviewtime",
          minWidth: 200,
          cellRenderer: (params) => {
            return params.value ? params.value.toLocaleString() : "-";
          },
          filterParams: {
            filterOptions: ["greaterThan", "lessThan", "inRange"],
            suppressAndOrCondition: true,
          },
        },
        {
          headerName: "Invited on",
          filter: "agDateColumnFilter",
          field: "insert_dateformat",
          minWidth: 200,
          cellRenderer: (params) => {
            return params.value ? params.value.toLocaleString() : null;
          },
          filterParams: {
            filterOptions: ["greaterThan", "lessThan", "inRange"],
            suppressAndOrCondition: true,
          },
        },

        {
          headerName: "Actions",
          cellRenderer: "Actions",
          cellRendererParams: {
            clicked: (e) => {
              this.onRowClicked(e);
            },
          },
          maxWidth: 70,
          pinned: "right",
          filter: false,
          sortable: false,
        },
      ],
      rowData: [],
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        flex: 1,
        minWidth: 100,
        minHeight: 400,
        resizable: true,
        filter: true,
        floatingFilter: true,
        sortable: true,
        // headerCheckboxSelectionFilteredOnly: true,
        // headerCheckboxSelection: isFirstColumn,
        // checkboxSelection: isFirstColumn,
      },

      rowSelection: "multiple",
      videoData: null,
      availablestatus: [],
      status: "",
      statuschange: false,
      rowModelType: null,
      downloadurl: serverurl + "/export-candidates?c=" + [],
      getRowHeight: (params) =>
        params.data && params.data.phonenumber ? 80 : 40,
    };
  },
  methods: {
    onFilterChanged() {
      let filterModel = JSON.parse(JSON.stringify(this.gridApi.getFilterModel()));
      let params = {
        filterModel
      };
      
      
      let conditions = this.fetchconditions(params);
      this.downloadurl =
        serverurl + "/export-candidates?c=" + JSON.stringify(conditions);
    },
    // downloadurl() {
    //   if(this.gridApi){

    //   return serverurl+"/export-candidates?c="+JSON.stringify(conditions);
    //   }
    //   return "";
    // },
    onRefresh() {
      this.gridApi.refreshInfiniteCache();
    },
    getLink() {
      if (!this.tests.find((t) => t.id == this.selectedTest)) return null;
      if (this.invitetype == "self")
        this.link =
          "https://jobs.hulkhire.com/schedule/" +
          this.store.profile.profile.toLowerCase() +
          "/" +
          this.tests
            .find((t) => t.id == this.selectedTest)
            .testname.toLowerCase()
            .replace(/ /g, "-") +
          "/" +
          this.selectedTest +
          "/1";
      else if (this.invitetype == "direct")
        this.link = "https://interview.hulkhire.com?token=" + 123456;
      return this.link;
    },
    framecondition(field, operation, value, range, type) {
      let condition;
      if (operation.indexOf("LIKE") >= 0)
        condition = "c." + field + " " + operation + " " + "'%" + value + "%'";
      else if (operation == "BETWEEN")
        condition =
          field +
          " " +
          operation +
          (type == "number" ? " " : " '") +
          range[0] +
          (type == "number" ? " and " : "' and '") +
          range[1] +
          (type == "number" ? " " : "' ");
      else if (operation == "in")
        condition = field + " " + operation + " " + value;
      else
        condition =
          field +
          " " +
          operation +
          (type == "number" ? " " : " '") +
          (value || range[0] || range[1]) +
          (type == "number" ? "" : "' ");
      return condition.replace(/0.0001/g, "0");
    },
    getoperationfornumber(model) {
      let operation;
      switch (model.type) {
        case "greaterThanOrEqual":
          operation = ">=";
          break;
        case "lessThanOrEqual":
          operation = "<=";
          break;
        case "equals":
          operation = "=";
          break;
        case "inRange":
          operation = "BETWEEN";
          break;
      }
      return operation;
    },
    getoperationfordate(model) {
      let operation;
      switch (model.type) {
        case "greaterThan":
          operation = ">";
          break;
        case "lessThan":
          operation = "<";
          break;
        case "inRange":
          operation = "BETWEEN";
          break;
      }
      return operation;
    },
    getoperationfortext(model) {
      let operation;
      switch (model.type) {
        case "contains":
          operation = "LIKE";
          break;
        case "notContains":
          operation = "NOT LIKE";
          break;
      }
      return operation;
    },
    getoperation(model) {
      let operation;
      switch (model.filterType) {
        case "number":
          operation = this.getoperationfornumber(model);
          break;
        case "text":
          operation = this.getoperationfortext(model);
          break;
        case "date":
          operation = this.getoperationfordate(model);
          break;
        case "multiselect":
          operation = "in";
          break;
      }
      return operation;
    },
    fixRangeValues(operation, model) {
      if (operation == "in")
        model.filter = "('" + model.filter.join("','") + "')";
      if (model.filter == 0) model.filter = 0.0001;
      if (model.filterTo == 0) model.filterTo = 0.0001;
      if (operation == "BETWEEN" && model.type == "date") {
        if (new Date(model.dateFrom) > new Date(model.to)) {
          let temp = model.dateFrom;
          model.dateFrom = model.dateTo;
          model.dateTo = temp;
        }
      }
      if (operation == "BETWEEN" && model.type == "number") {
        if (model.filter > model.filterTo) {
          let temp = model.filter;
          model.filter = model.filterTo;
          model.filterTo = temp;
        }
      }

      if (model.dateFrom) {
        model.dateFrom = new Date(model.dateFrom).toISOString();
      }
      if (model.dateTo) {
        model.dateTo = new Date(model.dateTo).toISOString();
      }
    },
    fetchconditions(params) {
      let reversemapping={};
      Object.keys(statusmapping).map(k=>reversemapping[statusmapping[k]]=k);
      if(params.filterModel.status){
        params.filterModel.status.filter=params.filterModel.status.filter.map(s=>reversemapping[s]||s);
      }
      let conditions = [];

      console.log(params);
      Object.keys(params.filterModel).map((key) => {
        let model = params.filterModel[key];
        let operation = this.getoperation(model);
        this.fixRangeValues(operation, model);

        if (fieldmapping[key]) {
          let cond = fieldmapping[key].map((k) =>
            this.framecondition(
              k,
              operation,
              model.filter || model.dateTo || model.dateFrom,
              [model.filter || model.dateFrom, model.filterTo || model.dateTo],
              model.filterType
            )
          );
          conditions.push("(" + cond.join(" or ") + ")");
        } else
          conditions.push(
            this.framecondition(
              key,
              operation,
              model.filter || model.dateTo || model.dateFrom,
              [model.filter || model.dateFrom, model.filterTo || model.dateTo],
              model.filterType
            )
          );
      });
      console.log(conditions);
      return conditions;
    },
    fetchNewData(p) {
      let params = JSON.parse(JSON.stringify(p));
      let conditions = this.fetchconditions(params);
      let order = {};
      if (params.sortModel.length > 0) {
        order.column =
          fieldmapping[params.sortModel[0].colId] || params.sortModel[0].colId;
        order.type = params.sortModel[0].sort;
      }
      this.gridApi.showLoadingOverlay();

      this.getCandidates(conditions, order, {
        start: params.startRow,
        end: params.endRow,
      }).then((c) => {
        // this.gridApi.setRowCount(c.length)
        console.log("received");
        this.gridApi.hideOverlay();
        if(c.candidates.length==0)
          this.gridApi.showNoRowsOverlay()
        p.successCallback(c.candidates, c.count);
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      var datasource = {
        rowCount: null,
        getRows: (params) => {
          this.fetchNewData(params);
        },
      };
      this.fetchTests().then(() => {
        this.gridApi.setDatasource(datasource);
      });
    },
    requestReviews() {
      let candidateCount = this.gridApi
        .getSelectedNodes()
        .filter((s) => s.data.status == "Waiting for evaluation").length;

      if (this.gridApi.getSelectedNodes().length != candidateCount) {
        alert(
          "Reviewers can be tagged only for the candidates who have apeared for interview"
        );
        return;
      }
      if (candidateCount == 0) {
        alert("Please select candidates to to tag reviewers");
        return;
      }
      // this.tagReviewers();
    },
    // tagReviewers(){

    // },
    onSelectionChanged() {
      // var rowCount = event.api.getSelectedNodes().length;
      // let rows = event.api
      //   .getSelectedNodes()
      //   .filter((s) => s.data.status == "Waiting for evaluation");
      // window.alert("selection changed, " + rowCount + " rows selected");
    },

    resetFilter() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: {
          sort: null,
        },
      });
    },
    getCandidates(conditions, order, pagination) {
      return fetch(serverurl + "/candidates", {
        credentials: "include",
        method: "post",
        body: JSON.stringify({ conditions, order, pagination }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((t) => {
          this.rowData = t.candidates.map((row) => {
            row.status= statusmapping[row.status]|| row.status;
            if (row.experience != null)
              row.experience = row.experience ? parseInt(row.experience) : 0;
            if (row.notice_period != null)
              row.notice_period = row.notice_period
                ? parseInt(row.notice_period)
                : 0;
            if (row.current_ctc != null)
              row.current_ctc = row.current_ctc ? parseInt(row.current_ctc) : 0;
            if (row.expected_ctc != null)
              row.expected_ctc = row.expected_ctc
                ? parseInt(row.expected_ctc)
                : 0;
            row.scores = {};
            if (row.technologies) {
              row.technologies.split(",").map((r, i) => {
                row.scores[r] = row.tech_scores?.split(",")[i];
              });
            }

            // row.scores = t.bm_score.find((r) => r.candidateid == row.id);
            // if (row.scores) {
            //   let sc = t.bm_score.find((r) => r.candidateid == row.id);
            //   let scores = {};
            //   row.total = 0;
            //   Object.keys(sc).map((tech) => {
            //     let filterkeys = [
            //       "cheated",
            //       "id",
            //       "communication",
            //       "comments",
            //       "result",
            //       "insert_time",
            //       "test_id",
            //       "email",
            //       "shared",
            //       "username",
            //       "candidateid",
            //       "source",
            //       "userid",
            //       "profile",
            //     ];
            //     if (
            //       filterkeys.indexOf(tech) == -1 &&
            //       sc[tech] != null &&
            //       sc[tech] >= 0 &&
            //       sc[tech] <= 5
            //     ) {
            //       scores[tech] = sc[tech];
            //       row.total += sc[tech];
            //     }
            //   });
            //   row.scores = scores;
            //   row.technical_avg =
            //     Math.round((row.total / Object.keys(scores).length) * 100) /
            //     100;
            //   row.communication = Math.round(sc["communication"] * 100) / 100;
            //   row.comments = sc.comments;
            // } else {
            //   row.technical_avg = "-";
            //   row.communication = "-";
            // }
            row.interviewtime = new Date(row.interviewtime);
            row.insert_date = new Date(row.insert_date).toDateString();
            row.insert_dateformat = new Date(row.insert_date);
            row.fullcontact =
              row.firstname +
              (row.lastname ? row.lastname : "") +
              row.email +
              (row.phonenumber ? row.phonenumber : "");
            // let test = this.tests.find((z) => z.id == row.testid);
            // if (test) this.calculateMatch(row, test.testFilters);
            return row;
          });
          return { candidates: this.rowData, count: t.rowCount[0].count };
        });
    },
    onRowClicked(e) {
      if (
        candidateStatus[e.data.status] <= 2 &&
        candidateStatus[e.data.status] > 9
      )
        return;

      const newTab = window.open(
        location.origin + "/#/interviews/candidatedetails/" + e.data.id+ "?timestamp=" + Date.now(),
        "_blank"
      );
      newTab.onload= function() {
  newTab.triggerMounted();
};
    },
    // calculateMatch(row, filters) {
    //   if (candidateStatus[row.status] <= 2 || candidateStatus[row.status] > 9)
    //     return;
    //   let match = 0;
    //   if (!filters || filters.length == 0) return;
    //   row.filters = {};
    //   filters.map((f) => {
    //     let match_status = false;
    //     if (f.type == "number") {
    //       let range = JSON.parse(f.matchvalues[0])[0];
    //       if (
    //         row[f.mapped_column] >= range.min &&
    //         row[f.mapped_column] <= range.max
    //       )
    //         match_status = true;
    //     } else if (f.matchvalues.indexOf(row[f.mapped_column]) > -1)
    //       match_status = true;

    //     if (match_status) {
    //       row.filters[f.filter_name] = {
    //         value: row[f.mapped_column],
    //         result: true,
    //       };
    //       match++;
    //     } else
    //       row.filters[f.filter_name] = {
    //         value: row[f.mapped_column],
    //         result: false,
    //       };
    //   });
    //   row.match = (match / filters.length) * 100;
    // },
    async fetchTests() {
      return fetch(serverurl + "/tests", {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((t) => {
          this.tests = t;
          return;
        });
    },
    excelExport(event) {
      var input = event.target;
      var reader = new FileReader();
      reader.onload = () => {
        var fileData = reader.result;
        var wb = XLSX.read(fileData, { type: "binary" });
        wb.SheetNames.forEach((sheetName) => {
          var rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
          console.log(rowObj);
          this.excelData = rowObj.filter((s) => s.name && s.email && s.whatsapp);
        });
      };
      reader.readAsBinaryString(input.files[0]);
    },
    downloadSample() {
      var ns = XLSX.utils.book_new();
      ns.props = {
        title: "Candidates",
        subject: "candidate",
        Auther: "HulkHire",
        createdDate: Date.now(),
      };
      ns.SheetNames.push("Candidate");
      var nb_data = [
        ["name", "email","whatsapp"],
        ["John Brown", "john@gmail.com","919999999999"],
      ];
      var nb = XLSX.utils.aoa_to_sheet(nb_data);
      ns.Sheets["Candidate"] = nb;

      var nbOut = XLSX.write(ns, { bookType: "xlsx", type: "binary" });
      function saveBook(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      }
      saveAs(
        new Blob([saveBook(nbOut)], { type: "application/octet-stream" }),
        "candidates.xlsx"
      );
    },
    sendInvitation() {
      if (this.excelData.length == 0 || !this.selectedTest) {
        alert("Select interview and import candidates");
        return;
      }

      this.sending = true;
      fetch(serverurl + "/sendInvitation", {
        credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          candidates: this.excelData,
          testid: this.selectedTest,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.dialog = false;
          this.sending = false;
          this.excelData = [];
          this.gridApi.refreshInfiniteCache();
          // this.$refs.fileupload.value=null;
        });
    },
    copylink() {
      navigator.clipboard.writeText(this.link);
    },
  },
  created() {
    this.rowModelType = "infinite";
    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">Please wait.. Getting candidates..</span>';
    this.overlayNoRowsTemplate =
      `<span style="font-size:16px; padding: 10px; border: 2px solid #008060; background: #fff;">
      <b>No matching candidates</b><br/> 
      <span style="font-size:14px; color:gray; display:block;" class="mt-2">
      Try changing filtration criteria or Invite more candidates</span>
      </span>`;
    // this.cacheBlockSize = 10;
    // this.maxBlocksInCache = 10;
  },
  async mounted() {
    console.log("hey")
    await this.fetchTests()
  },
};
</script>

<style src="./Candidate.scss" lang="scss"/>
