<template>
    <v-app>
        <v-container class="pt-10">
            <v-row cols="3">
                <v-img src="https://interview.hulkhire.com/logo/full-black-tp.png" alt="HulkHire" style=" height: 36px;" />
            </v-row>
            <v-row>
                <v-col>
                    <v-card class="pa-4">
                        <v-card-title class="justify-start">
                            <h3 class="align-left">Mock Candidate Interview journey</h3>
                        </v-card-title>
                        <div>
                            <v-row class="no-gutters">
                                <v-col cols="6">Firstname: &nbsp;&nbsp;{{ candidate.firstname }}<br /></v-col>
                                <v-col cols="6">Interview:&nbsp;&nbsp;{{ testdetails.testname }}<br /></v-col>
                            </v-row>
                            <v-row class="no-gutters">
                                <v-col cols="6">Email:&nbsp;&nbsp;{{ candidate.email }}<br /></v-col>
                                <v-col cols="6">Interview Time:&nbsp;&nbsp;{{ candidate.interviewtime ? new
                                    Date(candidate.interviewtime).toDateString() : "-" }}<br /></v-col>
                            </v-row>
                            <v-row class="no-gutters">
                                <v-col cols="6">Status:&nbsp;&nbsp;{{ candidate.status }}<br /></v-col>
                            </v-row>

                        </div>
                        <v-row class="mt-4 mb-4 no-gutters">
                            <v-btn variant="outlined" color="primary" v-if="candidate.status == 'SLOT MAIL SENT'"
                                @click="scheduleInterview()">Schedule Interview
                                for 2 days later</v-btn>
                            <v-btn variant="outlined" color="primary"
                                v-if="['SLOT MAIL SENT', 'INVITATION SENT', 'NO SHOW'].indexOf(candidate.status) > -1"
                                @click="cancelInterview()">Cancel Interview</v-btn>
                            <v-btn color="primary" variant="outlined" class="mr-4"
                                v-if="candidate.status == 'INVITATION SENT' || candidate.status == 'NO SHOW'"
                                @click="rescheduleInterview()">Re Schedule Interview for 3 days later</v-btn>
                            <!-- <v-btn variant="outlined" color="primary" v-if="candidate.status == 'INVITATION SENT'"
                                @click="updateStatus('NO_SHOW')">mark as No
                                Show</v-btn> -->
                            <v-btn variant="outlined" color="primary" v-if="candidate.status == 'INVITATION SENT'"
                                @click="markCompleted()">Mark
                                as
                                completed</v-btn>
                            <v-form v-if="candidate.status == 'WAITING FOR EVALUATION'">
                                <div v-for="skill in skills" v-bind:key="skill">
                                    {{ skill }} : <v-text-field variant="outlined" type="number"
                                        v-model="feedback.score[skill]"></v-text-field>
                                </div>
                                <div v-if="skills.indexOf('IN-PERSON') > -1">
                                    <label>Observations</label>
                                    <v-textarea variant="outlined" type="text" v-model="feedback.observations"></v-textarea>
                                    <label>Projects</label>
                                    <v-textarea variant="outlined" type="text" v-model="feedback.projects"></v-textarea>
                                    <label>Excitement to join</label>

                                    <v-textarea variant="outlined" type="text"
                                        v-model="feedback.excitementToJoin"></v-textarea>
                                    <label>Attitude</label>
                                    <v-textarea variant="outlined" type="text" v-model="feedback.attitude"></v-textarea>
                                    <label>Communication</label>
                                    <v-textarea variant="outlined" type="text"
                                        v-model="feedback.communication"></v-textarea>

                                </div>
                                <v-btn variant="outlined" color="primary" @click="submitFeedback()">Submit
                                    Feedback</v-btn>
                            </v-form>

                        </v-row>
                        <v-card-title v-if="candidate.status == 'BM VERIFIED'" class="justify-start">
                            <h3>Feedback</h3>
                        </v-card-title>
                        <v-row v-if="candidate.status == 'BM VERIFIED'" class="flex-column no-gutters">
                            <v-row class="no-gutters pl-2" v-for="s in testresults" v-bind:key="s.id" cols="12">
                                {{ s.technology }}- {{ s.overallscore }}
                                <br />
                                <div v-if="s.technology == 'IN-PERSON'">
                                    {{ s.coding_score }}
                                </div>
                            </v-row>

                        </v-row>
                    </v-card>

                </v-col>
            </v-row>

        </v-container>
    </v-app>
</template>
<script>
// -> Schedule Interview 
// -> Reschedule Interview
// -> Cancel Interview
// -> No Show Interview
// -> Complete Interview
// -> Give Feedback
import config from "../../config";
let serverurl = config.serverurl;

function getTimezoneOffsetUTC() {
    let offset = new Date().getTimezoneOffset();
    let sign = offset > 0 ? "-" : "+";
    offset = Math.abs(offset);
    let hours = Math.floor(offset / 60);
    let minutes = offset % 60;
    return sign + pad(hours) + ":" + pad(minutes);
}

function pad(number) {
    if (number < 10) {
        return "0" + number;
    }
    return number;
}

export default {
    name: "DummyInterview",
    data() {
        return {
            candidate: {},
            feedback: {
                score: {},
                projects: "",
                observations: "",
                communication: "",
                attitude: "",
                excitementToJoin: ""
            },
            skills: [],
            testdetails: {},
            testresults: {}
        }
    },
    methods: {
        // get candidate status , skills
        async getCandidateDetails(candidateid) {
            // get candidate details
            await fetch(serverurl + `/candidatedetails?id=${candidateid}`, {
                credentials: "include"
            })
                .then(res => res.json())
                .then(res => {
                    this.candidate = res.candidate;
                    this.candidate.status = res.candidatestatus.status.toUpperCase();
                    this.skills = res.testdetails.requiredskill.split(",");
                    this.testdetails = res.testdetails;
                    this.testresults = res.testresults;
                });
        },
        async scheduleInterview() {

            await fetch(serverurl + "/sendInvitation", {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    candidates: [
                        {
                            firstname: this.candidate.firstname,
                            lastname: this.candidate.lastname,
                            email: this.candidate.email.trim(),
                            profile_url: "",
                            whatsapp: "",
                            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone + "$$$UTC" + getTimezoneOffsetUTC(),
                        },
                    ],
                    type: "SELF",
                    interviewtime: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
                    sourcing_category: this.sourcing_category,
                    testid: this.testdetails.id,
                    profile: this.testdetails.profile,
                }),
            })

            this.getCandidateDetails(this.candidate.id);

        },
        async rescheduleInterview() {

            await fetch(serverurl + "/rescheduleinterview", {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    candidateid: this.candidate.id,
                    interviewtime: new Date(Date.now() + 3 * 24 * 60 * 60 * 1000),
                }),
            })

            this.getCandidateDetails(this.candidate.id);

        },
        async markCompleted() {

            await fetch(serverurl + "/markTestFinished", {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    testProfileId: this.testdetails.id,
                    id: this.candidate.id
                }),
            })

            this.getCandidateDetails(this.candidate.id);
        },
        async cancelInterview() {
            await fetch(serverurl + "/changeCandidateStatus", {
                credentials: "include",
                method: "post",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: this.candidate.id,
                    testid: this.testdetails.id,
                    status: "Cancelled",
                    reason: "Candidate cancelled interview"
                })
            });
            this.getCandidateDetails(this.candidate.id);

        },
        updateStatus(status) {
            console.log(status);
        },
        async submitFeedback() {
            let data = {
                id: this.candidate.id,
                testid: this.testdetails.id,
                email: this.candidate.email,
                name: this.candidate.firstname,
                skills: {
                }
            }
            this.skills.map(s => {
                if (s == "IN-PERSON") {
                    data.skills[s] = {
                        score: this.feedback.score[s], details: {
                            observations: this.feedback.observations,
                            excitementToJoin: this.feedback.excitementToJoin,
                            attitude: this.feedback.attitude,
                            communication: this.feedback.communication,
                            projects: this.feedback.projects
                        }
                    }
                }
                else
                    data.skills[s] = { score: this.feedback.score[s] }
            })
            await fetch(serverurl + "/submitfeedback", {
                credentials: "include",
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })

            this.getCandidateDetails(this.candidate.id);
        }
    },
    mounted: async function () {
        // get candidateid and testid from url

        // if(location.host.indexOf("sandbox") == -1 ){
        //     this.$router.push("/404");
        // }
        let candidateid = this.$route.params.candidateid;
        await this.getCandidateDetails(candidateid);

    }
}
</script>