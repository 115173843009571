<template>
  
    
    <v-row class="ma-0 ml-1">

      <v-col cols="12" sm="4" md="2">
        <v-select
          :items="items"
          variant="underlined"
          item-title="name"
          item-value="name"
          height="30"
          style="width:200px;z-index: 1000000;"
          v-on:change="
            (val) => {

              item = items.find((s) => s.name == val);
              setname(val);
              setvalue(null);
            }
          "
        ></v-select>
      </v-col>
      <v-col  cols="12" sm="4" md="2">
        <div v-if="item">
          
          <div class="v-label v-label--active theme--light" style="font-size:14px" v-if="item.type == 'number' && item.value">
            {{ item.value[0] }} <span style="float:right">{{ item.value[1] }}</span>
          </div>
          <v-range-slider
            v-if="item.type == 'number'"
            v-model="item.value"
            :max="item.max"
            :min="item.min"
            style="width:200px"
            v-on:input="
            (val) => {
              setvalue(JSON.stringify([{min:val[0], max:val[1]}]));
            }
          "
          >
          </v-range-slider>
          <v-select
            v-if="item.type != 'number'"
            v-model="item.value"
            :items="item.items"
            :label="item.name"
            multiple
            height="30"
            variant="underlined"
            style="width:200px"
            v-on:change="(val) => {setvalue(val.join('$'));}"></v-select>
        </div>
      </v-col>
      <v-col  cols="12" sm="4" md="2">
        <v-btn
          class="mx-2"
          fab
          dark
          size="small"
          color="secondary"
          style="text-decoration:none"
         variant="outlined"
          @click="onadd(index, type)"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          fab
          dark
          size="small"
          color="secondary"
          style="text-decoration:none"
         variant="outlined"
          @click="onremove(index, type)"
        >
          <v-icon dark> mdi-minus </v-icon>
        </v-btn>
      </v-col>
    </v-row>
</template>
<script>
export default {
  props: ["items", "onremove", "onadd", "filtration", "setname","setvalue","type","index"],
  data() {
    return {
      item: null,
      clientRules: [(v) => !!v || "This field is required"],
    };
  },
  methods: {
    updateValue(e) {
      this.$emit("input", e);
    },
  },
};
</script>