<template>
    <v-card class="mb-12 pl-4 pr-4 pt-10 pb-10" style="box-shadow: none !important;">
        <v-card-title>In Person Interview Details</v-card-title>
        <v-col cols="12">
            <label>Instructions for Interviewer</label>
          <v-textarea v-model="details.instructions" row type="text" 
            placeholder="Instructions for Interviewer" :rules="clientRules" variant="outlined" ></v-textarea>
            <label>Weighatge</label>
            <v-text-field v-model="details.weightage"  style="width: 100px;"  :min="5" :max="100" single-line type="number"
            placeholder="Weightage" :rules="weightageRules" variant="outlined" />
          
        </v-col>
    </v-card>
</template>
<script>
export default {
    components: {  },
    props:["data","onSave","id"],
    watch:{
        id:function(){
            this.details=this.$props.data
        },
        isFormValid(newVal) {
            this.details.valid=newVal
        }
    },
    computed: {
        isFormValid() {
            return this.details.instructions && this.weightageRules[0](this.details.weightage)!='Invalid'
        }
    },
    data() {
        return {
            details:this.data,
            clientRules: [
        (v) => {
          console.log(v);
          return (!!v && v.length > 0) || "This field is required";
        },
      ],weightageRules:[
      (v) => {
          return (v && v>=5 && v<=100 && /^[0-9]*$/.test(v))|| "Invalid"
        },
      ],
        }
    }
}
</script>

