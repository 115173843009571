

import config from "../../config";
let serverurl = config.serverurl;
export default {
  template: `
  <div style="display: inline-block; width: 200px;padding:10px; max-height:100px;">

  <label style="font-weight:bold; margin-bottom:10px; display:block;">Select job</label>
 <ul style="list-style-type:none;padding-left:0px">

 <li v-for="j in jobs">
 <div style="display:flex; align-items:center; padding-bottom:4px;">
 <input
 style="margin-right:4px;" type="checkbox" v-model="selectedjob" @change="updateFilter();"
 :value="j.id" >{{j.testname}}-{{j.createdby}}</div></li>
 <!--  <li v-for="j in jobs"><label style="display:flex; align-items:center; padding-bottom:4px;"><input
                  style="margin-right:4px;" type="checkbox" v-model="selectedjob" @change="updateFilter();"
                  :value="j.id" ><label>{{j.testname}}-{{j.createdby}}</label></li>-->
  </ul> 
  <button style="color:blue; margin:10px;" @click="selectedjob=[];updateFilter();">Clear</button>
</div>
    `,
  data: function () {
    return {
      jobs:[],
      selectedjob: []
    };
  },
  mounted() {
    fetch(serverurl + "/tests", {
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((t) => {
        this.jobs = t;
      });
  },
  methods: {
    updateFilter() {
      this.params.filterChangedCallback();
    },
    doesFilterPass(params) {
      return params.data.testid == this.selectedjob;
    },

    isFilterActive() {
      return this.selectedjob.length > 0;
    },

    // this example isn't using getModel() and setModel(),
    // so safe to just leave these empty. don't do this in your code!!!
    getModel() {
      return this.selectedjob.length > 0 ? { filterType: "multiselect", type: "multiselect", filter: this.selectedjob } : null;
    },

    setModel(model) {
      console.log("model");
      console.log(model);
      this.selectedjob = model?model:[];
    },
  },
}