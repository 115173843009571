<template>
    <div id="editor" style="width: 50%; height: 540px; box-shadow:0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"></div>
</template>
<script>
import loader from "@monaco-editor/loader";
export default {
  name: "Editor",
  data() {
    return {
      monaco: null,
      editor: null,
    };
  },

  props: ["code", "tech"],
  watch: {
    tech: function () {
      let oldModel = this.editor.getModel();
      let newModel = this.monaco.editor.createModel(this.code, this.tech);
      this.editor.setModel(newModel);
      if (oldModel) {
        oldModel.dispose();
      }
    },
    code: function () {
      let oldModel = this.editor.getModel();
      let newModel = this.monaco.editor.createModel(this.code, this.tech);
      this.editor.setModel(newModel);
      if (oldModel) {
        oldModel.dispose();
      }
    },
  },
  async mounted() {
    loader.init().then((monaco) => {
      this.monaco = monaco;
      const editorOptions = {
        theme:"vs-dark",
        language: this.tech,
        minimap: { enabled: false },
        readOnly: true,
        value: this.code,
      };
      this.editor = monaco.editor.create(
        document.getElementById("editor"),
        editorOptions
      );
    });
  },
};
</script>
