<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row
        no-gutters
        class="d-flex justify-space-between mt-3 mb-3"
        style="background: #fff; padding: 4px 10px; align-items: center"
      >
        <h1 class="page-title">Interviews</h1>
        <v-spacer></v-spacer>
        <v-btn
          id="create-job-jobs"
          v-bind="attrs"
          v-on="on"
          variant="flat"
          color="secondary"
          class="text-capitalize button-shadow mr-1"
          @click="$router.push('/interviews/create')"
          >Create Interview</v-btn
        >
        <!-- <v-menu offset-y>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="secondary"
              class="text-capitalize button-shadow mr-1"
              >Latest Reports</v-btn
            >
          </template>
        </v-menu> -->
      </v-row>
      <v-row class="no-gutters">
        <v-card width="100vw">
          <v-data-table
            :headers="headers"
            :items="tests"
            :items-per-page="5"
            class="elevation-1"
          >
            <!-- <template v-slot:[`item.category`]="{ item }">
              <v-chip :color="getColor(item.columns.category)" dark>
                {{ item.columns.category }}
              </v-chip>
            </template> -->
            <template v-slot:[`item.action`]="{ item }">
              <v-menu bottom left v-if="item.columns.active == 'Active'">
                <template v-slot:activator="{ props }">
                  <v-btn light icon v-bind="props" variant="plain">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="getLink(item.raw)">
                    <v-list-item-title>Self Invitation Link</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="
                      showdeleteconfirmation = true;
                      selectedjob = item.raw;
                    "
                  >
                    <v-list-item-title>Archive Job</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </div>
    <share-link-dialog
      title="Share self invitation link"
      :link="selectedLink"
      :show="showCopyLink"
      :changeShow="
        () => {
          // stopTour()
          showCopyLink = false;
        }
      "
    ></share-link-dialog>
    <v-dialog v-model="showdeleteconfirmation" width="500">
      <v-card v-if="selectedjob">
        <v-card-text class="pt-10 font-weight-bold" style="font-size: 20px !important">
          Are you sure you want to delete job -
          {{ selectedjob.testname }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
           variant="outlined"
            @click="showdeleteconfirmation = false"
          >
            Cancel
          </v-btn>
          <v-btn
            variant="flat"
            color="primary"
            @click="
              showdeleteconfirmation = false;
              archiveJob();
            "
          >
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ShareLinkDialog from "./ShareLinkDialog.vue";
import { VDataTable } from 'vuetify/labs/VDataTable'
let candidateStatus = {
  "Pending Slot Invitation": -2,
  "Slot Mail Sent": -1,
  "Pending Invitation": 1,
  "Invitation Sent": 2,
  "Ready to Hire": 3,
  "Waiting for evaluation": 3,
  Approved: 4,
  Rejected: 5,
  "On hold": 6,
  "Moved to next Round": 7,
  Recruited: 8,
  "Moved to training": 9,
};
import config from "../../config";
let serverurl = config.serverurl;
import { store } from "@/store/index";
import help from '../../helper.js';
export default {
  name: "Jobs",
  components: {
    ShareLinkDialog,
    VDataTable
  },
  data() {
    return {
      store,
      selectedjob: null,
      showdeleteconfirmation: false,
      showCopyLink: false,
      headers: [
        { title: "Job Title", key: "testname" },
        // { title: "Category", key: "category" },
        { title: "Candidates", key: "totalApplications" },
        // { title: "Head Counts", key: "head_counts" },
        { title: "Status", key: "active" },
        { title: "Created At", key: "createdAt" },
        { title: "Created By", key: "createdBy" },
        { title: "Actions", key: "action" },
      ],
      tests: [],
      selectedLink: "",
    };
  },
  methods: {
    getColor(category) {
      let color = null;
      switch (category.toUpperCase()) {
        case "TECHNOLOGY":
          color = "blue";
          break;
        case "SALES":
          color = "red";
          break;
        case "HR":
          color = "green";
          break;
        case "FINANCE":
          color = "yellow";
          break;
    }
      return color;
    },
    getLink(job) {
      this.showCopyLink = true;
      this.selectedLink =
        "https://jobs.hulkhire.com/schedule/" +
        this.store.profile.profile.toLowerCase() +
        "/" +
        job.testname.toLowerCase().replace(/ /g, "-") +
        "/" +
        job.id +
        "/1";
    },
    archiveJob() {
      fetch(serverurl + "/deleteTest?id=" + this.selectedjob.id).then(() => {
        this.getJobs();
      });
    },
    getJobs() {
      fetch(serverurl + "/getJobStatusData", { credentials: "include" })
        .then((res) => res.json())
        .then((res) => {
          let tests = {};

          res.map((r) => {
            if (!r.candidate_count) r.candidate_count = 0;
            if (!tests[r.testid]) {
              tests[r.testid] = {
                id: r.testid,
                reach_interviewinvitation: 0,
                totalApplications: 0,
                reach_waitingforevaulation: 0,
                reach_approved: 0,
                reach_rejected: 0,
                reach_hold: 0,
                "Slot Mail Sent": 0,
                "Invitation Sent": 0,
                "Waiting for evaluation": 0,
                Approved: 0,
                Hold: 0,
                Rejected: 0,
                testname: r.testname,
                category: r.department,
                active: r.active ? "Active" : "Archived",
                createdBy: r.createdby,
                createdAt: new Date(r.insert_time).toDateString(),
              };
              tests[r.testid].head_counts = r.headcount;
            }
            tests[r.testid].totalApplications += r.candidate_count;
            if (candidateStatus[r.status] >= 1) {
              tests[r.testid].reach_interviewinvitation += r.candidate_count;
            }
            if (candidateStatus[r.status] >= 3) {
              tests[r.testid].reach_waitingforevaulation += r.candidate_count;
            }
            if (candidateStatus[r.status] == 5) {
              tests[r.testid].reach_rejected += r.candidate_count;
              tests[r.testid]["Rejected"] += r.candidate_count;
            }
            if (candidateStatus[r.status] == 6) {
              tests[r.testid].reach_hold += r.candidate_count;
              tests[r.testid]["Hold"] += r.candidate_count;
            }
            if ([4, 7, 8, 9].indexOf(candidateStatus[r.status]) > -1) {
              tests[r.testid].reach_approved += r.candidate_count;
              tests[r.testid]["Approved"] += r.candidate_count;
            }
            if (
              candidateStatus[r.status] <= 2 &&
              candidateStatus[r.status] >= 0
            ) {
              tests[r.testid]["Invitation Sent"] += r.candidate_count;
            }
            if (candidateStatus[r.status] <= 0) {
              tests[r.testid]["Slot Mail Sent"] += r.candidate_count;
            }
            if (candidateStatus[r.status] == 3) {
              tests[r.testid]["Waiting for evaluation"] += r.candidate_count;
            }
          });
          this.tests = Object.keys(tests).map((s) => tests[s]);
          
          if (this.$route.params.sharejobid) {
            this.getLink(
              this.tests.find((t) => t.id == this.$route.params.sharejobid)
            );
            if(this.tests.length==1){
              help.nextStep(this,6,1000)
            }
            window.history.pushState("Interviews", "Interviews", "/interviews/interviews");
          }
          else{
            if(this.tests.length==0)
              help.nextStep(this,7)
          }
          
        });
    },
    stopTour(){
      help.stopTour(this);
    }
  },
  created() {
  },
  mounted() {
    this.getJobs();
  },
  beforeDestroy() {
    console.log("unmounted");
    // help.stopTour(this);
  },
};
</script>

<style src="./Jobs.scss" lang="scss"/>
