import { createApp } from 'vue'
import App from './App.vue'
import {store} from './store/index'
import router from './Routes'
// import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import config from '@/config'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
  theme:{
    themes: {
      light: config.light
    }
  }
})

loadFonts()

createApp(App)
  .use(store)
  .use(router)
  .use(vuetify)
  .mount('#app')
