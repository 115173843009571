<template>
    <v-card class="mb-12 pl-4 pr-4 pt-10 pb-10" style="box-shadow: none !important;">
        <v-card-title>Interview Details</v-card-title>
        <v-row class="mt-0 mb-0 ml-0 mr-0">
            <v-col cols="12">
                <label>Job Title</label>
                <v-text-field v-model="details.title" maxlength="50" placeholder="Job Title" :rules="clientRules" required
                    variant="outlined"></v-text-field>
            </v-col>

            <v-col cols="12">
                <label>Notification and reminder email</label>
                <v-text-field v-model="details.email" hint="Optional" single-line type="text" placeholder="hr@company.com"
                    :rules="emailRules" variant="outlined" />
            </v-col>
        </v-row>
        <!-- <v-btn id="step-1" color="primary" :disabled="details.title.length == 0" @click="() => { onSave(details)}">Save</v-btn> -->
    </v-card>
</template>
<script>

export default {
    components: {},
    props: ["data", "onSave", "id"],
    watch: {
        id: function () {
            this.details = this.$props.data
        },
        isFormValid(newVal) {
            this.details.valid=newVal
        }
    },
    data() {
        return {
            details: this.data,
            pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            clientRules: [
                (v) => {
                    console.log(v);
                    return (!!v && v.length > 0) || "This field is required";
                },
            ],
            emailRules: [
                (v) => {
                    return (!v || this.pattern.test(v)) || "Invalid e-mail.";
                },
            ],
        }
    },
    computed: {
        isFormValid() {
            return this.details.title && (!this.details.email || this.pattern.test(this.details.email))
        }
    },

}
</script>