<template>
    <div class="node"  :class="{selected: data.id==data.selectednodeid, invalid: !data.valid}" >
        <v-row>
            <v-col cols="3">
                <div class="node-icon" >
                    <v-icon color="#fff" size="large">mdi-cog</v-icon>
                </div>
                
            </v-col>
            <v-col cols="9" class="justify-start d-flex flex-column">
                 <strong>{{ data.title || "Job Title" }} </strong>
                <span>Send Updates On - {{ data.email || "" }}</span>
                
            </v-col>
        </v-row>
        
    </div>
</template>
<script>
export default {
    components: {  },
    props:["data"],
    data() {
        return {
        }
    },
    methods:{
        
    }
}
</script>
