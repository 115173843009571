<template>
    <div v-if="params.data && params.data.technical_avg">
      <!-- <v-rating
                half-increments
                readonly
                color="green"
                length="5"
                size="20"
                v-model="params.data.technical_avg"
              ></v-rating> -->
              <strong style="color:green; font-size:20px;"> {{params.data.technical_avg.toFixed(2)}}</strong>
  <span v-for="tech in Object.keys(params.data.scores)" v-bind:key="tech" style="text-transform: capitalize;display:block;line-height:1.2;">{{tech}} - {{params.data.scores[tech]}}</span>
  
  </div>
</template>